import React, { useState, useRef, Fragment } from "react";
import { useQuery } from "react-query";
import Dropzone from "react-dropzone";
import _ from "../../../../i18n";

import { useOnClickOutside } from "../../../../utils";
import {
  saveReason,
  deleteReason,
  saveAnomaly,
  deleteAnomaly,
  getOrganizationCollaborators,
} from "../../../../api";

import { Tooltip } from "antd";
import { TiArrowSortedDown } from "react-icons/ti";
import { Checkbox } from "primereact/checkbox";
import { Carousel } from "primereact/carousel";
import { Dialog } from "primereact/dialog";
import { ModalConfirm } from "tamtam-components";
import {
  IconTrash,
  CheckIcon,
  IconPencil,
  AttachmentIcon,
  IconChevronDown,
  ExclamationMarkIcon,
} from "../../../common/Icons";

import "./EncodingProgressionContent.local.scss";

const AddNewCorrectionCardFrame = ({
  organization,
  categories,
  year,
  token,
  reasons,
  anomalies,
  onCancel,
  onConfirm,
  updateReasons,
  pendingReport,
  isToCorrectProp,
  updateAnomalies,
}) => {
  const refAnomalies = useRef();
  const refResponsables = useRef();
  const refTaskCategories = useRef();
  const refReasonCategories = useRef();
  useOnClickOutside(refTaskCategories, () =>
    setTaskCategoriesDivIsVisible(false)
  );
  useOnClickOutside(refReasonCategories, () =>
    setReasonCategoriesDivIsVisible(false)
  );
  useOnClickOutside(refResponsables, () => {
    setResponsablesDivIsVisible(false);
    setCollaboratorsKeyWord("");
  });
  useOnClickOutside(refAnomalies, () => setAnomaliesDivIsVisible(false));
  const [isDeleteReasonModalOpen, setIsDeleteReasonModalOpen] = useState(false);
  const [anomaliesDivIsVisible, setAnomaliesDivIsVisible] = useState(false);
  const [responsablesDivIsVisible, setResponsablesDivIsVisible] =
    useState(false);
  const [collaboratorsKeyWord, setCollaboratorsKeyWord] = useState("");
  const [selectedAnomaly, setSelectedAnomaly] = useState(
    pendingReport ? pendingReport.anomaly : null
  );
  const [selectedResponsable, setSelectedResponsable] = useState(
    pendingReport ? pendingReport.responsable : null
  );
  const [collaborators, setCollaborators] = useState([]);

  //Reason Grid
  const [checkedReasonList, setCheckedReasonList] = useState({
    categories: pendingReport
      ? Object.keys(pendingReport.errors.categories).map((item) =>
          parseInt(item)
        )
      : [],
    reasons: pendingReport
      ? Object.keys(pendingReport.errors.reasons).map((item) => parseInt(item))
      : [],
  });
  const [contentReasonList, setContentReasonList] = useState({
    categories: pendingReport ? pendingReport.errors.categories : {},
    reasons: pendingReport ? pendingReport.errors.reasons : {},
  });
  const [selectedReasonTaskCategory, setSelectedReasonTaskCategory] =
    useState(null);

  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedCategoryReason, setSelectedCategoryReason] = useState(null);
  const [newAnomaly, setNewAnomaly] = useState(null);
  //Dialog form
  const [isOpen, setIsOpen] = useState(false);
  const [taskCategoriesDivIsVisible, setTaskCategoriesDivIsVisible] =
    useState(false);
  const [reasonCategoriesDivIsVisible, setReasonCategoriesDivIsVisible] =
    useState(false);
  const [newReason, setNewReason] = useState({
    reasonCategory: null,
    isSerious: false,
    title: "",
    type: "REASON",
  });
  const [newReasonCategory, setNewReasonCategory] = useState({
    taskCategory: null,
    title: "",
    type: "CATEGORY",
  });

  let collaboratorsQuery = useQuery(
    ["collaboratorsQuery", organization, collaboratorsKeyWord],
    async () => {
      if (token) {
        try {
          const response = await getOrganizationCollaborators(
            organization,
            year,
            token,
            null,
            collaboratorsKeyWord
          );
          let filtredCollaborator = response.data.data.filter(
            (collab) => collab !== null
          );

          setCollaborators(filtredCollaborator);
        } catch (e) {
          setCollaborators([]);
          return null;
        }
      }
    }
  );

  const handleSaveReason = async (item) => {
    try {
      const response = await saveReason({
        id: null,
        token,
        organization,
        type: item.type,
        parent: item.reasonCategory ? item.reasonCategory.id : null,
        label: item.title,
        isSerious: item.isSerious,
        taskCategory:
          item.type === "CATEGORY" ? newReasonCategory.taskCategory.id : null,
      });
      let newReasons = [...reasons];
      let reasonResponse = response.data.data;
      if (item.type === "CATEGORY") {
        setNewReasonCategory({ ...newReasonCategory, title: "" });
        setNewReason({
          ...newReason,
          reasonCategory: reasonResponse,
        });
        newReasons.unshift(reasonResponse);
        setReasonCategoriesDivIsVisible(false);
      } else {
        setNewReason({
          isSerious: false,
          title: "",
          reasonCategory: null,
          type: "REASON",
        });
        setNewReasonCategory({ ...newReasonCategory, taskCategory: null });
        newReasons = reasons.map((category) => {
          if (category.id === item.reasonCategory.id) {
            let newSubReasons = category.items ? [...category.items] : [];
            newSubReasons.unshift(reasonResponse);
            return { ...category, items: newSubReasons };
          } else {
            return category;
          }
        });
        setIsOpen(false);
      }
      updateReasons(newReasons);
    } catch (e) {
      return null;
    }
  };

  const handleSaveAnomaly = async (label) => {
    try {
      const response = await saveAnomaly({
        id: null,
        token,
        organization,
        label: label,
      });
      let newAnomalies = [...anomalies];
      let anomalyResponse = response.data.data;
      newAnomalies.unshift(anomalyResponse);
      setSelectedAnomaly(anomalyResponse);
      setNewAnomaly(null);
      updateAnomalies(newAnomalies);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleDeleteReason = async ({ id, type }) => {
    try {
      const response = await deleteReason({
        id: id,
        token,
        organization,
        type: type,
      });
      let newReasons = [];
      let reasonResponse = response.data.data.id;
      if (type === "CATEGORY") {
        newReasons = reasons.filter((reason) => reason.id !== reasonResponse);
      } else {
        newReasons = reasons.map((category) => {
          let newItems = category.items ? [...category.items] : null;
          if (newItems) {
            newItems = newItems.filter(
              (reason) => reason.id !== reasonResponse
            );
            return { ...category, items: newItems };
          } else {
            return category;
          }
        });
      }
      console.log("delettettetetete========");
      updateReasons(newReasons);
    } catch (e) {
      return null;
    }
  };

  const handleDeleteAnomaly = async ({ id }) => {
    try {
      const response = await deleteAnomaly({
        id: id,
        token,
        organization,
      });
      let newAnomalies = [];
      let anomalyResponse = response.data.data.id;
      newAnomalies = anomalies.filter(
        (anomaly) => anomaly.id !== anomalyResponse
      );
      updateAnomalies(newAnomalies);
    } catch (e) {
      return null;
    }
  };

  const onTemplateSelect = (acceptedFiles, id, type) => {
    let content =
      type === "CATEGORY"
        ? contentReasonList.categories
        : contentReasonList.reasons;

    content[id] = {
      ...content[id],
      files: content[id].files.concat(acceptedFiles),
    };
    setContentReasonList({
      categories: type === "CATEGORY" ? content : contentReasonList.categories,
      reasons: type === "REASON" ? content : contentReasonList.reasons,
    });
  };

  const renderFiles = (type, id) => {
    let list =
      type === "CATEGORY"
        ? contentReasonList.categories
        : contentReasonList.reasons;
    return list[id] ? list[id].files : [];
  };

  const removeFile = (type, id, index) => {
    let content =
      type === "CATEGORY"
        ? contentReasonList.categories
        : contentReasonList.reasons;

    let newFiles = content[id].files;
    newFiles.splice(index, 1);

    content[id] = {
      ...content[id],
      files: newFiles,
    };
    setContentReasonList({
      categories: type === "CATEGORY" ? content : contentReasonList.categories,
      reasons: type === "REASON" ? content : contentReasonList.reasons,
    });
  };

  let taskCategories = [];
  reasons.forEach((element) => {
    if (!taskCategories.find((item) => item.id === element.categoryId)) {
      taskCategories.push({
        label: element.categoryLabel,
        id: element.categoryId,
      });
    }
  });
  if (selectedReasonTaskCategory === null) {
    setSelectedReasonTaskCategory({
      label: reasons[0].categoryLabel,
      id: reasons[0].categoryId,
    });
  }

  let filtredReason = [];
  if (selectedReasonTaskCategory) {
    reasons.forEach((reason) => {
      if (reason.categoryId === selectedReasonTaskCategory.id) {
        filtredReason.push(reason);
      }
    });
  }

  let filtredReasonForm = [];
  if (newReasonCategory && newReasonCategory.taskCategory) {
    reasons.forEach((reason) => {
      if (reason.categoryId === newReasonCategory.taskCategory.id) {
        filtredReasonForm.push(reason);
      }
    });
  }

  return (
    <div className="pre-form">
      <div className="row">
        {!isToCorrectProp ? (
          <div>
            <div className="instruction">
              {_(
                isToCorrectProp
                  ? "selectTheErrorReportAnomaly"
                  : "selectTheAnomaly"
              )}{" "}
              * :{" "}
            </div>
            <div
              ref={refAnomalies}
              className={
                "rectangular_select" +
                " " +
                (anomaliesDivIsVisible ? "rectangular_select_opened" : "")
              }
            >
              <div
                className={"rectangular_select_header"}
                onClick={() => {
                  setNewAnomaly(null);
                  setAnomaliesDivIsVisible(!anomaliesDivIsVisible);
                }}
                style={{ width: 300 }}
              >
                {selectedAnomaly ? (
                  <div>{selectedAnomaly.label}</div>
                ) : (
                  <span>{_("select")}...</span>
                )}
                <TiArrowSortedDown />
              </div>
              {anomaliesDivIsVisible && (
                <div
                  className={"rectangular_select_body"}
                  style={{ width: 300 }}
                >
                  <div className="input_form">
                    <input
                      value={newAnomaly ? newAnomaly : ""}
                      placeholder={_("addNewAnomaly")}
                      onChange={(event) => setNewAnomaly(event.target.value)}
                    />
                    {newAnomaly && (
                      <div className="action-side">
                        <div
                          className="arrow"
                          onClick={() => {
                            handleSaveAnomaly(newAnomaly);
                          }}
                        >
                          <CheckIcon width={10} height="8" fill="#ffffff" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={"rectangular_select_body_options"}>
                    {anomalies.map((item, index) => (
                      <div
                        key={index}
                        className={"rectangular_select_body_option input_form"}
                        onClick={() => {
                          setSelectedAnomaly(item);
                          setAnomaliesDivIsVisible(false);
                        }}
                      >
                        {item.label}
                        <div
                          className="action-side"
                          style={{ background: "#FFFFFF" }}
                        >
                          <div
                            className="arrow"
                            onClick={(e) => {
                              handleDeleteAnomaly(item);
                              if (
                                selectedAnomaly &&
                                item.id === selectedAnomaly.id
                              ) {
                                setSelectedAnomaly(null);
                              }
                              e.stopPropagation();
                            }}
                          >
                            <IconTrash size={11} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="instruction">{_("selectResponsable")} * : </div>
            <div
              ref={refResponsables}
              className={
                "rectangular_select" +
                " " +
                (responsablesDivIsVisible ? "rectangular_select_opened" : "")
              }
            >
              <div
                className={"rectangular_select_header"}
                onClick={() => {
                  setResponsablesDivIsVisible(!responsablesDivIsVisible);
                }}
                style={{ width: 300 }}
              >
                {selectedResponsable ? (
                  <div>{selectedResponsable.name}</div>
                ) : (
                  <span>{_("select")}...</span>
                )}
                <TiArrowSortedDown />
              </div>
              {responsablesDivIsVisible && (
                <div
                  className={"rectangular_select_body"}
                  style={{ width: 300 }}
                >
                  <input
                    onChange={(e) => setCollaboratorsKeyWord(e.target.value)}
                  />
                  <div className={"rectangular_select_body_options"}>
                    {collaborators &&
                      collaborators.map((item, index) => (
                        <div
                          key={index}
                          className={
                            "rectangular_select_body_option input_form"
                          }
                          onClick={() => {
                            setSelectedResponsable(item);
                            setResponsablesDivIsVisible(false);
                            setCollaboratorsKeyWord("");
                          }}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {isToCorrectProp && (
          <div style={{ marginLeft: 35 }}>
            <div className="instruction">{_("toCorrect")} : </div>
            <Checkbox checked={isToCorrectProp} disabled />
          </div>
        )}
      </div>
      <hr />
      <div className="instruction">{_("selectTheReason")} * :</div>
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "5px" }}
      >
        <Carousel
          value={taskCategories}
          numVisible={3}
          numScroll={1}
          responsiveOptions={[]}
          itemTemplate={(category, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <div
                  key={i}
                  className={`tab_badge ${
                    selectedReasonTaskCategory.id === category.id
                      ? "tab_badge_active"
                      : ""
                  }`}
                  onClick={() => setSelectedReasonTaskCategory(category)}
                >
                  <div>
                    {category?.label && category?.label.substr(0, 24)}
                    {category?.label && category?.label.length > 25 ? "." : ""}
                  </div>
                </div>
              </div>
            );
          }}
        />
        <div className="action-bar" onClick={() => setIsOpen(true)}>
          <IconPencil size={14} />
        </div>
      </div>

      {isToCorrectProp ? (
        <div className="grid_card">
          {filtredReason.map((cat) => (
            <Fragment>
              <div
                className={
                  "card card_large" +
                  " " +
                  (selectedCategoryReason &&
                  selectedCategoryReason.id === cat.id
                    ? "card_large_open"
                    : "")
                }
              >
                <div>
                  <Checkbox
                    onChange={(e) => {
                      let oldChecked = [...checkedReasonList.categories];
                      let newReasonsChecked = [...checkedReasonList.reasons];
                      let newChecked = [];
                      if (oldChecked.includes(cat.id)) {
                        newChecked = oldChecked.filter(
                          (item) => item !== cat.id
                        );
                        delete contentReasonList.categories[cat.id];
                      } else {
                        if (
                          cat.items &&
                          cat.items.length &&
                          cat.items
                            .map((r) => r.id)
                            .some((item) => newReasonsChecked.includes(item))
                        ) {
                          newReasonsChecked.forEach((item) => {
                            if (cat.items.map((r) => r.id).includes(item)) {
                              delete contentReasonList.reasons[item];
                            }
                          });
                          newReasonsChecked = newReasonsChecked.filter(
                            (item) => !cat.items.map((r) => r.id).includes(item)
                          );
                        }
                        newChecked = [...checkedReasonList.categories];
                        newChecked.push(cat.id);
                        let newContent = contentReasonList.categories;
                        newContent[cat.id] = {
                          idReason: cat.id,
                          type: "CATEGORY",
                          description: "",
                          files: [],
                        };
                        setContentReasonList({
                          ...contentReasonList,
                          categories: newContent,
                        });
                      }
                      setCheckedReasonList({
                        ...checkedReasonList,
                        categories: newChecked,
                        reasons: newReasonsChecked,
                      });
                    }}
                    checked={checkedReasonList.categories.includes(cat.id)}
                  />
                  {cat.label}
                </div>
                <div className="action-side">
                  <div className="actions">
                    
                    <div
                      className="action"
                      onClick={() =>
                        setIsDeleteReasonModalOpen({
                          type: "CATEGORY",
                          id: cat.id,
                        })
                      }
                    >
                      <IconTrash size={12} />
                    </div>
                    <div className="action" onClick={() => null}>
                      <IconPencil size={12} />
                    </div>
                  </div>
                  <div
                    className={
                      "arrow" +
                      " " +
                      (selectedCategoryReason &&
                      selectedCategoryReason.id === cat.id
                        ? "open"
                        : "")
                    }
                    onClick={() => {
                      if (
                        selectedCategoryReason &&
                        selectedCategoryReason.id === cat.id
                      ) {
                        setSelectedCategoryReason(null);
                      } else {
                        setSelectedCategoryReason(cat);
                      }
                    }}
                  >
                    <IconChevronDown width={10} height="8" />
                  </div>
                </div>
              </div>

              {checkedReasonList.categories.includes(cat.id) && (
                <div className={"card_form"}>
                  <div className="text-area">
                    <div className="instruction">{_("description ")} * :</div>
                    <textarea
                      placeholder={_("addDescription")}
                      onChange={(e) => {
                        let newContent = contentReasonList.categories;
                        newContent[cat.id].description = e.target.value;
                        setContentReasonList({
                          ...contentReasonList,
                          categories: newContent,
                        });
                      }}
                      value={contentReasonList.categories[cat.id].description}
                    />
                  </div>
                  <div className="attachment-list">
                    <div className="instruction">{_("attachments ")} * :</div>
                    {renderFiles("CATEGORY", cat.id).map((file, index) => {
                      return (
                        <div className="attachment-item">
                          <div>{file.name}</div>
                          <div className="actions">
                            <div
                              onClick={() =>
                                removeFile("CATEGORY", cat.id, index)
                              }
                            >
                              <IconTrash size={12} />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        onTemplateSelect(acceptedFiles, cat.id, "CATEGORY")
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "dropzone",
                            onDrop: (event) => event.stopPropagation(),
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="action">
                            <AttachmentIcon />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              )}

              {selectedCategoryReason &&
                selectedCategoryReason.id === cat.id &&
                cat.items &&
                cat.items.length &&
                cat.items.map((reason) => (
                  <Fragment>
                    <div
                      className={
                        "card card_large sub_card" +
                        " " +
                        (selectedReason && selectedReason.id === reason.id
                          ? "sub_card_open"
                          : "")
                      }
                    >
                      <div style={{ display: "flex" }}>
                        <Checkbox
                          onChange={(e) => {
                            let oldChecked = [...checkedReasonList.reasons];
                            let newCategoriesChecked = [
                              ...checkedReasonList.categories,
                            ];
                            let newChecked = [];
                            if (oldChecked.includes(reason.id)) {
                              newChecked = oldChecked.filter(
                                (item) => item !== reason.id
                              );
                              delete contentReasonList.reasons[reason.id];
                            } else {
                              if (
                                checkedReasonList.categories.includes(cat.id)
                              ) {
                                newCategoriesChecked =
                                  newCategoriesChecked.filter(
                                    (item) => item !== cat.id
                                  );
                                delete contentReasonList.categories[cat.id];
                              }
                              newChecked = [...checkedReasonList.reasons];
                              newChecked.push(reason.id);
                              let newContent = contentReasonList.reasons;
                              newContent[reason.id] = {
                                idReason: reason.id,
                                type: "REASON",
                                description: "",
                                files: [],
                              };
                              setContentReasonList({
                                ...contentReasonList,
                                reasons: newContent,
                              });
                            }
                            setCheckedReasonList({
                              ...checkedReasonList,
                              categories: newCategoriesChecked,
                              reasons: newChecked,
                            });
                          }}
                          checked={checkedReasonList.reasons.includes(
                            reason.id
                          )}
                        />
                        <div>{reason.label}</div>
                        {reason.isSerious && (
                          <Tooltip
                            key="tool-1"
                            placement="top"
                            title={_("seriousError")}
                            mouseLeaveDelay={0}
                          >
                            <span>
                              <ExclamationMarkIcon size="13" fill="#EB5757" />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <div className="action-side">
                        <div className="actions">
                          <div
                            className="action"
                            onClick={() =>
                              setIsDeleteReasonModalOpen({
                                type: "CATEGORY",
                                id: reason.id,
                              })
                            }
                          >
                            <IconTrash size={12} />
                          </div>
                          <div className="action" onClick={() => null}>
                            <IconPencil size={12} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {checkedReasonList.reasons.includes(reason.id) && (
                      <div className={"card_form card_form_sub"}>
                        <div className="text-area">
                          <div className="instruction">
                            {_("description ")} * :
                          </div>
                          <textarea
                            placeholder={_("addDescription")}
                            onChange={(e) => {
                              let newContent = contentReasonList.reasons;
                              newContent[reason.id].description =
                                e.target.value;
                              setContentReasonList({
                                ...contentReasonList,
                                reasons: newContent,
                              });
                            }}
                            value={
                              contentReasonList.reasons[reason.id].description
                            }
                          />
                        </div>
                        <div className="attachment-list">
                          <div className="instruction">
                            {_("attachments ")} * :
                          </div>
                          {renderFiles("REASON", reason.id).map(
                            (file, index) => {
                              return (
                                <div className="attachment-item">
                                  <div>{file.name}</div>
                                  <div className="actions">
                                    <div
                                      onClick={() =>
                                        removeFile("REASON", reason.id, index)
                                      }
                                    >
                                      <IconTrash size={12} />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              onTemplateSelect(
                                acceptedFiles,
                                reason.id,
                                "REASON"
                              )
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className: "dropzone",
                                  onDrop: (event) => event.stopPropagation(),
                                })}
                              >
                                <input {...getInputProps()} />
                                <div className="action">
                                  <AttachmentIcon />
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="grid_card">
          {filtredReason.map((cat) => (
            <Fragment>
              <div
                className={
                  "card card_large" +
                  " " +
                  (selectedCategoryReason &&
                  selectedCategoryReason.id === cat.id
                    ? "card_large_open"
                    : "")
                }
              >
                <div>{cat.label}</div>
                <div className="action-side">
                  <div className="actions">
                    <div
                      className="action"
                      onClick={() =>
                        setIsDeleteReasonModalOpen({
                          type: "CATEGORY",
                          id: cat.id,
                        })
                      }
                    >
                      <IconTrash size={12} />
                    </div>
                    <div className="action" onClick={() => null}>
                      <IconPencil size={12} />
                    </div>
                  </div>
                  <div
                    className={"arrow"}
                    onClick={() => {
                      if (
                        selectedCategoryReason &&
                        selectedCategoryReason.id === cat.id
                      ) {
                        setSelectedCategoryReason(null);
                      } else {
                        setSelectedCategoryReason(cat);
                      }
                    }}
                  >
                    <IconChevronDown width={10} height="8" />
                  </div>
                </div>
              </div>
              {selectedCategoryReason &&
                selectedCategoryReason.id === cat.id &&
                cat.items &&
                cat.items.length &&
                cat.items.map((reason) => (
                  <div
                    className={
                      "card sub_card" +
                      " " +
                      (selectedReason && selectedReason.id === reason.id
                        ? "active"
                        : "")
                    }
                    onClick={() => {
                      setSelectedReason(reason);
                    }}
                  >
                    <div>
                      {reason.isSerious && (
                        <Tooltip
                          key="tool-1"
                          placement="top"
                          title={_("seriousError")}
                          mouseLeaveDelay={0}
                        >
                          <span>
                            <ExclamationMarkIcon size="13" fill="#EB5757" />
                          </span>
                        </Tooltip>
                      )}
                      {reason.label}
                    </div>
                    <div className="action-side">
                      <div className="actions">
                        <div
                          className="action"
                          onClick={() =>
                            setIsDeleteReasonModalOpen({
                              type: "REASON",
                              id: reason.id,
                            })
                          }
                        >
                          <IconTrash size={12} />
                        </div>
                        <div className="action" onClick={() => null}>
                          <IconPencil size={12} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Fragment>
          ))}
        </div>
      )}
      <div className="footer" style={{ width: "76%" }}>
        <div className="action_left" onClick={onCancel}>
          {_("cancel")}
        </div>
        {isToCorrectProp ? (
          <div>
            <div
              className={
                "action_right" +
                " " +
                ((isToCorrectProp && !selectedResponsable) ||
                (isToCorrectProp &&
                  checkedReasonList.categories.length +
                    checkedReasonList.reasons.length ===
                    0)
                  ? "disabled"
                  : "")
              }
              onClick={() => {
                if (
                  (isToCorrectProp && !selectedResponsable) ||
                  (isToCorrectProp &&
                    checkedReasonList.categories.length +
                      checkedReasonList.reasons.length ===
                      0)
                ) {
                  return;
                } else {
                  onConfirm(isToCorrectProp, {
                    id: pendingReport ? pendingReport.id : null,
                    responsable: selectedResponsable,
                    errors: contentReasonList,
                    status: "PENDING",
                  });
                }
              }}
            >
              {_("save")}
            </div>
            <div
              className={
                "action_right" +
                " " +
                ((isToCorrectProp && !selectedResponsable) ||
                (isToCorrectProp &&
                  checkedReasonList.categories.length +
                    checkedReasonList.reasons.length ===
                    0)
                  ? "disabled"
                  : "")
              }
              onClick={() => {
                if (
                  (isToCorrectProp && !selectedResponsable) ||
                  (isToCorrectProp &&
                    checkedReasonList.categories.length +
                      checkedReasonList.reasons.length ===
                      0)
                ) {
                  return;
                } else {
                  onConfirm(isToCorrectProp, {
                    id: pendingReport ? pendingReport.id : null,
                    responsable: selectedResponsable,
                    errors: contentReasonList,
                    status: "DONE",
                  });
                }
              }}
            >
              {_("finalize")}
            </div>
          </div>
        ) : (
          <div
            className={
              "action_right" +
              " " +
              (!selectedReason || !selectedAnomaly ? "disabled" : "")
            }
            onClick={() => {
              if (!selectedReason || !selectedAnomaly) {
                return;
              } else {
                onConfirm(isToCorrectProp, {
                  anomaly: selectedAnomaly,
                  reason: selectedReason,
                  plans: [],
                });
              }
            }}
          >
            {_("next")}
          </div>
        )}
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={isDeleteReasonModalOpen}
        onCancel={() => setIsDeleteReasonModalOpen(null)}
        onConfirm={() => {
          handleDeleteReason(isDeleteReasonModalOpen);
          setIsDeleteReasonModalOpen(null);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("confirmDelete")}
        text={_(
          isDeleteReasonModalOpen && isDeleteReasonModalOpen.type === "REASON"
            ? "confirmReasonDeleteContent"
            : "confirmReasonCascadeDeleteContent"
        )}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />
      <Dialog
        header="Creer une raison/Categorie raison"
        visible={isOpen}
        style={{
          position: "relative",
          width: "38vw",
          height:
            taskCategoriesDivIsVisible || reasonCategoriesDivIsVisible
              ? "34vw"
              : "24vw",
        }}
        onHide={() => {
          setNewReason({
            reasonCategory: null,
            isSerious: false,
            title: "",
            type: "REASON",
          });
          setNewReasonCategory({
            taskCategory: null,
            title: "",
            type: "CATEGORY",
          });
          setIsOpen(false);
        }}
      >
        <div className="modal-form">
          <div style={{ display: "flex" }}>
            <div className="instruction" style={{ width: 135 }}>
              {_("Nomenclature de tâches ")} * :
            </div>
            <div
              ref={refTaskCategories}
              className={
                "rectangular_select" +
                " " +
                (taskCategoriesDivIsVisible ? "rectangular_select_opened" : "")
              }
            >
              <div
                className={"rectangular_select_header "}
                onClick={() =>
                  setTaskCategoriesDivIsVisible(!taskCategoriesDivIsVisible)
                }
                style={{
                  width: 350,
                  borderWidth: 0.8,
                }}
              >
                {newReasonCategory.taskCategory ? (
                  <div>{newReasonCategory.taskCategory.label}</div>
                ) : (
                  <span>{_("selectCategory")}...</span>
                )}
                <TiArrowSortedDown />
              </div>
              {taskCategoriesDivIsVisible && (
                <div
                  className={"rectangular_select_body"}
                  style={{
                    width: 350,
                  }}
                >
                  <div className={"rectangular_select_body_options"}>
                    {categories.map((item, index) => (
                      <div
                        key={index}
                        className={"rectangular_select_body_option"}
                        onClick={() => {
                          setNewReasonCategory({
                            ...newReasonCategory,
                            taskCategory: item,
                          });
                          //IF NO CATEGORIES set categorie obligatoire.
                          setTaskCategoriesDivIsVisible(false);
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 9 }}>
            <div className="instruction" style={{ width: 135 }}>
              {_("Catégorie de raison ")} :
            </div>
            <div
              ref={refReasonCategories}
              className={
                "rectangular_select" +
                " " +
                (reasonCategoriesDivIsVisible
                  ? "rectangular_select_opened"
                  : "")
              }
            >
              <div
                className={"rectangular_select_header "}
                onClick={() =>
                  setReasonCategoriesDivIsVisible(!reasonCategoriesDivIsVisible)
                }
                style={{
                  width: 350,
                  borderWidth: 0.8,
                }}
              >
                {newReason.reasonCategory ? (
                  <div>{newReason.reasonCategory.label}</div>
                ) : (
                  <span>{_("selectCategory")}...</span>
                )}
                <TiArrowSortedDown />
              </div>
              {reasonCategoriesDivIsVisible && (
                <div
                  className={"rectangular_select_body"}
                  style={{ width: 350 }}
                >
                  <div className={"rectangular_select_body_options"}>
                    <div className="input_form">
                      <input
                        value={newReasonCategory ? newReasonCategory.title : ""}
                        placeholder={_("addNewReasonCategory")}
                        onChange={(event) =>
                          setNewReasonCategory({
                            ...newReasonCategory,
                            title: event.target.value,
                          })
                        }
                      />
                      {newReasonCategory.title && (
                        <div
                          className={`action-side ${
                            !newReasonCategory.taskCategory && "disabled"
                          }`}
                        >
                          <div
                            className="arrow"
                            onClick={() => {
                              if (!newReasonCategory.taskCategory) {
                                return;
                              }
                              handleSaveReason(newReasonCategory);
                            }}
                          >
                            <CheckIcon width={10} height="8" fill="#ffffff" />
                          </div>
                        </div>
                      )}
                    </div>
                    {filtredReasonForm
                      .filter((reason) =>
                        newReason.taskCategory
                          ? reason.categoryId === newReason.taskCategory.id
                          : true
                      )
                      .map((item, index) => (
                        <div
                          key={index}
                          className={"rectangular_select_body_option"}
                          onClick={() => {
                            setNewReason({
                              ...newReason,
                              reasonCategory: item,
                            });
                            setReasonCategoriesDivIsVisible(false);
                          }}
                        >
                          {item.label}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 14 }}>
            <div className="instruction" style={{ width: 135 }}>
              {_("Titre de raison ")} :
            </div>
            <div
              className="card card_large sub_card form_card"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <input
                value={newReason.title}
                onChange={(event) =>
                  setNewReason({ ...newReason, title: event.target.value })
                }
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 6 }}>
            <div className="instruction" style={{ width: 135 }}>
              {_("isSerious")} :
            </div>
            <Checkbox
              onChange={(e) =>
                setNewReason({ ...newReason, isSerious: e.checked })
              }
              checked={newReason.isSerious}
            />
          </div>
          <div className="modal-form_footer">
            <div
              className="action_left"
              onClick={() => {
                setNewReason({
                  taskCategory: null,
                  title: "",
                  type: "CATEGORY",
                });
                setNewReasonCategory({
                  reasonCategory: null,
                  isSerious: false,
                  title: "",
                  type: "REASON",
                });
                setIsOpen(false);
              }}
            >
              {_("cancel")}
            </div>
            <div
              className={"action_right" + " " + (true ? "" : "disabled")}
              onClick={() => {
                // if (!selectedReason || !selectedAnomaly) {
                //   return;
                // } else {
                handleSaveReason(newReason);
                // }
              }}
            >
              {_("save")}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewCorrectionCardFrame;
