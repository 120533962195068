import axios from "axios";
import { TTP_API_URL } from "../config";

export const getClientBudget = ({ token, organizationId, clientID, year }) => {
  const requestUrl = `${TTP_API_URL}/team/budget/client`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      client: clientID,
      year,
    },
  });
};

export const saveBudgetVolumetrie = (
  clientId,
  budgetVolumetrie,
  organizationId,
  squadId,
  clusterId,
  buId,
  statusBudget,
  year,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/budget/updateBudgetVolumetire`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("clientId", clientId);
  formData.append("budgetVolumetrie", JSON.stringify(budgetVolumetrie));
  formData.append("organizationId", organizationId);
  formData.append("squadId", squadId);
  formData.append("clusterId", JSON.stringify(clusterId));
  formData.append("statusBudget", statusBudget);
  formData.append("buId", JSON.stringify(buId));
  formData.append("year", year);

  return axios.post(requestUrl, formData);
};
export const saveBudgetOffre = (
  clientId,
  organizationId,
  updatedDataOffre,
  updatedDataCategoryBudget,
  updatedCategoryRoleList,
  updatedCollaboratorsList,
  statusBudget,
  year,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/budget/updateBudgetModel`;
  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("clientId", clientId);
  formData.append("budgetOffre", JSON.stringify(updatedDataOffre));
  formData.append("budgetCategory", JSON.stringify(updatedDataCategoryBudget));
  formData.append(
    "budgetByCollaborator",
    JSON.stringify(updatedCollaboratorsList)
  );
  formData.append("statusBudget", statusBudget);
  formData.append(
    "budgetCategoryRole",
    JSON.stringify(updatedCategoryRoleList)
  );
  formData.append("organizationId", organizationId);
  formData.append("year", year);

  return axios.post(requestUrl, formData);
};
export const saveClientBudgetSetting = (
  clientId,
  organizationId,
  startCategories,
  departCategories,
  startDatesConfig,
  departDatesConfig,
  preferentialTariff,
  voyant,
  year,
  token,
  leavingRiskObservation
) => {
  const requestUrl = `${TTP_API_URL}/team/budget/updateCleintBudgetSettings`;
  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("clientId", clientId);
  formData.append("startCategories", JSON.stringify(startCategories));
  formData.append("departCategories", JSON.stringify(departCategories));
  formData.append("startDatesConfig", JSON.stringify(startDatesConfig));
  formData.append("voyant", JSON.stringify(voyant));
  formData.append("preferentialTariff", preferentialTariff);
  formData.append("organizationId", organizationId);
  formData.append("year", year);

  if (
    departDatesConfig &&
    departDatesConfig.depart_invoice &&
    departDatesConfig.depart_time_sheet &&
    departDatesConfig.communicated_departure
  ) {
    formData.append("departDatesConfig", JSON.stringify(departDatesConfig));
  }

  if (leavingRiskObservation != null && leavingRiskObservation !== '') {
    formData.append("leavingRiskObservation", leavingRiskObservation);
  }

  return axios.post(requestUrl, formData);
};
export const saveClientsIntermediateSituations = (
  intermediateSituations,
  organization,
  year,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/budget/saveClientsIntermediateSituations`;
  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("intermediateSituations", JSON.stringify(intermediateSituations));
  formData.append("organizationId", organization);
  formData.append("year", year);

  return axios.post(requestUrl, formData);
};
