import React, { useState, useRef, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getCollaborator } from "../../api";
import TTPTab from "../common/Tab/TTPTab";
import _ from "../../i18n";
import CollaboratorProductionTracking from "./ProductionTracking/CollaboratorProductionTracking";
import CollaboratorPlansBoard from "./PlansBoard/CollaboratorPlansBoard";
import Planner from "./Planner";

import "./CollaboratorSpace.local.scss";
import CalendarCollaborator from "./CalendarIframe";
import CapacityDossier from "./CapacityDossierIframe";
import CollaboratorDashboardIframe from "./CollaboratorDashboardIframe";
import FinancialCollaborator from "./FinancialCollaborator/FinancialCollaborator";
import CollaboratorSpaceHeader from "./CollaboratorSpaceHeader";
import TTPTab2 from "../common/Tab/TTPTab2";

const CollaboratorSpace = ({ userSpace = null, collaboratorSpace = null }) => {
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedCurentStep, setSelectedCurentStep] = useState("ALL");
  const [inProgressDataFetching, setInProgressDataFetching] = useState(false);
  const [inProgressData, setInProgressData] = useState(null);
  const [inProgressFilteredData, setInProgressFilteredData] = useState(null);
  const [countByStep, setCountByStep] = useState(null);
  const [inProgressIPMData, setInProgressIPMData] = useState(null);
  const [inProgressISOCData, setInProgressISOCData] = useState(null);
  const [inProgressTVAData, setInProgressTVAData] = useState(null);
  const [selectedPeriodicityTypeId, setSelectedPeriodicityTypeId] =
    useState(null);
  const [filterInprogressData, setFilterInprogressData] = useState({
    keyWord: "",
    sort: "",
  });
  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  const [searchParams] = useSearchParams();
  const { language } = useSelector((state) => state.params);
  const activeTab = searchParams.get("tab");
  let { id } = useParams();
  let tabs = [
    // {
    //   id: "DASHBOARD",
    //   label: "dashboard",
    // },
    {
      id: "PRODUCTON_TRACKING",
      label: "productionTracking",
    },
    ...(preferences && preferences.allowAccessPlans === "1"
      ? [
          {
            id: "PLANS_BOARD",
            label: "plansBoard",
          },
        ]
      : []),
    {
      id: "CALENDAR",
      label: "calendar",
    },
    {
      id: "PLANNER",
      label: "planning",
    },
    {
      id: "FOLDER_CAPACITY",
      label: "folderCapacity",
    },
    {
      id: "FINANCIAL",
      label: "etat financier personnel",
    },
  ];

  //Header
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    tab:
      tabs.filter((tab) => tab.id === activeTab).length > 0
        ? tabs.filter((tab) => tab.id === activeTab)[0].id
        : tabs[0].id,
  });

  const [collaborator, setCollaborator] = useState(null);

  useEffect(() => {
    if (collaboratorSpace && userSpace) {
      setCollaborator(userSpace);
    }
  }, [collaborator]);

  let collaboratorQuery = useQuery(["collaboratorQuery", token], async () => {
    if (token, user) {
      try {
        const response = await getCollaborator(
          id,
          navCommunity.id,
          token
        );
        setCollaborator(response.data.data);
      } catch (e) {
        setCollaborator(null);
        return null;
      }
    }
  });
  
  const renderTabContent = () => {
    switch (filter.tab) {
      case "DASHBOARD":
        return ["SUPERADMIN", "ADMIN", "MANAGER"].includes(loggedAs) ? (
          <CollaboratorDashboardIframe
            id={collaborator.id}
            name={collaborator.name}
            year={filter.year}
          />
        ) : (
          <div>DASHBOARD</div>
        );
      case "PRODUCTON_TRACKING":
        return (
          <CollaboratorProductionTracking
            id={collaborator.id}
            name={collaborator.name}
            collaborator={collaborator}
            token={token}
            lng={language}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            year={filter.year}
            user={user}
          />
        );

      case "PLANS_BOARD":
        return (
          <CollaboratorPlansBoard
            id={collaborator.id}
            token={token}
            lng={language}
            parentFilter={{
              partner: null,
              partnerOptions: []
            }}
            year={filter.year}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            user={user ? user.firstName + " " + user.lastName : ""}
            hasAccess={preferences && preferences.allowAccessPlans === "1"}
          />
        );

      case "CALENDAR":
        return (
          <CalendarCollaborator
            id={collaborator.id}
            name={collaborator && collaborator.name}
            year={filter.year}
          />
        );

      case "PLANNER":
        return (
          <Planner
            collaborator={collaborator}
            organizationId={navCommunity && navCommunity.id}
            navCommunity={navCommunity}
            lng={language}
            token={token}
            year={filter.year}
          />
        );

      case "FOLDER_CAPACITY":
        return (
          <CapacityDossier
            id={collaborator.id}
            name={collaborator.name}
            year={filter.year}
          />
        );
      case "FINANCIAL":
        return (
          <FinancialCollaborator
            token={token}
            year={filter.year}
            id={collaborator.id}
            name={collaborator.name}
            organization={navCommunity && navCommunity.id}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="page-content">
      <CollaboratorSpaceHeader
        collaboratorSpace={collaboratorSpace}
        onChangeYear={(item) => {
          setFilter({ ...filter, year: item });
        }}
        collaborator={collaborator}
        year={filter.year}
      />
      <div className="tab-container">
        {/* <TTPTab
          tabs={tabs}
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item });
          }}
          handleActivateArrowRight={() => {
            return;
          }}
        /> */}
        <TTPTab2
          tabs={tabs}
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id });
          }}
        />
      </div>

      <div className="content-body">{collaborator && renderTabContent()}</div>
    </div>
  );
};

export default CollaboratorSpace;
