import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCategoriesSuggestions } from "../../../api";
import "./Nettoyage/Nettoyage.local.scss";
import { Carousel } from "primereact/carousel";
import Nettoyage from "./Nettoyage";
import _ from "../../../i18n";
import "./ProductionCapacity.local.scss";
import Encoding from "./Encoding";

const ProductionCapacity = ({ filter, setFilter, hasAccess }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const [activeTab, setActiveTab] = useState({
    category: 4,
  });

  const categories = [
    {
      id: 4,
      label: _("04. ENCODAGE COMPTABLE."),
      category: "ENCODAGE COMPTABLE",
    },
    {
      id: 6,
      label: _("06. NETTOYAGE ET VERIFIC."),
      category: "NETTOYAGE ET VERIFICATION COMPTABILITE",
    },
  ];

  const renderPannelFrame = () => {
    return (
      <div style={{ width: "100%" }}>
        {activeTab.category === 6 ? (
          <Nettoyage filter={filter} setFilter={setFilter} />
        ) : activeTab.category === 4 ? (
          <Encoding filter={filter} setFilter={setFilter} />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  let categoriesQuery = useQuery(
    ["categoriesQuery", navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getCategoriesSuggestions(
            navCommunity.id,
            token
          );
          let categoriesResponse = response.data.data.map((item) => {
            return { id: item.id, label: item.label[language] };
          });
          // setCategories(categoriesResponse); // Assurez-vous d'avoir useState pour categories si vous voulez mettre à jour l'état
        } catch (e) {
          // setCategories([]);
          return null;
        }
      }
    }
  );

  return (
    <Fragment>
      {hasAccess === "1" ? (
        <div style={{ position: "relative", width: "100%" }}>
          <Carousel
            value={categories}
            numVisible={2}
            numScroll={0}
            showNavigators={false}
            style={{
              width: "100%",
              marginBottom: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            itemTemplate={(category, i) => {
              return (
                <div key={i}>
                  <div
                    className={`tab_badge ${
                      activeTab.category === category.id
                        ? "tab_badge_active"
                        : ""
                    }`}
                    onClick={() => setActiveTab({ category: category.id })}
                  >
                    <div>
                      {category.label}
                    </div>
                  </div>
                </div>
              );
            }}
          />
          {renderPannelFrame()}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default ProductionCapacity;
