import React, { useRef, useState } from "react";

import _ from "../../../i18n";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";
import { TiArrowSortedDown } from "react-icons/ti";
import { renderTeamYears, useOnClickOutside } from "../../../utils";

const EcheancesFilter = ({ filter, nbResult, updateFilter }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);

  const handleChangeZone = (id) => {
    updateFilter({
      ...filter,
      zone: id,
    });
  };

  const handleChangeYear = (year) => {
    updateFilter({
      ...filter,
      year: year,
    });
  };

  const renderYearSelect = () => {
      return (
        <div
          ref={ref}
          className={
            "customed_select" +
            " " +
            (yearDivIsVisible ? "customed_select_opened" : "")
          }
          style={{
            background: "#6d7f92",
            color: "#ffffff",
          }}
        >
          <div className={"customed_select_header"}>
            <span>{filter.year}</span>
            <div className={"actions"}>
              <div>
                <span
                  className={"action"}
                  onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
                >
                  <TiArrowSortedDown />
                </span>
              </div>
            </div>
          </div>
          {yearDivIsVisible && (
            <div className={"customed_select_body"}>
              <div className={"customed_select_body_options"}>
                {renderTeamYears().map((item, index) => (
                  <div
                    key={index}
                    className={"customed_select_body_option"}
                    onClick={() => {
                      handleChangeYear(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom" style={{ width: 0 }}>
        {renderYearSelect()}
        <div className="mini-tabs-frame">
          {[
            {
              id: null,
              label: "all",
              title: "tous les collaborateurs",
            },
            {
              id: "1",
              label: "belgique",
              title: "les collaborateurs de la belgique",
            },
            {
              id: "2",
              label: "maroc",
              title: "les collaborateurs du maroc",
            },
          ].map((item) => (
            <div
              className={filter.zone === item.id && "mini-tabs-frame_active"}
              onClick={() => handleChangeZone(item.id)}
            >
              {_(item.label)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePartnerChange = (partner) => {
    updateFilter({ ...filter, partner: Number(partner) });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("partner"),
        options: filter.partnerOptions,
        values: filter.partner,
        action: handlePartnerChange,
      },
    },
  ];

  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          subBG="F8F9FA"
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default EcheancesFilter;
