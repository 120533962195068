import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import "./BalancesRecap.local.scss";
import BalancesRecapFilter from "./BalancesRecapFilter";
import { getBalancePlannerRecap } from "../../../api";
import { renderDurationMinutesDisplay } from "../../../utils";
import { Tooltip } from "antd";

const BalancesRecap = ({ parentFilter, hasAccess }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [currentWeek, setCurrentWeek] = useState(null);
  const [balancesPlannerRecap, setBalancesPlannerRecap] = useState([]);

  const [filter, setFilter] = useState({
    ...parentFilter,
    year: new Date().getFullYear().toString(),
    keyWord: "",
    collaborator: null,
  });

  useEffect(() => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const daysSinceStart = Math.floor(
      (now - startOfYear) / (24 * 60 * 60 * 1000)
    );
    const currentWeekIndex = Math.floor(daysSinceStart / 7) + 1;

    setCurrentWeek(`Semaine ${currentWeekIndex}`);
    setExpandedWeeks({ [`Semaine ${currentWeekIndex}`]: true });
  }, []);

  let reportingsQuery = useQuery(
    ["getBalancePlannerRecap", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getBalancePlannerRecap(
            token,
            navCommunity.id,
            filter.partner,
            filter.year,
            filter.collaborator,
            filter.keyWord
          );
          setBalancesPlannerRecap(response.data.data);
          // setLoadingCollaborators(false);
        } catch (e) {
          // setLoadingCollaborators(false);
          return null;
        }
      }
    }
  );

  const toggleWeek = (week) => {
    setExpandedWeeks((prev) => ({
      ...prev,
      [week]: !prev[week],
    }));
  };

  return (
    <div className="balances_recap_page">
      {hasAccess === "1" && (
        <div className="page-content">
          <BalancesRecapFilter
            token={token}
            organization={navCommunity && navCommunity.id}
            filter={{ ...filter }}
            updateFilter={setFilter}
          />
          <div className="echeance_container">
            {balancesPlannerRecap ? (
              <table>
                <thead>
                  <tr>
                    <th>Semaine / Collaborateur</th>
                    <th>Bilan Planifié</th>
                    <th>Durée Estimée</th>
                    <th>Durée Réalisée</th>
                    <th>% Charge Atteinte</th>
                    <th>RDV Présentation</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {balancesPlannerRecap &&
                    balancesPlannerRecap.map((weekData, index) => (
                      <React.Fragment key={index}>
                        <tr className="week-row">
                          <td className="week-row" colSpan="7">
                            <button onClick={() => toggleWeek(weekData.week)}>
                              {expandedWeeks[weekData.week] ? "−" : "+"}{" "}
                              {weekData.week}
                            </button>
                          </td>
                        </tr>
                        {/* Collaborateurs */}
                        {expandedWeeks[weekData.week] &&
                          weekData.collaborators.map(
                            (collaborator, colIndex) => (
                              <tr key={colIndex} className="collaborator-row">
                                <td>{collaborator.name}</td>
                                <td>{collaborator.planned}</td>
                                <td>
                                  {renderDurationMinutesDisplay(
                                    collaborator.estimated
                                  )}
                                </td>
                                <td>
                                  {renderDurationMinutesDisplay(
                                    collaborator.realized
                                  )}
                                </td>
                                <td>
                                  <Tooltip
                                    placement="topRight"
                                    title={`${renderDurationMinutesDisplay(
                                      collaborator.estimated
                                    )} / ${collaborator.totalWorkingHours}h`}
                                  >
                                    {collaborator.charge}
                                  </Tooltip>
                                </td>
                                <td>{collaborator.presentation}</td>
                                <td>{collaborator.status}</td>
                              </tr>
                            )
                          )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BalancesRecap;
