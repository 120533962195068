import React, { useEffect, useState, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import TTPPaginator from "../../common/TTPPaginator";
import styles from "./ReactTable.local.scss";
import styles2 from "../DynamicTable.local.scss";
import {
  onError,
  renderRatingStars,
  toFixed,
  toNumber,
  getUserNameForAvatar,
  generateFullName,
  getTimeByDurationFormat,
} from "../../../utils";
import { NumericFormat } from "react-number-format";
// import NumberFormat from "react-number-format";
// import { BudgetIcon } from "../../Common/Icons";
import EditableCell from "../EditableCell";
import { Tooltip } from "antd";
import _ from "../../../i18n";

let levelState = "";
let pageState = 0;

function ReactTable({
  data,
  dataSource,
  dataTotal,
  lengthData,
  handleChangeStartPage,
  synchronizeData,
  updateData,
  skipPageReset,
  pageSizeProp,
  setPageSizeProp,

  pageSize,
  selectedYear,
  setSelectedLimit,
  pageIndexPagination,
  setPageIndexPagination,
  handleRedirectBudgetSetting,
}) {
  const [pageIndex, setPageIndex] = useState(pageIndexPagination);
  // const [pageSize, setPageSize] = useState(pageSizeProp);

  const updateCell = (rowIndex, value, property) => {
    const result = verifyBudgetRate(value, property);
    if (result) {
      onError(_("Error"));
      return false;
    }
    // updateData({ rowIndex, value, property });
    return true;
  };

  const renderAvatar = (firstName, lastName, avatarUrl, duration) => {
    if (
      (!firstName && !lastName && !avatarUrl && !duration > 0) ||
      levelState === "level-1"
    ) {
      return <p style={{ textAlign: "center" }}>_</p>;
    }
    let avatarDiv;
    if (!avatarUrl) {
      if (firstName && lastName) {
        avatarDiv = (
          <div className="avatar_not_exist">
            <span>{getUserNameForAvatar(lastName, firstName)}</span>
          </div>
        );
      } else {
        avatarDiv = (
          <div className="avatar_no">
            <span />
          </div>
        );
      }
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }
    return (
      <Tooltip
        key={`${firstName}_${lastName}`}
        placement="top"
        title={generateFullName(firstName, lastName)}
      >
        {avatarDiv}
        <p
          className="collaborator_duration"
          style={{ marginBottom: "0px", fontSize: "9px", marginTop: "2px" }}
        >
          {duration || duration === 0
            ? getTimeByDurationFormat(duration)
            : null}
        </p>
      </Tooltip>
    );
  };
  const synchronize = (...props) => {
    synchronizeData(...props);
  };
  const columns = useMemo(
    () => [
      {
        id: "client",
        Header: "Client",
        accessor: (row) => row,
        Cell: ({ value }) => {
          const { id, label, year, level } = value;
          levelState = level;
          return (
            <div>
              {label.length > 35 ? (
                <Tooltip placement="right" title={label} mouseLeaveDelay={0}>
                  <p>{`${label.substring(0, 35)}...`}</p>
                </Tooltip>
              ) : (
                <p>{label}</p>
              )}
            </div>
          );
        },
      },
      {
        Header: "Economique",
        columns: [
          {
            id: "economic",
            Header: `Budget ${selectedYear && selectedYear.split("/")[0]}`,
            accessor: (row) => row["economic"],
            Cell: ({ value, row: { index }, column: { id } }) => (
              <div className="cell_container">
                <span style={{ marginBottom: "3px" }}>
                  {levelState !== "level-1" ? (
                    <EditableCell
                      style={{ fontSize: "11px" }}
                      initialValue={toFixed(value["value"], 2)}
                      synchronize={(inputValue) =>
                        synchronize({
                          rowIndex: index + 1,
                          property: id,
                          value: inputValue,
                        })
                      }
                      updateCell={(inputValue) => {
                        const newEconomicBudget = {
                          ...value,
                          value: toFixed(inputValue, 2),
                        };
                        return updateCell(index + 1, newEconomicBudget, id);
                      }}
                    />
                  ) : (
                    <NumericFormat
                      value={value["value"]}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={" "}
                    />
                  )}
                  <p style={{ marginLeft: "2px", fontSize: "11px" }}>€</p>
                </span>
                <span style={{ fontSize: "x-small" }}>
                  <p>Taux budgétaire:</p>
                  {value["budgetaryBenchmarkRatio"]} €/h
                </span>
              </div>
            ),
          },
          {
            id: "profit",
            Header: `Réalisé ${
              selectedYear && toNumber(selectedYear.split("/")[1])
            }`,
            accessor: (row) => row["profit"],
            Cell: ({ value }) =>
              value && (
                <div className="cell_container">
                  <span style={{ marginBottom: "3px", fontSize: "11px" }}>
                    <NumericFormat
                      value={value.revenue}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={" "}
                    />
                    <p style={{ marginLeft: "2px" }}>€</p>
                  </span>
                  <span style={{ fontSize: "x-small" }}>
                    <p>Taux effectif:</p>
                    {value.ratio} €/h
                  </span>
                </div>
              ),
          },
        ],
      },

      {
        Header: "Benchmark",
        columns: [
          {
            id: "budgetaryBenchmark",
            Header: "Budgétaire",
            accessor: (row) => row["budgetaryBenchmark"],
            Cell: ({ value }) =>
              value && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <NumericFormat
                      style={{ fontSize: "11px" }}
                      value={value.ratio}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </span>
                  <div style={{ scale: "0.85", display: "flex" }}>
                    {renderRatingStars(Math.ceil(Math.random() * 10000), value)}
                  </div>
                </div>
              ),
          },
          {
            id: "benchmark",
            Header: "Effectif",
            accessor: (row) => row["benchmark"],
            Cell: ({ value }) =>
              value && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <NumericFormat
                    style={{ fontSize: "11px" }}
                    value={value.ratio}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                  />
                  <div style={{ scale: "0.85", display: "flex" }}>
                    {renderRatingStars(Math.ceil(Math.random() * 10000), value)}
                  </div>
                </span>
              ),
          },
        ],
      },

      {
        Header: "Horaire",
        columns: [
          {
            id: "budget",
            Header: `Budget ${selectedYear && selectedYear.split("/")[0]}`,
            accessor: (row) => row["budget"],
            Cell: ({ value }) =>
              value && (
                <div>
                  <span
                    style={{
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginBottom: "5px", fontSize: "11px" }}>
                      Total: {getTimeByDurationFormat(value.total)}
                    </p>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {value["subTotals"] &&
                      value["subTotals"].map((item) => (
                        <span key={item.zone} style={{ fontSize: "x-small" }}>
                          {item.zone}:{" "}
                          {getTimeByDurationFormat(toFixed(item.value, 2))}
                        </span>
                      ))}
                  </div>
                </div>
              ),
          },
          {
            id: "time",
            Header: `Réalisé ${
              selectedYear && toNumber(selectedYear.split("/")[1])
            }`,
            accessor: (row) => row["time"],
            Cell: ({ value }) =>
              value && (
                <div>
                  <span
                    style={{
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginBottom: "5px", fontSize: "11px" }}>
                      Total: {getTimeByDurationFormat(value.total)}
                    </p>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {value["subTotals"] &&
                      value["subTotals"].map((item) => (
                        <span key={item.zone} style={{ fontSize: "x-small" }}>
                          {item.zone}:{" "}
                          {getTimeByDurationFormat(toFixed(item.value, 2))}
                        </span>
                      ))}
                  </div>
                </div>
              ),
          },
        ],
      },

      {
        Header: `Responsables ${
          selectedYear && toNumber(selectedYear.split("/")[0])
        }`,
        columns: [
          {
            id: "supervisor",
            Header: "SUP.",
            accessor: (row) =>
              row["level"] === "level-0"
                ? row["responsibles"]["supervisor"]
                : "-AAA",
            Cell: ({ value }) => {
              const { firstName, lastName, avatarUrl, duration } = value;
              return renderAvatar(firstName, lastName, avatarUrl, duration);
            },
          },
          {
            id: "gd",
            Header: "GD",
            accessor: (row) =>
              row["level"] === "level-0" ? row["responsibles"]["GD"] : "-AAA",
            Cell: ({ value }) => {
              const { firstName, lastName, avatarUrl, duration } = value;
              return renderAvatar(firstName, lastName, avatarUrl, duration);
            },
          },

          {
            id: "ge",
            Header: "GE",
            accessor: (row) =>
              row["level"] === "level-0" ? row["responsibles"]["GE"] : "-AAA",
            Cell: ({ value }) => {
              const { firstName, lastName, avatarUrl, duration } = value;
              return renderAvatar(firstName, lastName, avatarUrl, duration);
            },
          },
          {
            id: "autre",
            Header: "AUTRE",
            accessor: (row) =>
              row["level"] === "level-0"
                ? row["responsibles"]["AUTRE"]
                : "-AAA",
            Cell: ({ value }) => {
              const { firstName, lastName, avatarUrl, duration } = value;
              return renderAvatar(firstName, lastName, avatarUrl, duration);
            },
          },
        ],
      },
    ],
    [selectedYear]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
      manualPagination: true, // gestion de la pagination côté backend
      pageCount: Math.ceil(lengthData / pageSizeProp),
    },
    usePagination
  );

  const { rows } = useTable(
    {
      columns,
      data: dataTotal || [],
    },
    usePagination
  );

  const handlePageChange = (object) => {
    pageState = object.selected;
    setSelectedLimit(object.selected);
    handleChangeStartPage(object.selected * pageSizeProp);
    // gotoPage(object.selected);
    setPageIndexPagination(object.selected);
  };
  console.log("rows====fprfr", rows);
  return (
    <div className="ttp-tab-container">
      <table {...getTableProps()} className="react_table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{}}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr style={{ height: "72px" }} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{ background: "rgb(200, 205, 210)" }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr style={{ height: "72px" }} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {console.log(
        "pageSize===",
        pageSize,
        pageSizeProp,
        "pageIndexPagination",
        pageIndexPagination
      )}
      <TTPPaginator
        onPageChange={handlePageChange}
        pageSize={pageSizeProp}
        nbResult={lengthData}
        // pageCount={Math.ceil(lengthData / pageSize)}
        paginationPage={pageIndexPagination + 1}
      />
    </div>
  );
}

const verifyBudgetRate = (object, property) => {
  switch (property) {
    case "budget":
      let totalZonesBudget = getTotalZonesBudget(object);
      const res = Number(object.total) < totalZonesBudget;
      return res;

    default:
      return false;
  }
};

const getTotalZonesBudget = (object) => {
  return object.subTotals.reduce(
    (prev, curr) => Number(prev) + Number(curr.value),
    0
  );
};

export default ReactTable;
