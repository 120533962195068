import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getBinomeFinancials } from "../../../../api";
import { TTP_UA_URL } from "../../../../config";
import {
  calculatePeriodDates,
  formatNumber,
  renderCollaboratorAvatar,
} from "../../../../utils";
import { CheckIcon, IconClose } from "../../../common/Icons";
import styles from "./Matrix.module.scss";

const { start, end } = calculatePeriodDates("_month");
export default function Matrix({
  token,
  loggedAs,
  language,
  organization,
  partner,
  activeBinome,
  year,
}) {
  const [financials, setFinancials] = useState([]);
  const { collaborator, backup } = activeBinome;

  const financialQuery = useQuery(
    ["getFinancialQuery", token, year, activeBinome],
    async () => {
      if (token && organization && activeBinome) {
        try {
          const ids = [collaborator.id];
          if (backup.id) {
            ids.push(backup.id);
          }
          const response = await getBinomeFinancials(
            token,
            ids,
            organization.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year,
            start,
            end
          );
          const data = response.data.data;
          setFinancials(data);
        } catch (e) {
          console.log(e);
          return {};
        }
      }
    },
    {
      enabled: !!token && !!organization && !!partner && !!activeBinome,
    }
  );

  useEffect(() => {
    financialQuery.refetch();
  }, [activeBinome, year]);

  let caTotal = 0;
  financials && financials.forEach((c) => (caTotal += c.ca.real));

  return (
    <div className={styles.matrix}>
      <div className={styles.header} />
      <div className={styles.header}>
        TimeSheet <span>{moment().format("MMMM")}</span>
      </div>
      <div className={styles.header} style={{ borderRight: 0 }}>
        C.A {`(${year})`} <span>{`${formatNumber(caTotal)} €`}</span>
      </div>

      {financialQuery.isFetching
        ? renderSkeleton()
        : financialQuery.isFetched &&
          financials &&
          financials.map((entry, index) => (
            <React.Fragment key={entry.id}>
              <div
                className={styles.cell}
                style={{ justifyContent: "flex-start", gap: 8 }}
              >
                {entry.id !== "others" && (
                  <div style={{ overflow: "hidden" }}>
                    {renderCollaboratorAvatar(
                      entry.avatar,
                      entry.fname,
                      entry.lname
                    )}
                  </div>
                )}
                <div>
                  <strong>{entry.fname + " " + entry.lname}</strong>
                  <em>{entry.role}</em>
                </div>
              </div>

              <div className={styles.cell}>
                <div style={{ flexDirection: "row", gap: 20 }}>
                  {entry.id != "others" ? (
                    <CheckMark
                      overBudget={entry.hour.real < entry.hour.theoric}
                    />
                  ) : (
                    ""
                  )}
                  <span>
                    {entry.hour.real % 60 == 0
                      ? entry.hour.real / 60
                      : (entry.hour.real / 60).toFixed(1)}
                    {`${
                      entry.hour.theoric ? `h/ ${entry.hour.theoric / 60}` : ""
                    }`}
                  </span>
                </div>
                <Link
                  to={`${TTP_UA_URL}&GOTO=${`/${language}/capacity-planing`}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.redirect_link}
                >
                  <FiArrowUpRight color="#29394D" />
                </Link>
              </div>
              <div className={styles.cell} style={{ borderRight: 0 }}>
                <span>{`${formatNumber(entry.ca.real)} €`}</span>
                <span>{`Ajusté: ${formatNumber(entry.ca.adjusted)} €`}</span>
                <Link
                  to={`${TTP_UA_URL}&GOTO=${`/${language}/fiche-collaborator/${entry.id}?tab=FINANCIAL`}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.redirect_link}
                >
                  <FiArrowUpRight color="#29394D" />
                </Link>
              </div>
            </React.Fragment>
          ))}
    </div>
  );
}

const CheckMark = ({ overBudget }) => {
  return (
    <div
      className={styles.mark}
      style={{ backgroundColor: overBudget ? "#FDEFEF" : "#EDFAF5" }}
    >
      {overBudget ? (
        <IconClose size={14} fill="#DE4848" />
      ) : (
        <CheckIcon width="14px" fill="#02AF8E" />
      )}
    </div>
  );
};

const renderSkeleton = () => {
  return [...Array(3)].map((_, index) => (
    <React.Fragment key={index}>
      <div
        className={styles.skeletonCell}
        style={{ justifyContent: "flex-start", gap: 8 }}
      >
        <div className={styles.skeletonAvatar} />
        <div>
          <div className={styles.skeletonTextLong} />
          <div className={styles.skeletonTextShort} />
        </div>
      </div>

      <div className={styles.skeletonCell}>
        <div style={{ display: "flex" }}>
          <div className={styles.skeletonIcon} />
          <div className={styles.skeletonTextMedium} />
        </div>
        <div className={styles.skeletonIcon} />
      </div>

      <div className={styles.skeletonCell} style={{ borderRight: 0 }}>
        <div className={styles.skeletonTextMedium} />
        <div className={styles.skeletonTextShort} />
        <div className={styles.skeletonIcon} />
      </div>
    </React.Fragment>
  ));
};
