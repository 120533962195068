import React from "react";
import _ from "../../i18n";

import "./Item.module.scss";
import { formatAmount } from "../../utils";

const Item = ({ item, index }) => {
  return (
    <div>
      <div className="header" style={{ margin: "15px 0px 15px 0px" }}>
        <div className="item_infos">
          <div className="item_infos_header">{item.name}</div>
          {index !== 0 && (
            <div className="item_infos_sub_header">
              {item.uen ? `${item.uen} - ` : ""} {item.group}{" "}
            </div>
          )}
          {index !== 0 && (
            <div className="item_infos_sub_header">
              {_("ID")} : {item.erp}
            </div>
          )}
        </div>
        <div className="item_entity_details" insucess_item>
          <div
            className="item_entity_details_items"
            style={{
              color: parseFloat(item.budget.hours.real) <= 0 && "#6D7F92",

              background: parseFloat(item.budget.hours.real) <= 0 && "#F1F2F4",
            }}
          >{`${formatAmount(item.budget.hours.real, false)}h`}</div>
        </div>
        <div className="item_entity_details">
          <div
            className="item_entity_details_items"
            style={{
              color: parseFloat(item.budget.economic.real) <= 0 && "#6D7F92",

              background:
                parseFloat(item.budget.economic.real) <= 0 && "#F1F2F4",
            }}
          >
            {formatAmount(item.budget.economic.real)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
