import React, { useState, useRef } from "react";
import { useQuery } from "react-query";

import _ from "../../../i18n";
import { useOnClickOutside, renderTeamYears } from "../../../utils";
import { getZonesSuggestions } from "../../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";

const EcheancesFilter = ({
  token,
  filter,
  nbResult,
  organization,
  updateFilter,
  setSelectedCollaboratorId,
  handleChangeSelectedZone,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);

  const handleChangeYear = (year) => {
    updateFilter({
      ...filter,
      year: year,
    });
  };

  const handleChangeZone = (id) => {
    setSelectedCollaboratorId(null);
    updateFilter({
      ...filter,
      selectedZone: id,
    });
    handleChangeSelectedZone();
  };

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    handleChangeYear(item)
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom">
        {renderYearSelect()}
        <div className="mini-tabs-frame">
          {[
            {
              id: "1",
              label: "belgique",
              title: "les collaborateurs de la belgique",
            },
            {
              id: "2",
              label: "maroc",
              title: "les collaborateurs du maroc",
            },
            {
              id: null,
              label: "all",
              title: "tous les collaborateurs",
            },
          ].map((item) => (
            <div
              className={
                filter.selectedZone === item.id && "mini-tabs-frame_active"
              }
              onClick={() => handleChangeZone(item.id)}
            >
              {_(item.label)}
              {/* <div className="span">{filter.counters[item.id]}</div> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  let zonesQuery = useQuery(["zonesQuery", organization], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, organization);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  const handlePartnerChange = (partner) => {
    updateFilter({ ...filter, partner: Number(partner) });
  };

  let filters = [
      {
        type: "SELECT",
        options: {
          isSimple: true,
          isClearable: true,
          placeholder: _("partner"),
          options: filter.partnerOptions,
          values: filter.partner,
          action: handlePartnerChange,
        },
      },
    ];

  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={10}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
          fetching={zoneOptions.isFetching}
        />
      </div>
    </div>
  );
};

export default EcheancesFilter;
