import { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import _ from "../../../i18n";
import { IconClose } from "../Icons";
import { Tooltip } from "antd";
import "./OffCanvasFilter.local.scss";

export default function OffCanvasFilter({ tabs, activeTab, selectTab }) {
  const [open, setOpen] = useState(false);
  const toggleOffCanvas = () => {
    setOpen(!open);
  };

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  return (
    <div className="off-canvas-filter">
      <Tooltip placement="left" title={_("filterReporting")}>
        <button
          type="button"
          className="filter_tab_btn"
          onClick={toggleOffCanvas}
        >
          <FaFilter size={20} />
        </button>
      </Tooltip>
      <div className={`filter-tabs-container ${open ? "open" : ""}`}>
        <div className="filter-tabs-container-header">
          <div>
            <h3>{_("filterReporting")}</h3>
            <span>{_("filterReportingSubtitle")}</span>
          </div>
          <button onClick={toggleOffCanvas}>
            <IconClose size={14} />
          </button>
        </div>
        <div className="filter-tabs-container-items">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`filter-tab ${
                tab.id == activeTab ? "filter-tab_active" : ""
              }`}
              onClick={() => {
                selectTab(tab);
                setOpen(false);
              }}
            >
              {_(tab.label)}
            </div>
          ))}
        </div>
      </div>
      {open && <div className="overlay" onClick={toggleOffCanvas}></div>}
    </div>
  );
}
