import axios from "axios";

import { TTP_API_URL } from "../config";

export const getPortfolioList = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/portfolio`;

  let params = {
    access_token: token,
    organization_id: organization,
    organization: organization,
    year: data.year,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getTimeSheetProgression = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/ts_progression`;
  let params = {
    access_token: token,
    organization_id: organization,
    partner: data.partner,
    organization: organization,
    year: data.year,
    collaborators: data.collaborators,
    clients: data.clients,
    role: data.role,
    bu: data.bu ? data.bu.value : null,
    zone: data.zone ? data.zone.value : null,
    sortBy: data.sort,
    deletedStatus: data.deletedStatus,
    billingType: data.billingType,
  };

  params.limit = data.pageSize || 10;
  params.start = !data.paginationPage ? 0 : (data.paginationPage - 1) * 10;

  return axios.get(requestUrl, {
    params,
  });
};

export const getCapacityPlaning = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators_calendar`;

  let params = {
    organizationId: organization,
    access_token: token,
    organization_id: organization,
    collaborators: data.collaborators,
    squads: data.squads,
    zones: data.zones,
    year: [data.year],
    clientCRMTypes: data.clientCRMTypes,
    clientCRMGroup: data.clientCRMGroup,
    deletedStatus: data.deletedStatus,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getCalendarRecap = (organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/calendar_recap`;

  let params = {
    organization: organization,
    access_token: token,
    organization_id: organization,
    partner: data.partner,
    year: data.year,
    month: data.month + 1,
    collaborators: data.collaborators,
    role: data.role,
    status: data.status ? (data.status === "employer" ? 0 : 1) : null,
    bu: data.bu ? [data.bu.value] : null,
    zone: data.zone ? [data.zone.value] : null,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getTimeSheetProgressionByCollaborator = ({
  token,
  organizationId,
  collaboratorId,
  filter
}) => {
  const requestUrl = `${TTP_API_URL}/team/ts_progression/${collaboratorId}`;
  let params = {
    access_token: token,
    organizationId,
    collaboratorId,
    year: filter.year,
    clients: filter.clients,
    role: filter.role,
    bu: filter.bu ? filter.bu.value : null,
    zone: filter.zone ? filter.zone.value : null,
    sortBy: filter.sort,
    deletedStatus: filter.deletedStatus,
    billingType: filter.billingType,
  };

  params.limit = filter.limit || 10;
  params.start = !filter.start ? 0 : (filter.start - 1) * 10;

  return axios.get(requestUrl, {
    params,
  });
}
