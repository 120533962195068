import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { PAGINATION_CURRENT_PAGE, PAGINATION_START, SUB_TABS, SUB_TABS_COLUMNS } from "./constants";
import { DatePicker, Input, Switch, Tooltip } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import './index.scss';
import _ from 'lodash';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatAmount, renderAvatar } from "../../../utils";
import AnimatedLoadingIcon from "../../common/Icons/AnimatedLoadingIcon";
import ClientsOutFilters from "../Components/ClientsOutFilters/ClientsOutFilters";
import { getRiskyClients, saveRiskyClients } from "../../../api";

export default function CroissanceClientsInRiskPage() {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [selectedSubTab, setSelectedSubTab] = useState(SUB_TABS[0]);
  const [bindedSubTabsColumns, setBindedSubTabsColumns] = useState(SUB_TABS_COLUMNS);
  const [showObservationModal, setShowObservationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [modifiedRows, setModifiedRows] = useState([]);
  const modifiedRowsRef = useRef(modifiedRows);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const [pageStart, setPageStart] = useState(PAGINATION_START);
  const [isSaving, setIsSaving] = useState({
    observation: false,
    rowData: false
  });
  // const [totalsData, setTotalsData] = useState([{
  //   'id': 0,
  //   'date': '-',
  //   'clientName': 'Total',
  //   'rdv': '--',
  //   'observation': '--'
  // }]);
  const [filters, setFilters] = useState({
    clientGroup: [],
    bu: [],
    partner: null,
    collaborator: null,
    role: null,
    pageSize: 10,
    year: new Date().getFullYear().toString(),
  });

  let getRiskyClientsQuery = useQuery(
    ["getRiskyClients", token, navCommunity, currentPage, pageStart, filters],
    async () => {
      if (token && navCommunity) {
        setIsLoadingData(true);
        try {
          const response = await getRiskyClients({
            token,
            organizationId: navCommunity.id,
            start: pageStart,
            filters
          });
          let data = response.data.data.items
          const totals = response.data.data.totals;

          // add attribute to control display/hide of prestatToBeDone
          data = data.map(item => ({
            ...item,
            displayAllPrestatToBeDone: false,
            updateCompensationField: false,
            updateReasonForDepartureField: false,
          }));

          setData(data);
          setDisplayedData(data);
          setTotalDataCount(response.data.data.totalCount);
          // setTotalsData(prevState => {
          //   const newVal = {...prevState[0], ...totals};
          //   return [newVal];
          // });
        } catch (e) {
          console.error("Error while fetching old clients", {e})
          return null;
        } finally {
          setIsLoadingData(false);
        }
      }
    }
  );

  useEffect(() => {
    // bind body templates to columns
    const BINDED_SUB_TABS_COLUMNS = {};

    BINDED_SUB_TABS_COLUMNS.admin = SUB_TABS_COLUMNS.admin.map((col) => {
      if (col.field === 'clientName') {
        col.body = clientColumnBodyTemplate;
      } else if (col.field === 'rdv') {
        col.body = rdvColumnBodyTemplate;
      } else if (col.field === 'observation') {
        col.body = observationColumnBodyTemplate;
      }
      return col;
    });
    setBindedSubTabsColumns(BINDED_SUB_TABS_COLUMNS);
  }, []);

  useEffect(() => {
    modifiedRowsRef.current = modifiedRows;
  }, [modifiedRows]);

  const renderSelectedColumns = () => {
    return bindedSubTabsColumns[selectedSubTab.key];
  }

  const clientColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span className="total-title">Total</span>);

    const clientName = rowData.client.name;
    const clientGroupDescription = rowData.client.groupDescription;
    const managerName = rowData.manager.name;
    const managerBU = rowData.manager.bu;
    const managerAvatarUrl = rowData.manager.avatarUrl;

    return (
      <div className="client-name-cell">
        <span className="client-name">{clientName}</span>
        <div className="client-other-info">
          <div className="client-type">
            {clientGroupDescription} - {managerBU}
          </div>
          <div className="client-manager-avatar">
            <Tooltip title={managerName}>
              {renderAvatar(managerAvatarUrl, managerName)}
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }

  const rdvColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    let date = null
    const keys = args.field.split('.');
    const hasValue = keys.reduce((obj, key) => obj && obj[key], rowData) &&
    keys.reduce((obj, key) => obj && obj[key], rowData) !== 'x';

    if (hasValue) {
      const [year, month, day] = rowData.rdv.split("-");
      date = new Date(year, month - 1, day);
    }

    return (
      <DatePicker
        value={!hasValue ? null : moment(date, "DD/MM")}
        onChange={(date) => {
          const year = date._d.getFullYear();
          const month = date._d.getMonth() + 1;
          const day = date._d.getDate();
          handleRowModification(rowData, 'rdv', `${year}-${month}-${day}`);
        }}
        format={"DD/MM"}
        picker="day"
        className="cell-datepicker-input"
      />
    )
  }

  const observationColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);
    const fieldValue = rowData[args.field]

    return (
      <div className="cell-input-field">
        <Input
          disabled={true}
          className="input"
          value={fieldValue}
          name={args.field}
          onChange={(e) => {
            handleRowModification(rowData, args.field, e.target.value);
          }}
        />
      </div>
    );
  }

  const handleRowModification = (rowData, modifiedPropertyName, modifiedPropertyValue) => {
    const keys = modifiedPropertyName.split('.');
    const updatedRowData = {
      ...rowData,
      [modifiedPropertyName]: modifiedPropertyValue
    };

    // Set the new value in the cloned rowData
    setValue({ ...rowData }, keys, modifiedPropertyValue);

    // Update the modifiedRows state

    setModifiedRows(prevState => {
      // Check if the row already exists in modifiedRows
      const modifiedRowIndex = prevState.findIndex(i => i.id === rowData.id);

      if (modifiedRowIndex > -1) {
        // Update existing modified row
        const updatedModifiedRows = [...prevState];
        updatedModifiedRows[modifiedRowIndex] = { ...updatedModifiedRows[modifiedRowIndex], ...updatedRowData };
        return updatedModifiedRows;
      } else {
        // Add new modified row
        return [...prevState, updatedRowData];
      }
    });

    // Update the displayedData state
    setDisplayedData(prevState =>
      prevState.map(i =>
        i.id === rowData.id ? { ...rowData, [modifiedPropertyName]: modifiedPropertyValue } : i
      )
    );
  }

  const setValue = (obj, keys, value) => {
    keys.reduce((accumulator, key, index) => {
      // If we're at the last key, set the value
      if (index === keys.length - 1) {
        accumulator[key] = value;
      } else {
        // If the key doesn't exist, create an empty object
        if (!accumulator[key]) {
          accumulator[key] = {};
        }
        return accumulator[key]; // Move to the next nested object
      }
      return accumulator;
    }, obj);
  };

  const handleSaveModifiedRows = () => {
    setIsSaving(prevState => ({...prevState, rowData: true}));

    saveRiskyClients({token, organizationId: navCommunity.id, items: modifiedRows}).then((res) => {
      if (res.status === 200) {
        setModifiedRows([]);
        toast.success("Les données ont été enregistrées avec succès");
        getRiskyClientsQuery.refetch();
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement des données");
      }
    }).catch(e => {
      toast.error("Une erreur est survenue lors de l'enregistrement des données");
      console.error("Error saving data : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, rowData: false}));
    });
  }

  useEffect(() => {
    setCurrentPage(0);
    setPageStart(0);
  }, [filters.pageSize]);

  return (
    <div className="clients-out-container">
       <div className="datatable-header">
        <div className="datatable-header-row">
          <div className="filters" style={{display: "flex", gap: "8px"}}>
            <ClientsOutFilters
              filterFormIsVisible={false}
              filter={filters}
              nbResult={10}
              updateFilter={setFilters}
              fetching={getRiskyClientsQuery.isFetching}
            />
          </div>
        </div>
        <div className="datatable-header-row">
          <p className="data-count">
            Nombre de dossiers: <span>{totalDataCount}</span>
          </p>
        </div>
      </div>
      <DataTable
        value={displayedData}
        lazy
        paginator
        first={currentPage * filters.pageSize + 1}
        rows={filters.pageSize}
        onPage={(e) => {
          setCurrentPage(e.page);
          setPageStart(e.page * filters.pageSize);
        }}
        scrollable
        scrollHeight="900px"
        tableClassName="clients-out-datatable"
        loading={isLoadingData}
        loadingIcon={
          <div className="ripple_loading" style={{alignItems: 'center'}}>
            <img src={"/img/Ripple-1s-150px.svg"} alt="" width={80} height={80} />
          </div>
        }
      >
        {
          renderSelectedColumns().map(col => (
            <Column
              className="datatable-column"
              key={col.field}
              field={col.field}
              header={col.header}
              style={{width: col.style?.width ?? 'auto'}}
              sortable={col.sortable ?? false}
              body={col.body ?? null}
              alignHeader={col?.alignHeader ?? 'left'}
              resizeable={col?.resizeable ?? false}
              align={col?.align ?? 'center'}
            />
          ))
        }
      </DataTable>

      {/* save/cancel buttons */}
      {modifiedRows.length > 0 && (
        <div className="action-buttons">
          <Button
            label="Annuler"
            severity="secondary"
            size="small"
            onClick={() => {
              setModifiedRows([]);
              setDisplayedData(_.cloneDeep(data));
            }}
            disabled={isSaving.rowData}
          />
          <Button
            size="small"
            label={
              isSaving.rowData ? (
                <AnimatedLoadingIcon color="#ffffff" />
              ) : 'Enregistrer'
            }
            disabled={isSaving.rowData}
            severity="success"
            onClick={handleSaveModifiedRows}
          />
        </div>
      )}
    </div>
  )
}
