import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { PAGINATION_CURRENT_PAGE, PAGINATION_LIMIT, PAGINATION_START, SUB_TABS, SUB_TABS_COLUMNS } from "./constants";
import { Checkbox, DatePicker, Input, Switch, Tooltip } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import PencilIcon from "../../common/Icons/PencilIcon";
import './index.scss';
import _ from 'lodash';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getNewClients, saveNewClients } from "../../../api";
import { toast } from "react-toastify";
import { CheckIcon, IconClose } from "../../common/Icons";
import { formatAmount } from "../../../utils";
import YearFilter from "../../common/Filter/YearFilter/YearFilter";
import AnimatedLoadingIcon from "../../common/Icons/AnimatedLoadingIcon";
import PaginationFilter from "../../common/Filter/PaginationFilter/PaginationFilter";
import ClientsOutFilters from "../Components/ClientsOutFilters/ClientsOutFilters";
import { Link } from "react-router-dom";
import RecordIcon from "../../common/Icons/RecordIcon";

export default function CroissanceClientsInPage() {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const [selectedSubTab, setSelectedSubTab] = useState(SUB_TABS[0]);
  const [bindedSubTabsColumns, setBindedSubTabsColumns] = useState(SUB_TABS_COLUMNS);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [modifiedRows, setModifiedRows] = useState([]);
  const modifiedRowsRef = useRef(modifiedRows);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const [pageStart, setPageStart] = useState(PAGINATION_START);
  const [pageLimit, setPageLimit] = useState(PAGINATION_LIMIT);
  const [isSaving, setIsSaving] = useState({
    comments: false,
    rowData: false
  });
  const [totalsData, setTotalsData] = useState([{
    'id': 0,
    'month': '-',
    'clientName': 'Total',
    'deadline': '--',
    'rdv': '--',
    'waitingForClient': '-',
    'todoDeg': '-',
    'orderSent': 0,
    'orderSigned': 0,
    'lmReception': 0,
    'lmProposition': 0,
    'oneShot': '0,00',
    'selectedPlan': '--',
    'planning': '€0,00',
    'governance': 0,
    'feeYearN': '€0,00',
    'feeYearN1': '€0,00',
    'appDemo': '--',
    'manager': '--',
    'threeMonths': '--',
    'sixMonths': '--',
    'nineMonths': '--',
    'volumetry': {
      purchases: 0,
      sales: 0,
      financialTransactions: 0
    }
  }]);

  const [filters, setFilters] = useState({
    clientGroup: [],
    bu: [],
    partner: null,
    collaborator: null,
    role: null,
    pageSize: 10,
    year: new Date().getFullYear().toString(),
  });

  let getNewClientsQuery = useQuery(
    ["getNewClients", token, navCommunity, currentPage, pageStart, filters],
    async () => {
      if (token && navCommunity) {
        setIsLoadingData(true);
        try {
          const response = await getNewClients({
            token,
            organizationId: navCommunity.id,
            start: pageStart,
            filters
          });
          const data = response.data.data.items
          const totals = response.data.data.totals;

          setData(data);
          setDisplayedData(data);
          setTotalDataCount(response.data.data.totalCount);
          setTotalsData(prevState => {
            const newVal = {...prevState[0], ...totals};
            return [newVal];
          });
        } catch (e) {
          console.error("Error while fetching new clients", {e})
          return null;
        } finally {
          setIsLoadingData(false);
        }
      }
    }
  );

  useEffect(() => {
    // bind body templates to columns
    const BINDED_SUB_TABS_COLUMNS = {};

    BINDED_SUB_TABS_COLUMNS.admin = SUB_TABS_COLUMNS.admin.map((col) => {
      if (col.field === 'month') {
        col.body = monthColumnBodyTemplate;
      } else if (col.field === 'clientName') {
        col.body = clientNameColumnBodyTemplate;
      } else if (col.field === 'rdv') {
        col.body = rdvColumnBodyTemplate;
      } else if (col.field === 'waitingForClient') {
        col.body = inputFieldColumnBodyTemplate;
      } else if (col.field === 'todoDeg') {
        col.body = inputFieldColumnBodyTemplate;
      } else if (col.field === 'orderSent') {
        col.body = checkUncheckColumnBodyTemplate;
      } else if (col.field === 'orderSigned') {
        col.body = checkUncheckColumnBodyTemplate;
      } else if (col.field === 'lmReception') {
        col.body = lmReceptionColumnBodyTemplate;
      } else if (col.field === 'lmProposition') {
        col.body = checkUncheckColumnBodyTemplate;
      } else if (col.field === 'oneShot' || col.field === 'planning' || col.field === 'feeYearN' || col.field === 'feeYearN1') {
        col.body = amountColumnsBodyTemplate;
      } else if (col.field === 'governance') {
        col.body = checkUncheckColumnBodyTemplate;
      }
      return col;
    });

    BINDED_SUB_TABS_COLUMNS.todo = SUB_TABS_COLUMNS.todo.map((col) => {
      if (col.field === 'appDemo') {
        col.body = switchColumnsBodyTemplate;
      } else if (col.field === 'manager') {
        col.body = gdPresentationColumnBodyTemplate;
      } else if (col.field === 'threeMonths' || col.field === 'sixMonths') {
        col.body = inputFieldColumnBodyTemplate
      } else if (col.field === 'nineMonths') {
        col.body = volumetryProgressionColumnBodyTemplate;
      }
      return col;
    });
    setBindedSubTabsColumns(BINDED_SUB_TABS_COLUMNS);
  }, []);

  useEffect(() => {
    modifiedRowsRef.current = modifiedRows;
  }, [modifiedRows]);

  const renderSelectedColumns = () => {
    return bindedSubTabsColumns[selectedSubTab.key];
  }

  const monthColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span>#</span>);

    let month = rowData.deadline.split("-")[1];

    month = month.startsWith('0') ? month.slice(1) : month;

    return (<span>{month}</span>);
  }

  const clientNameColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span className="total-title">Total</span>);

    const clientName = rowData.clientName;
    const type = rowData.clientGroup;
    const managerBU = rowData.managerBU;

    return (
      <div className="client-name-cell">
        <div className="client-name-cell-data">
          <span className="client-name-text">{clientName}</span>
          <div className="client-name-info">
            {type} - {managerBU}
          </div>
        </div>
        <div className="client-name-icons">
          <Tooltip placement="topRight" title="Ajouter/Modifier un commentaire">
            <PencilIcon
              width="16"
              height="16"
              className="icon"
              onClick={() => handleShowCommentsModal(true, rowData)}
            />
          </Tooltip>
          <Link
            to={`/${language}/fiche-client/${rowData.clientId}`}
          >
            <Tooltip placement="topRight" title="Consulter la fiche client">
              <RecordIcon
                size={18}
                className="icon"
              />
            </Tooltip>
          </Link>
        </div>
      </div>
    )
  }

  const rdvColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    let date = null
    const keys = args.field.split('.');
    const hasValue = keys.reduce((obj, key) => obj && obj[key], rowData) &&
    keys.reduce((obj, key) => obj && obj[key], rowData) !== 'x';

    if (hasValue) {
      const [year, month, day] = rowData.rdv.split("-");
      date = new Date(year, month - 1, day);
    }

    return (
      <DatePicker
        value={!hasValue ? null : moment(date, "DD/MM")}
        onChange={(date) => {
          const year = date._d.getFullYear();
          const month = date._d.getMonth() + 1;
          const day = date._d.getDate();
          handleRowModification(rowData, 'rdv', `${year}-${month}-${day}`);
        }}
        format={"DD/MM"}
        picker="day"
        className="cell-datepicker-input"
      />
    )
  }

  const switchColumnsBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    let classes = 'cell-switch-input';
    const keys = args.field.split('.');
    const isChecked = keys.reduce((obj, key) => obj && obj[key], rowData) === true || keys.reduce((obj, key) => obj && obj[key], rowData) === 'x';

    if (isChecked) {
      classes += ' cell-switch-input-checked';
    }

    return (
      <Switch
        size="small"
        checked={isChecked}
        className={classes}
        onChange={(checked) => {
          handleRowModification(rowData, args.field, checked);
        }}
      />
    )
  }

  const inputFieldColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);
    const fieldValue = rowData[args.field]

    return (
      <div className="cell-input-field">
        <Input
          className="input"
          value={fieldValue}
          name={args.field}
          onChange={(e) => {
            handleRowModification(rowData, args.field, e.target.value);
          }}
        />
      </div>
    )
  }

  const checkUncheckColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      switch(args.field) {
        case "orderSent": return (<span>{rowData.orderSent}</span>);
        case "orderSigned": return (<span>{rowData.orderSigned}</span>);
        case "governance": return (<span>{rowData.governance}</span>);
        case "lmProposition": return (<span>{rowData.lmProposition}</span>);
        default: return (<span>--</span>);
      }
    }

    if (rowData[args.field]) {
      return <div class="centered-cell">
        <CheckIcon width="20" height="12" fill="#15B392" />
      </div>
    } else {
      return <div class="centered-cell">
        <IconClose size="11" fill="#C7253E" />
      </div>
    }
  }

  const lmReceptionColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) {
      return (<span>{rowData.lmReception}</span>);
    }
    const value = rowData.lmReception;
    let displayValue = "N/A";

    if (value === "1") displayValue = 'Gagné';
    else displayValue = 'Perdu';

    return (
      <div className="centered-cell">
        {displayValue}
      </div>
    )
  }

  const amountColumnsBodyTemplate = (rowData, args) => {
    if (['feeYearN', 'feeYearN1', 'planning'].includes(args.field)) {
      return (<div>{formatAmount(rowData[args.field], true)}</div>);
    }
    return (<div>{formatAmount(rowData[args.field])}</div>);
  }

  const gdPresentationColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) {
      return <span>--</span>;
    }
    const isPresentationDone = rowData.isGdPresentationDone;

    if (!rowData.manager) {
      return '--';
    }

    return (
      <div className="cell-manager">
        <span className="cell-manager-name">{rowData.manager}</span>
        <Tooltip title={isPresentationDone ? 'La pésentation de GD est faite' : "La présentation de GD n'est pas faite"}>
          <Checkbox
            className="cell-manager-checkbox"
            checked={isPresentationDone}
            onChange={() => {
              handleRowModification(rowData, 'isGdPresentationDone', !isPresentationDone);
            }}
          />
        </Tooltip>
      </div>
    )
  }

  const volumetryProgressionColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) {
      return <span>--</span>;
    }

    return (
      <div className="cell-nine-months">
        <div className="cell-input-field">
          <Input
            className="input"
            value={rowData.nineMonths}
            onChange={(e) => {
              handleRowModification(rowData, 'nineMonths', e.target.value);
            }}
          />
        </div>
        <div className="cell-nine-months-volumetry">
          <p className="cell-nine-months-volumetry-item">
            <span>FA:</span>
            {Math.round(rowData.volumetry.purchases)}
          </p>
          <p className="cell-nine-months-volumetry-item">
            <span>FV:</span>
            {Math.round(rowData.volumetry.sales)}
          </p>
          <p className="cell-nine-months-volumetry-item">
            <span>BQ:</span>
            {Math.round(rowData.volumetry.financialTransactions)}
          </p>
        </div>
      </div>
    )
  }

  const handleShowCommentsModal = (isVisible, rowData) => {
    if (rowData) {
      setSelectedRow(rowData);
    } else {
      setSelectedRow(null);
    }
    setShowCommentsModal(isVisible);
  }

  const handleRowModification = (rowData, modifiedPropertyName, modifiedPropertyValue) => {
    const keys = modifiedPropertyName.split('.');
    const updatedRowData = {
      ...rowData,
      [modifiedPropertyName]: modifiedPropertyValue
    };

    // Set the new value in the cloned rowData
    setValue({ ...rowData }, keys, modifiedPropertyValue);

    // Update the modifiedRows state

    setModifiedRows(prevState => {
      // Check if the row already exists in modifiedRows
      const modifiedRowIndex = prevState.findIndex(i => i.id === rowData.id);

      if (modifiedRowIndex > -1) {
        // Update existing modified row
        const updatedModifiedRows = [...prevState];
        updatedModifiedRows[modifiedRowIndex] = { ...updatedModifiedRows[modifiedRowIndex], ...updatedRowData };
        return updatedModifiedRows;
      } else {
        // Add new modified row
        return [...prevState, updatedRowData];
      }
    });

    // Update the displayedData state
    setDisplayedData(prevState =>
      prevState.map(i =>
        i.id === rowData.id ? { ...rowData, [modifiedPropertyName]: modifiedPropertyValue } : i
      )
    );
  }

  const setValue = (obj, keys, value) => {
    keys.reduce((accumulator, key, index) => {
      // If we're at the last key, set the value
      if (index === keys.length - 1) {
        accumulator[key] = value;
      } else {
        // If the key doesn't exist, create an empty object
        if (!accumulator[key]) {
          accumulator[key] = {};
        }
        return accumulator[key]; // Move to the next nested object
      }
      return accumulator;
    }, obj);
  };

  const handleYearSelectionChange = (selectedYear) => {
    setSelectedYear(selectedYear);
  }

  const handlePaginationValueChange = (selectedValue) => {
    setPageLimit(selectedValue);
  }

  const handleSaveModifiedRows = () => {
    setIsSaving(prevState => ({...prevState, rowData: true}));

    saveNewClients({token, organizationId: navCommunity.id, items: modifiedRows}).then((res) => {
      if (res.status === 200) {
        setModifiedRows([]);
        toast.success("Les données ont été enregistrées avec succès");
        getNewClientsQuery.refetch();
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement des données");
      }
    }).catch(e => {
      toast.error("Une erreur est survenue lors de l'enregistrement des données");
      console.error("Error saving data : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, rowData: false}));
    });
  }

  const handleSaveComment = () => {
    const { id, clientId, comments } = selectedRow;

    setIsSaving(prevState => ({...prevState, comments: true}));
    saveNewClients({token, organizationId: navCommunity.id, items: [{id, clientId, comments}]}).then((res) => {
      if (res.status === 200) {
        setSelectedRow(null);
        toast.success("Commentaire enregistré avec succès.");
        getNewClientsQuery.refetch();
      } else {
        toast.error("Échec de l'enregistrement du commentaire. Veuillez réessayer.");
      }
    }).catch(e => {
      toast.error("Échec de l'enregistrement du commentaire. Veuillez réessayer.");
      console.error("Error saving comment : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, comments: false}));
    });
  }

  useEffect(() => {
    setCurrentPage(0);
    setPageStart(0);
  }, [pageLimit]);

  return (
    <div className="clients-in-container">
       <div className="datatable-header">
        <div className="datatable-header-row">
          <div className="filters" style={{display: "flex", gap: "8px"}}>
            <ClientsOutFilters
              filterFormIsVisible={false}
              filter={filters}
              nbResult={10}
              updateFilter={setFilters}
              fetching={getNewClientsQuery.isFetching}
            />
          </div>
        </div>
        <div className="datatable-header-row">
          <p className="data-count">
            Nombre de dossiers: <span>{totalDataCount}</span>
          </p>
          <div className="display-info-buttons">
            {SUB_TABS.map((tab) => (
              <button
                key={tab.key}
                className={`display-info-button ${selectedSubTab.key === tab.key ? 'active' : ''}`}
                onClick={() => setSelectedSubTab(tab)}>
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>
      <DataTable
        value={displayedData}
        frozenValue={totalsData}
        lazy
        paginator
        totalRecords={totalDataCount}
        first={currentPage * filters.pageSize + 1}
        rows={filters.pageSize}
        onPage={(e) => {
          setCurrentPage(e.page);
          setPageStart(e.page * filters.pageSize);
        }}
        scrollable
        scrollHeight="900px"
        tableClassName="clients-in-datatable"
        loading={isLoadingData}
        loadingIcon={
          <div className="ripple_loading" style={{alignItems: 'center'}}>
            <img src={"/img/Ripple-1s-150px.svg"} alt="" width={80} height={80} />
          </div>
        }
      >
        {
          renderSelectedColumns().map(col => (
            <Column
              className="datatable-column"
              key={col.field}
              field={col.field}
              header={col.header}
              style={{width: col.style?.width ?? 'auto'}}
              sortable={col.sortable ?? false}
              body={col.body ?? null}
              alignHeader={col?.alignHeader ?? 'left'}
              resizeable={col?.resizeable ?? false}
              align={col?.align ?? 'center'}
            />
          ))
        }
      </DataTable>

      {/* save/cancel buttons */}
      {modifiedRows.length > 0 && (
        <div className="action-buttons">
          <Button
            label="Annuler"
            severity="secondary"
            size="small"
            onClick={() => {
              setModifiedRows([]);
              setDisplayedData(_.cloneDeep(data));
            }}
            disabled={isSaving.rowData}
          />
          <Button
            size="small"
            label={
              isSaving.rowData ? (
                <AnimatedLoadingIcon color="#ffffff" />
              ) : 'Enregistrer'
            }
            disabled={isSaving.rowData}
            severity="success"
            onClick={handleSaveModifiedRows}
          />
        </div>
      )}

      {/* comments modal dialog */}
      {showCommentsModal && selectedRow && (
        <Dialog
          visible={showCommentsModal && selectedRow}
          onHide={() => handleShowCommentsModal(false)}
          className="comments-modal-dialog"
          header="Ajouter/Modifier un commentaire"
          footer={
            <div>
              <Button
                size="small"
                severity="success"
                onClick={handleSaveComment}
                disabled={isSaving.comments}
              >
                {
                  isSaving.comments ? (
                    <AnimatedLoadingIcon color="#ffffff" />
                  ):
                  'Enregistrer'
                }
              </Button>
            </div>
          }
        >
          <span className="p-float-label" style={{marginTop: '20px'}}>
            <InputTextarea
              id="comments"
              className="comments-textarea"
              rows={5}
              value={selectedRow.comments}
              disabled={isSaving.comments}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedRow(prevState => ({...prevState, comments: value}));
              }}
            />
            <label htmlFor="comments">Commentaires</label>
          </span>
        </Dialog>
      )}
    </div>
  )
}
