export default function RecordIcon({size = 16, color = '#000'}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      class="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M781.6 906.96666667H247c-28.3 0-51.3-23-51.3-51.3V182.06666667c0-28.3 23-51.3 51.3-51.3h534.6c28.3 0 51.3 23 51.3 51.3V855.66666667c0 28.3-23 51.3-51.3 51.3z" fill="#FFFFFF" />
      <path d="M781.6 958.3H247c-44.8 0-81.3-37.5-81.3-83.6V181.8c0-46.1 36.5-83.6 81.3-83.6h534.6c44.8 0 81.3 37.5 81.3 83.6v692.8c0 46.2-36.5 83.7-81.3 83.7zM247 159.9c-11.7 0-21.3 9.8-21.3 21.9v692.8c0 12.1 9.6 21.9 21.3 21.9h534.6c11.7 0 21.3-9.8 21.3-21.9V181.8c0-12.1-9.6-21.9-21.3-21.9H247z" fill={color} />
      <path d="M385.6 80.36666667H643v100.8H385.6z" fill="#FFFFFF" />
      <path d="M649.8 195.5h-271c-4.5 0-8.2-3.7-8.2-8.2V72.9c0-4.5 3.7-8.2 8.2-8.2h271c4.5 0 8.2 3.7 8.2 8.2v114.5c0 4.4-3.7 8.1-8.2 8.1z m-249.2-30H628V94.7H400.6v70.8z" fill={color} />
      <path d="M584.9 145.1H441.1c-3.1 0-5.6-2.5-5.6-5.6v-18.9c0-3.1 2.5-5.6 5.6-5.6H585c3.1 0 5.6 2.5 5.6 5.6v18.9c-0.1 3.1-2.6 5.6-5.7 5.6z" fill="727272" />
    </svg>
  );
}
