import { Image, Tooltip } from "antd";
import { Carousel } from "primereact/carousel";
import { useEffect, useRef, useState } from "react";
import {
  FaAngleDown,
  FaAngleUp,
  FaArrowCircleDown,
  FaArrowCircleUp,
} from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  getBonusCount,
  getClientOrderSignature,
  getClientsIN,
  getClientsOut,
  getClientsReview,
  getCPProgression,
  getDevelopmentPlans,
  getProfitProgression,
  getScheduleProgression,
} from "../../../api";
import { getPartners } from "../../../api/partners";
import _ from "../../../i18n";
import { formatNumber, renderAvatar, renderTeamYears } from "../../../utils";
import WavingHand from "../assets/wavingHand.svg";
import { CollapsibleCard } from "../components/CollapsibleCard";
import RedirectCard from "../components/RedirectCard/RedirectCard";
import CapacityProduction from "./CapacityProduction";
import styles from "./Dashboard.module.scss";
import Stats from "./Stats";

const regionTabs = [
  { label: _("morocco"), value: 2 },
  { label: _("belgium"), value: 1 },
];

const folderTabs = [
  { label: _("schedule"), value: "SCHEDULE" },
  { label: _("volumetry"), value: "VOLUMETRY" },
];

const financialTabs = [
  { label: _("ca"), value: "CA" },
  { label: _("profitability"), value: "PROFIT" },
  { label: _("outstanding"), value: "OUTSTANDING" },
];

export default function DashboardPartner() {
  const ref = useRef();

  const { token, user, navCommunity, loggedAs, spaceAs } = useSelector(
    (state) => state.auth
  );
  const { language: lang } = useSelector((state) => state.params);

  const [partner, setPartner] = useState(
    ["SUPERADMIN", "ADMIN"].includes(loggedAs) ? null : user
  );
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [progression, setProgression] = useState({
    schedule: [],
    volumetry: [],
    ca: [],
    profit: [],
    outstanding: [],
    cp: {
      be: [],
      ma: [],
    },
    devPlan: [],
    bonus: [],
    reviewList: [],
    op: [],
  });
  const [clientsIN, setClientsIN] = useState([]);
  const [clientsOut, setClientsOut] = useState([]);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
    regionTab: regionTabs[0].value,
    folderTab: folderTabs[0].value,
    financialTab: financialTabs[0].value,
    cpVisibleItems: 3,
    expanded: null,
  });

  const handleLoadMore = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      cpVisibleItems: prevFilter.cpVisibleItems + 2,
    }));
  };

  const handleLoadLess = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      cpVisibleItems: prevFilter.cpVisibleItems - 2,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setYearDivIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setYearDivIsVisible]);

  const { data, error, isLoading } = useQuery(
    ["partnersQuery", token, navCommunity],
    async () => {
      if (
        token &&
        navCommunity &&
        ["SUPERADMIN", "ADMIN"].includes(loggedAs) &&
        ["SUPERADMIN", "ADMIN"].includes(spaceAs)
      ) {
        const response = await getPartners({
          token,
          organizationId: navCommunity?.id,
        });
        const data = response.data.data
          .map((partner) => ({
            value: partner.id,
            label: partner.name,
          }));
        setPartnerOptions(data);
        if (data.length > 0) {
          const initPartner = data.filter((partner) => partner.value == 25295);
          setPartner(initPartner[0]);
        }
        return data;
      }
    },
    {
      enabled: !!token && !!navCommunity,
    }
  );

  const scheduleProgression = useQuery(
    ["getScheduleProgressionQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getScheduleProgression(
            token,
            navCommunity,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year,
            filter.folderTab
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            [filter.folderTab.toLowerCase()]:
              data[filter.folderTab.toLowerCase()],
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  useEffect(() => {
    scheduleProgression.refetch();
  }, [filter.folderTab, filter.year, partner]);

  const profitProgression = useQuery(
    ["getProfitProgressionQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getProfitProgression(
            token,
            navCommunity,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year,
            filter.financialTab
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            [filter.financialTab.toLowerCase()]:
              data[filter.financialTab.toLowerCase()],
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  useEffect(() => {
    profitProgression.refetch();
  }, [filter.financialTab, filter.year, partner]);

  const productionProgression = useQuery(
    ["getProductionProgressionQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getCPProgression(
            token,
            navCommunity,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year,
            filter.regionTab
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            cp: {
              ...prevProgression.cp,
              [filter.regionTab == 1 ? "be" : "ma"]: data,
            },
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  useEffect(() => {
    productionProgression.refetch();
  }, [filter.regionTab, filter.year, partner]);

  const clientsINQuery = useQuery(
    ["getClientsINQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getClientsIN(
            token,
            navCommunity?.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year
          );
          const data = response.data.data;
          setClientsIN(data);
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  const clientsOutQuery = useQuery(
    ["getClientsOutQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getClientsOut(
            token,
            navCommunity?.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year
          );
          const data = response.data.data;
          setClientsOut(data);
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  const devPlanQuery = useQuery(
    ["getDevPlanQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getDevelopmentPlans(
            token,
            navCommunity?.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            devPlan: data,
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  const bonusQuery = useQuery(
    ["getBonusQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getBonusCount(
            token,
            navCommunity?.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            bonus: data,
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  const clientsReviewQuery = useQuery(
    ["getClientsReviewQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getClientsReview(
            token,
            navCommunity?.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            reviewList: data,
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  const clientOrderSignatureQuery = useQuery(
    ["getClientOrderSignatureQuery", token, navCommunity, partner],
    async () => {
      if (token && navCommunity && partner && filter.year) {
        try {
          const response = await getClientOrderSignature(
            token,
            navCommunity?.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            filter.year
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            op: data,
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!partner,
    }
  );

  useEffect(() => {
    clientsINQuery.refetch();
    clientsOutQuery.refetch();
    devPlanQuery.refetch();
    bonusQuery.refetch();
    clientsReviewQuery.refetch();
    clientOrderSignatureQuery.refetch();
  }, [filter.year, partner]);

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => setFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const {
    regionTab,
    folderTab,
    financialTab,
    year,
    cpVisibleItems: visibleCount,
    expanded,
  } = filter;

  const alias = regionTab == 1 ? "be" : "ma";
  const visibleData = progression.cp[alias].slice(0, visibleCount);

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <div className={styles.container_header_salutation}>
          <Image
            src={WavingHand}
            alt="waving-hand"
            width={20}
            height={20}
            className={styles.waving_hand}
          />
          <span>
            Bonjour, <strong>{user?.firstName}</strong>
          </span>
        </div>
        <div className={styles.container_header_filter}>
          {["SUPERADMIN", "ADMIN"].includes(loggedAs) &&
            ["SUPERADMIN", "ADMIN"].includes(spaceAs) && (
              <Select
                isSearchable
                closeMenuOnSelect
                placeholder="Partner"
                options={partnerOptions}
                value={partner}
                onChange={(inputValue) => setPartner(inputValue)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: 0,
                    backgroundColor: "transparent",
                  }),
                }}
              />
            )}
          {renderYearSelect()}
        </div>
      </div>
      {/* Dashboard content */}
      <div className={styles.main}>
        {/* Left */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            width: "calc(75% - 10px)",
          }}
        >
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            partner && (
              <>
                <Stats
                  token={token}
                  loggedAs={loggedAs}
                  organization={navCommunity}
                  partner={partner}
                  year={year}
                />
                <CapacityProduction
                  token={token}
                  loggedAs={loggedAs}
                  organization={navCommunity}
                  partner={partner}
                  year={year}
                />
              </>
            )
          )}
          <div className={styles.main_dev_in}>
            <RedirectCard
              title="Croissance"
              to={`/${lang}/croissance`}
              style={{
                marginTop: "20px",
                width: "100%",
                height: "100%",
                borderColor: "#F1F2F4",
                flexGrow: 2,
              }}
            >
              <div className={styles.container}>
                <div className={styles.column}>
                  <div>
                    {clientsINQuery.isFetched &&
                    clientsIN &&
                    clientsIN.items &&
                    clientsIN.items.length > 0 ? (
                      clientsIN.items.map((client) => (
                        <CollapsibleCard
                          key={client.id}
                          name={client.name}
                          badge="in"
                          label="in"
                          attributed={client.attributed}
                          attribution={client.attribution}
                          clientGroup={client.group}
                          clientHourBudget={client.budget}
                        />
                      ))
                    ) : (
                      <div
                        className="no_data"
                        style={{
                          height: 10,
                          width: "100%",
                        }}
                      >
                        <div className="title" style={{ fontSize: 10 }}>
                          {`${_("noClientIN")} ${filter.year}`}
                        </div>
                      </div>
                    )}
                    {clientsIN.remaining > 0 && (
                      <div className={styles.remaining_counter}>
                        {`+ ${clientsIN.remaining} Clients IN`}
                      </div>
                    )}
                  </div>
                  <div>
                    {clientsOutQuery.isFetched &&
                    clientsOut &&
                    clientsOut.items &&
                    clientsOut.items.length > 0 ? (
                      clientsOut.items.map((client) => (
                        <CollapsibleCard
                          key={client.id}
                          name={client.name}
                          badge="out"
                          label="out"
                          prestationEndDate={client.departDate}
                          prestationRest={client.tasks}
                        />
                      ))
                    ) : (
                      <div
                        className="no_data"
                        style={{
                          height: 10,
                          width: "100%",
                        }}
                      >
                        <div className="title" style={{ fontSize: 10 }}>
                          {`${_("noClientOUT")} ${filter.year}`}
                        </div>
                      </div>
                    )}
                    {clientsOut.remaining > 0 && (
                      <div className={styles.remaining_counter}>
                        {`+ ${clientsOut.remaining} Clients Out`}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles.column}>
                  <div>
                    {clientOrderSignatureQuery.isFetched &&
                    progression.op?.items &&
                    progression.op?.items.length > 0 ? (
                      progression.op.items.map((client) => (
                        <CollapsibleCard
                          key={client.id}
                          name={client.name}
                          badge="op"
                          label={client.signed ? "OP signé" : "OP pas signé"}
                          signed={client.signed}
                          clientGroup={client.group}
                          clientHourBudget={client.hourBudget}
                          clientEconomicBudget={client.economicBudget}
                        />
                      ))
                    ) : (
                      <div
                        className="no_data"
                        style={{
                          height: 10,
                          width: "100%",
                        }}
                      >
                        <div className="title" style={{ fontSize: 10 }}>
                          {`${_("noOP")} ${filter.year}`}
                        </div>
                      </div>
                    )}
                    {progression.op?.remaining > 0 && (
                      <div className={styles.remaining_counter}>
                        {`+ ${progression.op?.remaining} OP`}
                      </div>
                    )}
                  </div>
                  <div>
                    {clientsReviewQuery.isFetched &&
                    progression.reviewList?.items &&
                    progression.reviewList?.items.length > 0 ? (
                      progression.reviewList.items.map((client) => (
                        <CollapsibleCard
                          key={client.id}
                          name={client.name}
                          badge="forfait"
                          label={
                            client.reviewStatus === "IN_REVIEW"
                              ? "Révision en cours"
                              : "Révision terminée"
                          }
                          clientGroup={client.group}
                          clientHourBudget={client.totalBudget}
                          clientEconomicBudget={client.economicBudget}
                          inReview={client.reviewStatus === "IN_REVIEW"}
                          doneReview={client.reviewStatus === "REVIEWED"}
                        />
                      ))
                    ) : (
                      <div
                        className="no_data"
                        style={{
                          height: 10,
                          width: "100%",
                        }}
                      >
                        <div className="title" style={{ fontSize: 10 }}>
                          {`${_("noClientReview")} ${filter.year}`}
                        </div>
                      </div>
                    )}
                    {progression.reviewList?.remaining > 0 && (
                      <div className={styles.remaining_counter}>
                        {`+ ${progression.reviewList?.remaining} Révisions`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </RedirectCard>
            {/* todo: Add RH section */}
            <RedirectCard
              title="RH"
              to={`/${lang}/reporting?tab=BONUSES`}
              style={{
                marginTop: "20px",
                width: "100%",
                height: "100%",
                borderColor: "#F1F2F4",
              }}
            >
              <div className={styles.rh_redirect_card}>
              <div>
                  <h2>{_("bonus")}</h2>
                  <div>
                    {bonusQuery.isFetching
                      ? "Loading"
                      : bonusQuery.isFetched &&
                        progression.bonus?.items &&
                        progression.bonus?.items.length &&
                        progression.bonus?.items.map((co) => (
                          <div className={styles.rh_card}>
                            <div className={styles.rh_card_avatar}>
                              <div className={styles.rh_card_avatar_avatar}>
                                {renderAvatar(
                                  co.avatar,
                                  `${co.fname} ${co.lname}`
                                )}
                              </div>
                              <div>
                                <strong>{`${co.fname} ${co.lname[0]}.`}</strong>
                                <em>{co.role}</em>
                              </div>
                            </div>
                            <div className={styles.rh_card_dev_plan}>
                              <div className={styles.rh_card_dev_plan_count}>
                                {co.bonus_count}
                              </div>
                              <div>Bonus atteint</div>
                            </div>
                          </div>
                        ))}
                    {progression.bonus?.remaining > 0 && (
                      <div className={styles.remaining_counter}>
                        {`+ ${progression.bonus?.remaining} Collaborateurs`}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <h2>{_("devPlan")}</h2>
                  <div>
                    {devPlanQuery.isFetching
                      ? "Loading"
                      : devPlanQuery.isFetched &&
                        progression.devPlan?.items &&
                        progression.devPlan?.items.length &&
                        progression.devPlan?.items.map((co) => (
                          <div className={styles.rh_card}>
                            <div className={styles.rh_card_avatar}>
                              <div className={styles.rh_card_avatar_avatar}>
                                {renderAvatar(
                                  co.avatar,
                                  `${co.fname} ${co.lname}`
                                )}
                              </div>
                              <div>
                                <strong>{`${co.fname} ${co.lname[0]}.`}</strong>
                                <em>{co.role}</em>
                              </div>
                            </div>
                            <div className={styles.rh_card_dev_plan}>
                              <div className={styles.rh_card_dev_plan_count}>
                                {co.plans_count}
                              </div>
                              <div>Plans de dev.</div>
                            </div>
                          </div>
                        ))}
                    {progression.devPlan?.remaining > 0 && (
                      <div className={styles.remaining_counter}>
                        {`+ ${progression.devPlan?.remaining} Collaborateurs`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </RedirectCard>
          </div>
        </div>
        {/* Right */}
        <div className={styles.right_column}>
          {/* ----------- Capacity Production --------------  */}
          <RedirectCard
            title="Rapport annuel"
            to={`/${lang}/reporting?tab=TS_PROGRESSION`}
            width="100%"
            height="auto"
            borderColor="#F1F2F4"
          >
            <Carousel
              style={{
                width: "100%",
                padding: 0,
                marginBottom: 10,
                height: "auto",
              }}
              value={regionTabs}
              numVisible={2}
              numScroll={0}
              showNavigators={false}
              itemTemplate={(category, i) => {
                return (
                  <div
                    key={i}
                    className={`tab_badge ${
                      regionTab === category.value ? "tab_badge_active" : ""
                    }`}
                    style={{ minWidth: "50%", width: "auto", padding: "5px 0" }}
                    onClick={() =>
                      setFilter({ ...filter, regionTab: category.value })
                    }
                  >
                    <div style={{ textTransform: "capitalize" }}>
                      {_(category.label)}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.redirect_card_annual_report}>
              {productionProgression.isFetching ? (
                renderCPSkeleton()
              ) : productionProgression.isFetched &&
                visibleData &&
                visibleData.length ? (
                visibleData.map((co) => (
                  <div className={styles.redirect_card_annual_report_card}>
                    <div
                      className={
                        styles.redirect_card_annual_report_card_profile
                      }
                    >
                      <div
                        className={
                          styles.redirect_card_annual_report_card_profile_info
                        }
                      >
                        <div
                          className={
                            styles.redirect_card_annual_report_card_profile_info_avatar
                          }
                        >
                          <div
                            className={
                              styles.redirect_card_annual_report_card_profile_info_avatar_avatar
                            }
                          >
                            {renderAvatar(co.avatar, `${co.fname} ${co.lname}`)}
                            <div
                              className={styles.progress}
                              style={{
                                "--progress":
                                  (co.real.total * 100) / co.theoric,
                              }}
                            />
                          </div>
                          <div>
                            <strong>{`${co.fname} ${co.lname[0]}.`}</strong>
                            <em>{co.role}</em>
                          </div>
                        </div>
                        <div
                          className={
                            styles.redirect_card_annual_report_card_profile_info_diff
                          }
                        >
                          <div>
                            <span>Budg vs facturable</span>
                            <span>{`${
                              parseInt(co.budg_fact) > 0
                                ? parseInt(co.budg_fact)
                                : parseInt(co.budg_fact) * -1
                            } %`}</span>
                            {parseInt(co.budg_fact) > 0 ? (
                              <FaArrowCircleDown color="#FB1E5B" />
                            ) : (
                              <FaArrowCircleUp color="#06D9B1" />
                            )}
                          </div>
                          <div>
                            <span>Réalisé vs attendu</span>
                            <span>{`${
                              parseInt(co.real_theoric) > 0
                                ? parseInt(co.real_theoric)
                                : parseInt(co.real_theoric) * -1
                            } %`}</span>
                            {parseInt(co.real_theoric) > 0 ? (
                              <FaArrowCircleDown color="#FB1E5B" />
                            ) : (
                              <FaArrowCircleUp color="#06D9B1" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          styles.redirect_card_annual_report_card_profile_progress
                        }
                      >
                        <div
                          className={
                            styles.redirect_card_annual_report_card_profile_progress_details
                          }
                        >
                          <div>
                            <span>C.P:</span>
                            <span>{`${parseInt(
                              (co.real.total * 100) / co.theoric
                            )} %`}</span>
                          </div>
                          <div>
                            <span>V.A:</span>
                            <span>100</span>
                            <div className={styles.va_bar_container}>
                              <Tooltip
                                title={`${parseInt(co.real.wVA.percent)}%`}
                                placement="top"
                              >
                                <div
                                  style={{
                                    width: `${co.real.wVA.percent}%`,
                                    background: "black",
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                title={`${parseInt(co.real.fVA.percent)}%`}
                                placement="top"
                              >
                                <div
                                  style={{
                                    width: `${co.real.fVA.percent}%`,
                                    background: "#FB1E5B",
                                  }}
                                />
                              </Tooltip>

                              <Tooltip
                                title={`${parseInt(co.real.mVA.percent)}%`}
                                placement="top"
                              >
                                <div
                                  style={{
                                    width: `${co.real.mVA.percent}%`,
                                    background: "#FFAC3A",
                                  }}
                                />
                              </Tooltip>

                              <Tooltip
                                title={`${parseInt(co.real.hVA.percent)}%`}
                                placement="top"
                              >
                                <div
                                  style={{
                                    width: `${co.real.hVA.percent}%`,
                                    background: "#06D9B1",
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            styles.redirect_card_annual_report_card_profile_progress_arrow
                          }
                          onClick={() =>
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              expanded: co.id,
                            }))
                          }
                          style={{
                            transform:
                              expanded == co.id
                                ? "rotate(180deg)"
                                : "rotate(0)",
                          }}
                        >
                          <FaAngleDown width={16} height={16} color="#29394D" />
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.redirect_card_annual_report_details}
                      style={{
                        display: expanded == co.id ? "flex" : "none",
                        padding: expanded == co.id ? "20px 0" : 0,
                      }}
                    >
                      <div
                        className={
                          styles.redirect_card_annual_report_details_row
                        }
                      >
                        <div>
                          <span>{_("budgeted")}</span>
                          <span>{`${formatNumber(co.budgeted / 60)} h`}</span>
                        </div>
                        <div>
                          <span>{_("real")}</span>
                          <span>{`${formatNumber(co.real.total / 60)} h`}</span>
                        </div>
                        <div>
                          <span>{_("expected")}</span>
                          <span>{`${formatNumber(co.theoric / 60)} h`}</span>
                        </div>
                      </div>
                      <div className={styles.polygon} />
                      <div
                        className={
                          styles.redirect_card_annual_report_details_billable
                        }
                      >
                        <div>
                          <span>{`${formatNumber(
                            co.real.billable / 60
                          )} h`}</span>
                          <span>{_("billable")}</span>
                        </div>
                        <div>
                          <span>{`${formatNumber(
                            co.real.non_billable / 60
                          )} h`}</span>
                          <span>{_("nonBillable")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_data" style={{ height: 50 }}>
                  <div className="title">{_("noDataFound")}</div>
                </div>
              )}
            </div>

            {visibleData?.length < progression.cp[alias].length ? (
              <button
                className={styles.redirect_card_load_btn}
                onClick={handleLoadMore}
              >
                <span>{_("seeMore")}</span>
                <FaAngleDown width={20} height={20} />
              </button>
            ) : (
              <button
                className={styles.redirect_card_load_btn}
                onClick={handleLoadLess}
              >
                <span>{_("seeLess")}</span>
                <FaAngleUp width={20} height={20} />
              </button>
            )}
          </RedirectCard>

          {/* ---------- Hour Budget & Volumetry -----------  */}
          <RedirectCard
            title="Gestion des dossiers"
            to=""
            style={{
              marginTop: "20px",
              width: "100%",
              height: "auto",
              borderColor: "#F1F2F4",
            }}
          >
            <Carousel
              style={{
                width: "100%",
                padding: 0,
                marginBottom: 10,
                height: "auto",
              }}
              value={folderTabs}
              numVisible={2}
              numScroll={0}
              showNavigators={false}
              itemTemplate={(category, i) => {
                return (
                  <div
                    key={i}
                    className={`tab_badge ${
                      folderTab === category.value ? "tab_badge_active" : ""
                    }`}
                    style={{ minWidth: "50%", width: "auto", padding: "5px 0" }}
                    onClick={() =>
                      setFilter({ ...filter, folderTab: category.value })
                    }
                  >
                    <div style={{ textTransform: "capitalize" }}>
                      {_(category.label)}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.redirect_card_progression}>
              {scheduleProgression.isFetching
                ? renderSkeleton()
                : scheduleProgression.isFetched &&
                  progression[folderTab.toLowerCase()] && (
                    <div className={styles.redirect_card_progression_type}>
                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {folderTab == "SCHEDULE"
                          ? progression.schedule?.exceed?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div>{client.name}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span style={{ fontSize: 10 }}>{`${parseInt(
                                      client.hour_real
                                    )}h/${parseInt(client.hour_budget)}`}</span>
                                    <span
                                      style={{ width: "50px" }}
                                    >{`${formatNumber(
                                      parseInt(client.diff)
                                    )} %`}</span>
                                    <FaArrowCircleDown color="#FB1E5B" />
                                  </div>
                                </div>
                              )
                            )
                          : progression.volumetry?.exceed?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    {client.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span
                                      className={styles.volum_ecart}
                                      style={{
                                        backgroundColor: "#eb57571a",
                                        color: "#FB1E5B",
                                      }}
                                    >{`${parseInt(
                                      client.volY - client.volY_1
                                    )}`}</span>
                                    <span
                                      style={{ width: "50px" }}
                                    >{`${formatNumber(
                                      parseInt(client.diff)
                                    )} %`}</span>
                                    <FaArrowCircleDown color="#FB1E5B" />
                                  </div>
                                </div>
                              )
                            )}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {`+ ${
                            progression[folderTab.toLowerCase()]?.exceed
                              ?.rest_count
                          } en dépassement ${_(folderTab.toLowerCase())}`}
                        </div>
                      </div>

                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {folderTab == "SCHEDULE"
                          ? progression.schedule?.top?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span style={{ fontSize: 10 }}>{`${parseInt(
                                    client.hour_real
                                  )}h/${parseInt(client.hour_budget)}`}</span>
                                  <span
                                    style={{ width: "50px" }}
                                  >{`${formatNumber(
                                    parseInt(client.diff)
                                  )} %`}</span>
                                  <FaArrowCircleUp color="#06D9B1" />
                                </div>
                              </div>
                            ))
                          : progression.volumetry?.top?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    {client.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span
                                      className={styles.volum_ecart}
                                      style={{
                                        backgroundColor: "#02af8e1a",
                                        color: "#06D9B1",
                                      }}
                                    >{`${parseInt(
                                      client.volY - client.volY_1
                                    )}`}</span>
                                    <span
                                      style={{ width: "50px" }}
                                    >{`${formatNumber(
                                      parseInt(client.diff)
                                    )} %`}</span>
                                    <FaArrowCircleUp color="#06D9B1" />
                                  </div>
                                </div>
                              )
                            )}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {`+ ${
                            progression[folderTab.toLowerCase()]?.top
                              ?.rest_count
                          } ${
                            folderTab === "VOLUMETRY"
                              ? "Volumétrie respectée"
                              : `en top ${_(folderTab.toLowerCase())}`
                          }`}
                        </div>
                      </div>
                    </div>
                  )}
            </div>
          </RedirectCard>

          {/* --------------- CA, Profit & OUTSTANDING -----------------  */}
          <RedirectCard
            title="Financial"
            to={`/${lang}/financial-sheet`}
            style={{
              marginTop: "20px",
              width: "100%",
              height: "auto",
              borderColor: "#F1F2F4",
            }}
          >
            <Carousel
              style={{
                width: "100%",
                padding: 0,
                marginBottom: 10,
                height: "auto",
              }}
              value={financialTabs}
              numVisible={3}
              numScroll={0}
              showNavigators={false}
              itemTemplate={(category, i) => {
                return (
                  <div
                    key={i}
                    className={`tab_badge ${
                      financialTab === category.value ? "tab_badge_active" : ""
                    }`}
                    style={{ minWidth: "33.33%", width: "auto", padding: "5px 0" }}
                    onClick={() =>
                      setFilter({ ...filter, financialTab: category.value })
                    }
                  >
                    <div style={{ textTransform: "capitalize" }}>
                      {_(category.label)}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.redirect_card_progression}>
              {profitProgression.isFetching
                ? renderSkeleton()
                : profitProgression.isFetched &&
                  progression[financialTab.toLowerCase()] && (
                    <div className={styles.redirect_card_progression_type}>
                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {financialTab == "CA"
                          ? progression.ca?.exceed?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div>{client.name}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.diff) * -1
                                  )} €`}</span>
                                  <FaArrowCircleDown color="#FB1E5B" />
                                </div>
                              </div>
                            ))
                          : financialTab == "PROFIT"
                          ? progression.profit?.exceed?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    {client.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span>{`${formatNumber(
                                      parseInt(client.diff) * -1
                                    )} €`}</span>
                                    <FaArrowCircleDown color="#FB1E5B" />
                                  </div>
                                </div>
                              )
                            )
                          : progression.outstanding?.details &&
                            progression.outstanding.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.valueY)
                                  )} €`}</span>
                                </div>
                              </div>
                            ))}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {financialTab != "OUTSTANDING"
                            ? `+ ${
                                progression[financialTab.toLowerCase()]?.exceed
                                  ?.rest_count
                              } en échec ${_(financialTab.toLowerCase())}`
                            : null}
                        </div>
                      </div>

                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {financialTab == "CA"
                          ? progression.ca?.top?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.diff)
                                  )} €`}</span>
                                  <FaArrowCircleUp color="#06D9B1" />
                                </div>
                              </div>
                            ))
                          : financialTab == "PROFIT"
                          ? progression.profit?.top?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.diff)
                                  )} €`}</span>
                                  <FaArrowCircleUp color="#06D9B1" />
                                </div>
                              </div>
                            ))
                          : null}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {financialTab != "OUTSTANDING"
                            ? `+ ${
                                progression[financialTab.toLowerCase()]?.top
                                  ?.rest_count
                              } en top ${_(financialTab.toLowerCase())}`
                            : null}
                        </div>
                      </div>
                    </div>
                  )}
            </div>
          </RedirectCard>
        </div>
      </div>
    </div>
  );
}

// Skeleton Loaders
const renderSkeleton = () => {
  return (
    <div
      className={`${styles.redirect_card_progression_type} ${styles.skeleton}`}
    >
      <div className={styles.skeleton_child}>
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={`skeleton-exceed-${index}`}
            className={styles.skeleton_child_card}
          >
            <div className={styles.skeleton_text}></div>
            <div className={styles.skeleton_detail}>
              <div className={styles.skeleton_bar}></div>
              <div className={styles.skeleton_percentage}></div>
              <div className={styles.skeleton_icon}></div>
            </div>
          </div>
        ))}
        <div className={styles.skeleton_child_info}></div>
      </div>

      <div className={styles.skeleton_child}>
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={`skeleton-top-${index}`}
            className={styles.skeleton_child_card}
          >
            <div className={styles.skeleton_text}></div>
            <div className={styles.skeleton_detail}>
              <div className={styles.skeleton_bar}></div>
              <div className={styles.skeleton_percentage}></div>
              <div className={styles.skeleton_icon}></div>
            </div>
          </div>
        ))}
        <div className={styles.skeleton_child_info}></div>
      </div>
    </div>
  );
};

const renderCPSkeleton = () => {
  return (
    <div
      className={`${styles.redirect_card_annual_report_card} ${styles.skeleton}`}
    >
      <div className={styles.skeleton_profile}>
        <div className={styles.skeleton_avatar}></div>
        <div className={styles.skeleton_info}>
          <div className={styles.skeleton_name}></div>
          <div className={styles.skeleton_role}></div>
        </div>
      </div>
      <div className={styles.skeleton_progress}>
        <div className={styles.skeleton_progress_bar}></div>
        <div className={styles.skeleton_progress_arrow}></div>
      </div>
      <div
        className={`${styles.redirect_card_annual_report_details} ${styles.skeleton_details}`}
      >
        <div className={styles.skeleton_detail_row}>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
        </div>
        <div className={styles.skeleton_polygon}></div>
        <div className={styles.skeleton_billable}>
          <div className={styles.skeleton_billable_item}></div>
          <div className={styles.skeleton_billable_item}></div>
        </div>
      </div>
      <br />
      <div className={styles.skeleton_profile}>
        <div className={styles.skeleton_avatar}></div>
        <div className={styles.skeleton_info}>
          <div className={styles.skeleton_name}></div>
          <div className={styles.skeleton_role}></div>
        </div>
      </div>
      <div className={styles.skeleton_progress}>
        <div className={styles.skeleton_progress_bar}></div>
        <div className={styles.skeleton_progress_arrow}></div>
      </div>
      <div
        className={`${styles.redirect_card_annual_report_details} ${styles.skeleton_details}`}
      >
        <div className={styles.skeleton_detail_row}>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
        </div>
        <div className={styles.skeleton_polygon}></div>
        <div className={styles.skeleton_billable}>
          <div className={styles.skeleton_billable_item}></div>
          <div className={styles.skeleton_billable_item}></div>
        </div>
      </div>
    </div>
  );
};
