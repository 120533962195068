import { React, useState, useEffect } from "react";
import styles from "./Budget.module.scss";
import classNames from "classnames";
import _ from "../../i18n";
import moment from "moment";

// import DatePicker from "react-datepicker"
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import { CalendarIcon } from "../common/Icons";
import { Calendar } from "primereact/calendar";
import { Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
const Settings = ({
  preferences,
  dataSettings,
  startCategories,
  setStartCategories,
  departCategories,
  setDepartCategories,
  selectedStartDates,
  setSelectedStartDates,
  selectedDepartDates,
  setSelectedDepartDates,

  setStartDatesActivated,
  departActivated,
  startDatesActivated,
  atRiskDatesActivated,
  setAtRiskDatesActivated,
  departDatesActivated,
  setDepartDatesActivated,
  startDatesFacturation,
  setStartDatesFacturation,
  startDatesTask,
  setStartDatesTask,
  departDatesFacturation,
  setDepartDatesFacturation,
  departDatesTask,
  setDepartDatesTask,
  preferentialTariff,
  setPreferentialTariff,
  setUpdated,
  setNormalVoyant,
  setMediumVoyantFrom,
  setMediumVoyantTo,
  setHighVoyant,
  normalVoyant,
  mediumVoyantFrom,
  mediumVoyantTo,
  highVoyant,
  communicatedDeparture,
  setCommunicatedDeparture,
  leavingRiskObservation,
  setLeavingRiskObservation
}) => {
  const [selectedDate, setSelectedDate] = useState(
    dataSettings.start_dates.start_invoice
  );

  const [active, setActive] = useState("");

  const handleSettingTypeChange = (activeItem) => {
    if (active === activeItem) {
      setActive("");
    } else {
      setActive(activeItem);
    }
  };

  const handleChangeValues = (rowIndex, column, list) => {
    if (list === "start") {
      setStartCategories((prevStartCategories) => {
        return prevStartCategories.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              [column]: !item.checked,
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setDepartCategories((prevDepartCategories) => {
        return prevDepartCategories.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              [column]: !item.checked,
            };
          } else {
            return item;
          }
        });
      });
    }
    setUpdated(true);
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const renderActiveSettingContent = () => {
    switch(active) {
      case 'NewClient': return (
        <>
          <div className={styles.settings}>
            <div className={styles.title_settings}>
              Nouveau client
            </div>
            <div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Début de facturation
                </div>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={moment(startDatesFacturation, "DD-MM-YYYY").toDate()}
                  onChange={(e) => {
                    setStartDatesFacturation(
                      moment(e.value).format("DD-MM-YYYY")
                    );
                  }}
                />
                <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />{" "}
              </div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Début de prestation
                </div>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={moment(
                    startDatesTask, "DD-MM-YYYY"
                  ).toDate()}
                  onChange={(e) => {
                    setStartDatesTask(moment(e.value).format("DD-MM-YYYY"));
                  }}
                />
                <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />{" "}
              </div>
            </div>
          </div>
          <div className={styles.categories_list}>
            <div className={styles.categories}>
              categories
              <div>
                {startCategories.map((item, i) => {
                    return (
                      <div className={styles.category_item}>
                        <span>{item.label}</span>
                        <div className={styles.switch}>
                          <Switch
                            isChecked={item.checked}
                            onChange={() =>
                              handleChangeValues(i, "checked", "start")
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      )
      case 'AtRisk': return (
        <>
          <div className={styles.settings} style={{ height: '100%', width: '100%' }}>
            <div className={styles.title_settings}>
              Client à risque
            </div>
            <div>
              <div className={styles.date_title}>
                Remarque
              </div>
              <TextArea
                style={{ width: '500px' }}
                rows={7}
                value={leavingRiskObservation}
                onChange={(e) => setLeavingRiskObservation(e.target.value)}
              />
            </div>
          </div>
        </>
      )
      case 'Depart': return (
        <>
          <div className={styles.settings}>
            <div className={styles.title_settings}>
              Depart client
            </div>
            <div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Fin de facturation
                </div>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={moment(
                    departDatesFacturation,
                    "DD-MM-YYYY"
                  ).toDate()}
                  onChange={(e) => {
                    setDepartDatesFacturation(
                      moment(e.value).format("DD-MM-YYYY")
                    );
                  }}
                />
                <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />{" "}
              </div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Fin de prestation
                </div>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={moment(
                    departDatesTask, "DD-MM-YYYY"
                  ).toDate()}
                  onChange={(e) => {
                    setDepartDatesTask(moment(e.value).format("DD-MM-YYYY"));
                  }}
                />
                <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />{" "}
              </div>
              <div className={styles.calendar_content}>
                <div className={styles.date_title}>
                  Date de depart
                </div>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={moment( communicatedDeparture, "DD-MM-YYYY" ).toDate()}
                  onChange={(e) => {
                    if (active === "Depart") {
                      setCommunicatedDeparture(moment(e.value).format("DD-MM-YYYY"));
                    }
                  }}
                />
                <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />
              </div>
            </div>
          </div>
          <div className={styles.categories_list}>
            <div className={styles.categories}>
              categories
              <div>
                {
                  departCategories.map((item, i) => {
                    return (
                      <div className={styles.category_item}>
                        <span>{item.label}</span>
                        <div className={styles.switch}>
                          <Switch
                            isChecked={item.checked}
                            onChange={() =>
                              handleChangeValues(i, "checked", "depart")
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </>
      )
      default: return null
    }
  }

  return (
    <div>
      <div className={styles.title}>Paramètre client</div>
      <div className="grid-x">
        <div className="cell small-4 medium-4 large-3">
          <div
            className={classNames(
              styles.group_info,
              active === "NewClient" && styles.selected_info
            )}
          >
            {(preferences.allowUpdatingNewClients==="1") &&
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Nouveau client :</span>
                  <div>
                    <Switch
                      checked={active === 'NewClient'}
                      onChange={() => {
                        handleSettingTypeChange("NewClient");
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          {(preferences.allowUpdatingDepartClients==="1") &&
            <div
              className={classNames(
                styles.group_info,
                active === "AtRisk" && styles.selected_info
              )}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Client à risk :</span>
                  <div>
                    <Switch
                      checked={active === 'AtRisk'}
                      onChange={() => {
                        handleSettingTypeChange("AtRisk")
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {(preferences.allowUpdatingDepartClients==="1") &&
            <div
              className={classNames(
                styles.group_info,
                active === "Depart" && styles.selected_info
              )}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Départ client :</span>
                  <div>
                    <Switch
                      checked={active === 'Depart'}
                      onChange={() => {
                        handleSettingTypeChange("Depart")
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
           {(preferences.allowUpdatingTarifClients==="1") &&
          <div className={styles.info}>
            <div className={styles.info_content}>
              <span>Tarif préférentiel/ friendzone :</span>
              <div>
                <Switch
                  checked={active === 'PreferentialTariff'}
                  onChange={() => {
                    handleSettingTypeChange("PreferentialTariff")
                  }}
                />
              </div>
            </div>
          </div>}
          <div className={styles.info}>
            <div className={styles.info_content}>
              <span>Personne lié :</span>
              {dataSettings.attached.length > 0 &&
                dataSettings.attached.map((item, i) => {
                  return <div>{item.name}</div>;
                })}
            </div>
          </div>
          {(preferences.allowUpdatingBillingView==="1") &&
            <div className={styles.info} style={{ height: "auto" }}>
              <div className={styles.info_content}>
                <span>Voyant de facturation :</span>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#06D9B1" }}
                  ></span>
                  <div className={styles.voyant}>
                    {"< "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={normalVoyant}
                      onChange={(event) => setNormalVoyant(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#EA8124" }}
                  ></span>{" "}
                  <div className={styles.voyant}>
                    {"de "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={mediumVoyantFrom}
                      onChange={(event) => setMediumVoyantFrom(event.target.value)}
                    />
                    {" %"}
                  </div>
                  <div className={styles.voyant}>
                    {"a "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={mediumVoyantTo}
                      onChange={(event) => setMediumVoyantTo(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#EB5757" }}
                  ></span>{" "}
                  <div className={styles.voyant}>
                    {" > "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={highVoyant}
                      onChange={(event) => setHighVoyant(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {active !== "" && (
          <div
            className={classNames("cell small-4 medium-4 large-9")}
            style={{}}
          >
            <div
              className={styles.line}
              style={{
                display: "flex",
                height: '100%'
              }}
            >
              {renderActiveSettingContent()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
