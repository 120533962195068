import PageHeader from "../common/PageHeader/PageHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
import { TABS } from "./constants";
import _ from "../../i18n";
import { useState } from "react";
import styles from './index.module.scss'
import { ChartIcon } from "../common/Icons";
import CroissanceClientsInPage from "./ClientsIn";
import CroissanceClientsOutPage from "./ClientsOut";
import CroissanceReviewPage from "./Review";
import CroissanceClientsInRiskPage from "./ClientsInRisk";


export default function CroissancePage() {
  const [filter, setFilter] = useState({
    tab: TABS[0].id,
    subTitle: TABS[0].subTitle
  });

  const renderSelectedTabContent = () => {
    switch(filter.tab) {
      case "CLIENTS_OUT": return <CroissanceClientsOutPage />
      case "CLIENTS_IN_RISK": return <CroissanceClientsInRiskPage />
      case "REVIEW": return <CroissanceReviewPage />
      default: return <CroissanceClientsInPage />
    }
  }

  return (
    <div className={styles.page_container}>
      <PageHeader
        header={_("clientsCroissance")}
        subHeader={_(`${filter.subTitle}`)}
        icon={<ChartIcon stroke="#29394d" size="36" />}
      />

      <div className={styles.tab_container}>
        <TTPTab2
          tabs={TABS}
          activeTab={filter.tab}
          width="1120px"
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id, subTitle: item.subTitle });
          }}
        />
      </div>

      {renderSelectedTabContent()}
    </div>
  )
}
