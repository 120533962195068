import _ from "../i18n";
import { BellIcon, FlagIcon, CalendarIcon } from "../components/common/Icons";
import FileWarningIcon from "../components/common/Icons/FileWarningIcon";

export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const TTP_API_URL = process.env.REACT_APP_TTP_API_URL;
export const TTP_HUB_URL = process.env.REACT_APP_TTP_HUB_URL;
export const TTP_POWERTEAM_URL = process.env.REACT_APP_TTP_POWERTEAM_URL;
export const TTP_TEAM_URL = process.env.REACT_APP_TTP_TEAM_URL;
export const TTP_MEDIA_URL = process.env.REACT_APP_TTP_MEDIA_URL;
export const TTP_PORTAL_URL = process.env.REACT_APP_TTP_PORTAL_URL;
export const TTP_BLOG_URL = process.env.REACT_APP_TTP_BLOG_URL;
export const TTP_FORUM_URL = process.env.REACT_APP_TTP_FORUM_URL;
export const TTP_WEBTOOL_URL = process.env.REACT_APP_TTP_WEBTOOL_URL;
export const TTP_EVENT_URL = process.env.REACT_APP_TTP_EVENT_URL;
export const TTP_EMAILING_URL = process.env.REACT_APP_TTP_EMAILING_URL;
export const TTP_HOME_URL = process.env.REACT_APP_TTP_HOME_URL;
export const TTP_FFF_BLOG = process.env.REACT_APP_TTP_FFF_BLOG;
export const TTP_DEG_BLOG = process.env.REACT_APP_TTP_DEG_BLOG;
export const TTP_BE_ACCOUNTANTS_BLOG =
  process.env.REACT_APP_TTP_BE_ACCOUNTANTS_BLOG;
export const TTP_TALK_URL = process.env.REACT_APP_TTP_TALK_URL;
export const TTP_EBOX_URL = process.env.REACT_APP_TTP_EBOX_URL;
export const TTP_DASHBOARD_URL = process.env.REACT_APP_TTP_DASHBOARD_URL;
export const TTP_SEO_URL = process.env.REACT_APP_TTP_SEO_URL;
export const TTP_UA_URL = process.env.REACT_APP_TTP_UA_URL;

export const S3_FOLDER_URL = `https://s3.tamtam.pro/v2`;

export const AUTH_COOKIE_NAME = `ttp_auth_${APP_ENV}`;

export const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const URL_HASH_KEY = "Y3YZVA6uSLVqJhAWHdsb9CsGgcBXIhmY";

export const CLIENT_CREDENTIAL = {
  grant_type: "client_credentials",
  client_id: "11111",
  client_secret: "PublicSecret",
  scope: "public",
};

export const SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
    minHeight: "34px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    textTransform: "uppercase",
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
  indicatorSeparator: (provided) => ({ display: "none" }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px",
  }),
};

export const LIST_PAGE_SIZES = [5, 10, 25, 50, 100];

export const COLLABORATOR_ROLES = {
  ALL: _("all"),
  SUPERVISOR: "SUPERVISOR",
  GD: "GD",
  GE: "GE",
  SCAN_OP: "SCAN_OP",
  SE: "SE",
  DELEGATED: "DELEGATED",
};

export const COLLABORATOR_ROLE_FILTER = [
  {
    value: "",
    label: _(COLLABORATOR_ROLES.ALL),
  },
  {
    value: COLLABORATOR_ROLES.SUPERVISOR,
    label: _(COLLABORATOR_ROLES.SUPERVISOR),
  },
  {
    value: COLLABORATOR_ROLES.GD,
    label: _(COLLABORATOR_ROLES.GD),
  },
  {
    value: COLLABORATOR_ROLES.GE,
    label: _(COLLABORATOR_ROLES.GE),
  },
  {
    value: COLLABORATOR_ROLES.SCAN_OP,
    label: _(COLLABORATOR_ROLES.SCAN_OP),
  },
  {
    value: COLLABORATOR_ROLES.SE,
    label: COLLABORATOR_ROLES.SE,
  },
  {
    value: COLLABORATOR_ROLES.DELEGATED,
    label: COLLABORATOR_ROLES.DELEGATED,
  },
];

export const IS_ENTITY_STATES = [
  { value: "1", label: _("entiteType"), color: "#62DEB0" },
  { value: "0", label: _("attache"), color: "#FFB06A" },
];

export const COLLABORATOR_ZONES = [
  { value: "Maroc", label: _("morocco") },
  { value: "Belgique", label: _("belgium") },
];

export const COLLABORATOR_STATES = [
  { value: "ACTIVE", label: _("Active"), color: "#62DEB0" },
  { value: "NOT_CONFIGURED", label: _("Not configured"), color: "#FFB06A" },
  { value: "NOT_ACTIVE", label: _("Not active (left)"), color: "#e8eaed" },
];

export const BOARD_SECTIONS = {
  TODO: "TODO",
  WIP: "WIP",
  VALIDATION: "VALIDATION",
  DONE: "DONE",
};

export const PRIORITY_LEVELS = [
  { label: "URGENT", value: "URGENT" },
  { label: "MEDIUM", value: "MEDIUM" },
  { label: "LOW", value: "LOW" },
];
export const CARD_TYPE = [
  { label: _("cor_card"), value: "cor_card" },
  { label: _("dev_card"), value: "dev_card" },
];

export const DELETED_STATUS = [
  {
    value: "DELETED",
    label: _("deleted"),
  },
  {
    value: "NOT_DELETED",
    label: _("persistent"),
  },
];

export const COLLABORATOR_ATTRIBUTION_FILTER = [
  {
    value: "ALL_FOLDERS",
    label: _("allFiles"),
  },
  {
    value: "BUDGETED_FOLDERS",
    label: _("budgetedFolders"),
  },
  {
    value: "HANDLED_FOLDERS",
    label: _("handledFolders"),
  },
];

export const FINANCIAL_COLLABORATOR_SORT_TYPES = [
  {
    value: "VOLUMES_CLIENTS_DESC",
    label: _("Clients les plus gros (temps)"),
  },
  {
    value: "VOLUMES_CLIENTS_ASC",
    label: _("Clients les plus petits (temps)"),
  },
  {
    value: "PROFITABLE_CLIENTS_DESC",
    label: _("Clients les plus rentables"),
  },
  {
    value: "PROFITABLE_CLIENTS_ASC",
    label: _("Clients les moins rentables"),
  },
];

export const CLIENT_CRM_TYPE_FILTER = [
  {
    value: "PROSPECT",
    label: _("Prospect"),
  },
  {
    value: "CLIENT",
    label: _("client"),
  },
];

export const BUDGET_STATUS_FILTER = [
  {
    value: "COMPLET",
    label: _("complet"),
  },
  {
    value: "TASK_MANAGEMENT",
    label: _("taskManagement"),
  },
];

export const COLLABORATOR_ROLE_ATTRIBUT = [
  {
    value: "",
    label: _(COLLABORATOR_ROLES.ALL),
  },
  {
    value: "supervisor",
    label: _(COLLABORATOR_ROLES.SUPERVISOR),
  },
  {
    value: "manager",
    label: _(COLLABORATOR_ROLES.GD),
  },
  {
    value: "encodingManager",
    label: _(COLLABORATOR_ROLES.GE),
  },
  {
    value: "scanOperator",
    label: _(COLLABORATOR_ROLES.SCAN_OP),
  },
  {
    value: "encodingSupervisor",
    label: COLLABORATOR_ROLES.SE,
  },
];

export const CLIENT_GROUP_CRM_FILTER = [
  {
    value: "AiSBL",
    label: _("AiSBL"),
  },
  {
    value: "ASBL",
    label: _("ASBL"),
  },
  {
    value: "ASBL - Consultation",
    label: _("ASBL - Consultation"),
  },
  {
    value: "ASBL - Partenaire",
    label: _("ASBL - Partenaire"),
  },
  {
    value: "ASBL petite entreprise",
    label: _("ASBL petite entreprise"),
  },
  {
    value: "Contact",
    label: _("Contact"),
  },
  {
    value: "Fondation privée",
    label: _("Fondation privée"),
  },
  {
    value: "Gérant petite entreprise",
    label: _("Gérant petite entreprise"),
  },
  {
    value: "Gérants",
    label: _("Gérants"),
  },
  {
    value: "Indépendants",
    label: _("Indépendants"),
  },
  {
    value: "Indépendants - Consultation",
    label: _("Indépendants - Consultation"),
  },
  {
    value: "Indépendants petite entreprise",
    label: _("Indépendants petite entreprise"),
  },
  {
    value: "Partenaire",
    label: _("Partenaire"),
  },
  {
    value: "Particuliers",
    label: _("Particuliers"),
  },
  {
    value: "Particuliers - Consultation",
    label: _("Particuliers - Consultation"),
  },
  {
    value: "Sociétés",
    label: _("Sociétés"),
  },
  {
    value: "Sociétés - Partenaire",
    label: _("Sociétés - Partenaire"),
  },
  {
    value: "Sociétés petite entreprise",
    label: _("Sociétés petite entreprise"),
  },
  {
    value: "Test",
    label: _("Test"),
  },
];

export const BUDGET_STATUS = [
  {
    value: "BUDGET_COMPLETED",
    label: " Budgétisation complete",
  },
  {
    value: "UNAUTHORIZED_BUDGET",
    label: "Budget non autorisé",
  },
  {
    value: "BUDGET_GLOBAL_COMPLETED",
    label: "Budget global complete",
  },
  {
    value: "GLOBAL_BUDGET_NOT_AFFECTED",
    label: "Budget global non affecté",
  },
];

export const DEPART_STATUS = [
  {
    value: "CLIENT_LEAVING",
    label: "Client en partance",
  },
  {
    value: "CLIENT_RISKY",
    label: "Client à risk",
  },
];

export const SORT_TYPES = [
  {
    value: "REVENUE_DESC",
    label: _("Clients les plus gros (Chiffre d'affaire)"),
  },
  {
    value: "REVENUE_ASC",
    label: _("Clients les plus petits (Chiffre d'affaire)"),
  },
  {
    value: "PROFITABLE_CLIENTS_DESC",
    label: _("Clients les plus rentables"),
  },
  {
    value: "PROFITABLE_CLIENTS_ASC",
    label: _("Clients les moins rentables"),
  },
  {
    value: "OVER_VOLUMES_CLIENTS_DESC",
    label: _("Clients les plus gros (temps) dépassé"),
  },
  {
    value: "OVER_VOLUMES_CLIENTS_ASC",
    label: _("Clients les plus petits (temps) dépassé"),
  },
];

export const PERIOD_INTERVALS = [
  { label: "today", value: "today", daysToSub: 0 },
  { label: "yesterday", value: "yesterday", daysToSub: 1 },
  { label: "otherday", value: "otherday", daysToSub: 2 },
  { label: "week", value: "week", daysToSub: null },
];

export const NOTIFICATION_STATUS = [
  { label: "ALL", value: null },
  { label: "PENDING", value: "PENDING" },
  { label: "SOLVED", value: "RESOLVED" },
];

export const NOTIFICATION_TABS = [
  {
    tab: "allNotifications",
    icon: <BellIcon size={15} fill="#6D7F92" className="fill" />,
  },
  {
    redirect: "/:language/plans-board",
    tab: "card",
    actions: [
      "correction_card_affected",
      "correction_card_removed",
      "correction_card_returned",
      "correction_card_validated",
      "correction_card_request_validat"
    ],
    icon: <FlagIcon size={14} fill="#6D7F92" className="stroke" />,
  },
  {
    redirect: "/:language/error-reports",
    tab: "errorReports",
    actions: [
      "error_report_affected"
    ],
    icon: <FileWarningIcon size={14} fill="#6D7F92" className="stroke" />,
  },
  {
    redirect: "/:language/fiche-collaborator/:id?tab=CALENDAR",
    tab: "calendar",
    actions: [
      "certificat_required",
      "absence_rejected",
      "absence_approved",
      "absence_request",
      "absence_affected",
      "absence_backup",
      "absence_sick",
    ],
    icon: <CalendarIcon size={14} fill="#6D7F92" className="fill" />,
  },
];

export const ECHEANCES = {
  IPM: [null, "26-04", "30-06", "31-03", "31-10", "31-12"],
  ISOC: [
    null,
    "30-04",
    "30-06",
    "30-09",
    "31-01",
    "31-03",
    "31-07",
    "31-08",
    "31-12",
  ],
  TVA: [null, "20-04", "20-06", "20-10", "20-01"],
};

export const MICROSOFT_GRAPH_API_CLIENT_ID =
  process.env.REACT_APP_MICROSOFT_GRAPH_API_CLIENT_ID;
export const MICROSOFT_GRAPH_API_TENANT_ID =
  process.env.REACT_APP_MICROSOFT_GRAPH_API_TENANT_ID;
export const MICROSOFT_GRAPH_API_REDIRECT_URI =
  process.env.REACT_APP_MICROSOFT_GRAPH_API_REDIRECT_URI;

export const BUDGET_STATE_CONFIG = [
  {
    id: "all",
    label: _("all"),
    color: "#3c4e64",
  },
  {
    id: "unbudgeted",
    label: _("unbudgeted"),
    color: "#FE3745",
  },
  {
    id: "inProgress",
    label: _("inProgress"),
    color: "#18A0FB",
  },
  {
    id: "budgeted",
    label: _("budgetedState"),
    color: "#06D9B1",
  },
];

export const PAGES = {
  HOME_PAGE: "HOME_PAGE",
  COMMUNITIES_PAGE: "COMMUNITIES_PAGE",
  SETTING_PAGE: "SETTING_PAGE",
  TIME_SHEET_PAGE: "TIME_SHEET_PAGE",
  FINANCIAL_SHEET_PAGE: "FINANCIAL_SHEET_PAGE",
};

export const DYNAMIC_TABLE_SORT_TYPES = [
  {
    value: "REVENUE_DESC",
    label: _("Clients les plus gros (Chiffre d'affaire)"),
  },
  {
    value: "REVENUE_ASC",
    label: _("Clients les plus petits (Chiffre d'affaire)"),
  },
  {
    value: "VOLUMES_CLIENTS_DESC",
    label: _("Clients les plus gros (temps)"),
  },
  {
    value: "VOLUMES_CLIENTS_ASC",
    label: _("Clients les plus petits (temps)"),
  },
  {
    value: "PROFITABLE_CLIENTS_DESC",
    label: _("Clients les plus rentables"),
  },
  {
    value: "PROFITABLE_CLIENTS_ASC",
    label: _("Clients les moins rentables"),
  },
];

export const OFFSHORE_STATUS = [
  {
    value: "OFFSHORE",
    label: _("Offshore"),
  },
  {
    value: "NOT_OFFSHORE",
    label: _("Non Offshore"),
  },
];
export const NEW_COLLABORATOR_ROLE_FILTER = [
  {
    value: "",
    label: _(COLLABORATOR_ROLES.ALL),
  },
  {
    value: COLLABORATOR_ROLES.SUPERVISOR,
    label: _(COLLABORATOR_ROLES.SUPERVISOR),
  },
  {
    value: COLLABORATOR_ROLES.GD,
    label: _(COLLABORATOR_ROLES.GD),
  },
  {
    value: COLLABORATOR_ROLES.GE,
    label: _(COLLABORATOR_ROLES.GE),
  },
  {
    value: COLLABORATOR_ROLES.SCAN_OP,
    label: _(COLLABORATOR_ROLES.SCAN_OP),
  },
  {
    value: COLLABORATOR_ROLES.SE,
    label: COLLABORATOR_ROLES.SE,
  },
  {
    value: COLLABORATOR_ROLES.DELEGATED,
    label: COLLABORATOR_ROLES.DELEGATED,
  },
];

export const CLIENT_FACTURABLE_STATUS_FILTER = [
  {
    value: "NON_FACTURABLE",
    label: _("Éliminer non facturable"),
  },
  {
    value: "NON_FACTURABLE_MAROC",
    label: _("Éliminer non facturable maroc"),
  },
  {
    value: "NON_FACTURABLE_BELGIQUE",
    label: _("Éliminer non facturable belgique"),
  },
];
