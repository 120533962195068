export default function DangerTriangleIcon({ size = 14, fillColor = '#000000'}) {
  return (
    <svg
      fill={fillColor}
      height={size}
      width={size}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 501.481 501.481">
      <g>
        <g>
          <path d="M498.281,468.34L270.014,11.807c-4.267-7.467-10.667-11.733-19.2-11.733s-16,4.267-19.2,11.733L2.281,471.541
            c-3.2,6.4-3.2,13.867,1.067,20.267c4.267,5.333,10.667,9.6,18.133,9.6h458.667c11.733,0,21.333-9.6,21.333-21.333
            C501.48,475.807,500.414,471.541,498.281,468.34z M54.547,459.807L250.814,67.274l195.2,392.533H54.547z"/>
        </g>
      </g>
    <g>
      <g>
        <path d="M295.614,315.807c-2.133-6.4-7.467-10.667-12.8-12.8l-26.667-9.6l27.733-49.067c5.333-10.667,1.067-23.467-8.533-28.8
          c-10.667-5.333-23.467-1.066-28.8,8.534l-39.467,71.467c-3.2,5.333-3.2,11.733-1.067,17.067c2.133,5.333,7.467,9.6,12.8,11.733
          l26.667,8.533l-38.4,67.2c-6.4,10.667-2.133,23.467,7.467,28.8c3.2,2.133,7.467,3.2,10.667,3.2
          c7.467,0,14.933-4.267,18.133-10.667l51.2-88.533C297.747,327.541,297.747,321.141,295.614,315.807z"/>
      </g>
    </g>
  </svg>
  )
}
