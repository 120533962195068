import axios from "axios";
import { TTP_API_URL } from "../../config";

const apiUrl = `${TTP_API_URL}/team/client/recovr`;

export const syncDebtors = (token, organizationId) => {
  const requestUrl = `${apiUrl}/debtors`;

  const params = {
    access_token: token,
    organizationId: organizationId
  };

  return axios.get(requestUrl, { params });
};

export const syncInvoices = (token, organizationId) => {
  const requestUrl = `${apiUrl}/invoices`;

  const params = {
    access_token: token,
    organizationId: organizationId
  };

  return axios.get(requestUrl, { params });
};

export const getDebtorInvoices = (token, organizationId, clientId, debtorId) => {
  const requestUrl = `${apiUrl}/debtor/invoices`;

  const params = {
    access_token: token,
    organizationId: organizationId,
    clientId: clientId,
    debtorId: debtorId,
  };

  return axios.get(requestUrl, { params });
};

export const updateDebtorManager = (token, organizationId, id, email) => {
  const requestUrl = `${apiUrl}/debtor/manager`;

  const params = {
    access_token: token,
    organizationId: organizationId,
    debtorId: id,
  };

  const formData = new FormData();
  formData.append("email", email);

  return axios.post(requestUrl, formData, { params });
};
