import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { TiArrowSortedDown } from "react-icons/ti";

import PageHeader from "../common/PageHeader";
import NotAuthorized from "../common/NotAuthorized";

import {
  getOrganisationPeriodSetting,
  runAdminIsInvoiceSynchronisation,
  runAdminIsTaskSynchronisation,
  runAdminIsTvaBalancesSynchronisation,
  runAdminIsBalancesSynchronisation,
  runAdminIsClientRolesSynchronisation,
  runAdminIsClientEntitiesSynchronisation,
  syncDebtors,
  syncInvoices,
} from "../../api";

import { renderTeamYears } from "../../utils";
import styles from "./Synchronisation.module.scss";
import classNames from "classnames";
import _ from "../../i18n";
import AdminISLogo from "./assets/admin-is-logo.jpg";
import RecovrLogo from "./assets/recovr-logo.svg";

const intergrations = [
  {
    id: "#admin-is",
    name: "Syneton",
    src: AdminISLogo,
  },
  {
    id: "#recovr",
    name: "Recovr",
    src: RecovrLogo,
  },
];

const Synchronisation = () => {
  const ref = useRef();
  const auth = useSelector((state) => state.auth);

  const [selected, setSelected] = useState("#admin-is");

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
    month: null,
  });
  const [typeId, setTypeId] = useState(1);
  const [synchronisations, setSynchronisations] = useState({
    taskSync: false,
    invoiceSync: false,
    tvaSync: false,
    ipmSync: false,
    rolesSync: false,
    entitiesSync: false,
    recovrIDSync: false,
    recovrInvoiceSync: false,
  });
  const synchronisationOptions = [
    { id: 1, label: "TIME_SHEET" },
    { id: 2, label: "FACTURE" },
    { id: 3, label: "BALANCE ISOC" },
    { id: 4, label: "BALANCE IPM" },
    { id: 5, label: "RIC DECLARATION TVA" },
    { id: 6, label: "ROLES DES CLIENTS" },
    { id: 7, label: "RELATION ENTITÉ CLIENT" },
  ];

  const RECOVR_SynchronisationOptions = [
    {
      id: 1,
      label: "Identification des dossiers",
      highlightText:
        "ID Recovr, lé référence externe et le portail de paiement",
      description: "Synchroniser l'identification des dossiers dans Recovr",
    },
    {
      id: 2,
      label: "FACTURE",
      highlightText: "Paiements et Encours",
      description: "Synchroniser toutes les factures existantes dans Recovr",
    },
  ];

  const monthsOptions = Array.from({ length: 12 }, (_, i) => ({
    id: `m${i + 1}`,
    label: new Date(0, i)
      .toLocaleString("fr", { month: "long" })
      .replace(/^\w/, (c) => c.toUpperCase()),
  }));

  const handleMonthChange = (option) => {
    setFilter({ ...filter, month: option });
  };

  const fetchPeriodSettings = async () => {
    if (auth.token && auth.navCommunity) {
      try {
        const response = await getOrganisationPeriodSetting(
          auth.navCommunity.id,
          filter.year,
          auth.token
        );
        const data = response.data.data;
        setSynchronisations({
          taskSync: data?.isAdminIsTaskSynchronisationRuning,
          invoiceSync: data?.isAdminIsInvoiceSynchronisationRuning,
          tvaSync: data?.isAdminIsTvaBalancesSynchronisationRuning,
          ipmSync: data?.isAdminIsIPMSynchronisationRuning,
          isocSync: data?.isAdminIsISOCSynchronisationRuning,
          rolesSync: data?.isAdminIsClientRolesSynchronisationRuning,
          entitiesSync: data?.isAdminIsClientEntitiesSynchronisationRuning,
          recovrIDSync: data?.isRecovrIDSynchronisationRuning,
          recovrInvoiceSync: data?.isRecovrInvoiceSynchronisationRuning,
        });
      } catch (e) {
        toast.error(_("error_loading_data"));
      }
    }
  };

  useEffect(() => {
    fetchPeriodSettings();
    const intervalId = setInterval(fetchPeriodSettings, 20000);
    return () => clearInterval(intervalId);
  }, [auth.token, auth.navCommunity, filter.year]);

  const handleRunSynchronisation = async () => {
    const payload = {
      token: auth.token,
      organization: auth.navCommunity?.id,
      year: filter.year,
      type: typeId === 3 ? "ISOC" : typeId === 4 ? "IPM" : null,
      ...(filter.month?.id && { period: filter.month.id }),
    };

    try {
      if (typeId === 1) {
        setSynchronisations((prevState) => ({ ...prevState, taskSync: true }));
        await runAdminIsTaskSynchronisation(payload);
      } else if (typeId === 2) {
        setSynchronisations((prevState) => ({
          ...prevState,
          invoiceSync: true,
        }));
        await runAdminIsInvoiceSynchronisation(payload);
      } else if (typeId === 3 || typeId === 4) {
        if (typeId === 3) {
          setSynchronisations((prevState) => ({
            ...prevState,
            isocSync: true,
          }));
        } else {
          setSynchronisations((prevState) => ({
            ...prevState,
            ipmSync: true,
          }));
        }
        await runAdminIsBalancesSynchronisation(payload);
      } else if (typeId === 5) {
        setSynchronisations((prevState) => ({ ...prevState, tvaSync: true }));
        await runAdminIsTvaBalancesSynchronisation(payload);
      } else if (typeId === 6) {
        setSynchronisations((prevState) => ({
          ...prevState,
          rolesSync: true,
        }));
        await runAdminIsClientRolesSynchronisation(payload);
      } else if (typeId === 7) {
        setSynchronisations((prevState) => ({
          ...prevState,
          entitiesSync: true,
        }));
        await runAdminIsClientEntitiesSynchronisation(payload);
      }
      toast.success(_("successfully_added"));
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.[0]?.message || _("error");
      toast.error(errorMessage);
    }
  };

  const handleRunRecovrSynchronisation = async () => {
    const { token, navCommunity } = auth;
    if (typeId === 1) {
      try {
        setSynchronisations((prevState) => ({
          ...prevState,
          recovrIDSync: true,
        }));  
        await syncDebtors(token, navCommunity.id);
        toast.success(_("successfully_added"));
      } catch (e) {
        toast.error(_("error"));
        console.error("Recovr >> Debtors - Err: ", e);
        return;
      }
    } else if (typeId === 2) {
      try {
        setSynchronisations((prevState) => ({
          ...prevState,
          recovrInvoiceSync: true,
        }));  
        await syncInvoices(token, navCommunity.id);
        toast.success(_("successfully_added"));
      } catch (e) {
        toast.error(_("error"));
        console.error("Recovr >> Invoices - Err: ", e);
        return;
      }
    }
  };

  const renderYearSelect = () =>
    selected == "#admin-is" ? (
      <div
        ref={ref}
        className={classNames("customed_select", {
          customed_select_opened: yearDivIsVisible,
        })}
        style={{ background: "#6d7f92", color: "#ffffff" }}
      >
        <div
          className="customed_select_header"
          style={{ height: "100%" }}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className="actions">
            <TiArrowSortedDown />
          </div>
        </div>
        {yearDivIsVisible && (
          <div className="customed_select_body">
            {renderTeamYears().map((year, index) => (
              <div
                key={index}
                className="customed_select_body_option"
                onClick={() => setFilter({ ...filter, year })}
              >
                {year}
              </div>
            ))}
          </div>
        )}
      </div>
    ) : null;

  const renderSynchronisationSection = () => {
    const syncStatus = [
      { id: 1, label: "Tâches", isRunning: synchronisations.taskSync },
      { id: 2, label: "Factures", isRunning: synchronisations.invoiceSync },
      { id: 3, label: "ISOC", isRunning: synchronisations.isocSync },
      { id: 4, label: "IPM", isRunning: synchronisations.ipmSync },
      { id: 5, label: "TVA", isRunning: synchronisations.tvaSync },
      { id: 6, label: "Rôles", isRunning: synchronisations.rolesSync },
      { id: 7, label: "Rôles", isRunning: synchronisations.entitiesSync },
    ];

    const recovrSyncStatus = [
      {
        id: 1,
        label: "Identification des dossiers",
        isRunning: synchronisations.recovrIDSync,
      },
      {
        id: 2,
        label: "Factures",
        isRunning: synchronisations.recovrInvoiceSync,
      },
    ];

    let status = selected == "#admin-is" ? syncStatus : recovrSyncStatus;

    const currentSync = status.find((sync) => sync.id === typeId);

    if (currentSync && currentSync.isRunning) {
      return (
        <div
          className={classNames(
            styles.progress_bar,
            styles.gradiant_is_runing_synchro
          )}
        >
          Synchronisation {currentSync.label} en cours...
        </div>
      );
    }

    return (
      <>
        <div className={styles.params}>
          {renderYearSelect()}
          {selected == "#admin-is" && (typeId === 1 || typeId === 2) && (
            <div className={styles.months}>
              <span>Sélectionner le mois souhaité : </span>
              <Select
                options={monthsOptions}
                value={
                  monthsOptions.find(
                    (option) => option.id === filter.month?.id
                  ) || null
                }
                onChange={(option) => setFilter({ ...filter, month: option })}
                getOptionLabel={(e) => `${e.label}`}
                getOptionValue={(e) => e.id}
                placeholder="Choisir un mois"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "200px",
                    minWidth: "200px",
                  }),
                  menu: (base) => ({
                    ...base,
                    width: "200px",
                  }),
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.footer_actions}>
          <button
            className={styles.button_next}
            onClick={
              selected == "#admin-is"
                ? () => handleRunSynchronisation()
                : () => handleRunRecovrSynchronisation()
            }
          >
            Lancer
          </button>
        </div>
      </>
    );
  };

  const preferences = auth.user?.userOrganizations?.find(
    (org) => org.id === auth.navCommunity?.id
  )?.authorisation?.preferences;

  if (preferences?.allowAccessModelSetting === "0") {
    return <NotAuthorized />;
  }

  const tabs =
    selected == "#admin-is"
      ? synchronisationOptions
      : RECOVR_SynchronisationOptions;

  return (
    <div>
      <PageHeader header={_("gestion des synchronisations")} />
      <div className={styles.page_content}>
        <div className={classNames(styles.content)}>
          <div className={styles.bloc}>
            <div className={styles.title}>Synchronisation</div>
            <div
              style={{
                display: "flex",
                gap: 15,
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              {intergrations.map((int) => (
                <div
                  key={int.id}
                  className={`${styles.content_card} ${
                    selected == int.id && styles.content_card_active
                  }`}
                  onClick={() => setSelected(int.id)}
                >
                  <img src={int.src} alt="admin-is" height={50} />
                  <span>{int.name}</span>
                </div>
              ))}
            </div>
            <div className={classNames(styles.content, "grid-x")}>
              <div className={styles.client_types}>
                <div className={styles.sub_title}>Type de synchronisation</div>
                {tabs.map((item) => (
                  <div
                    key={item.id}
                    className={classNames(styles.types_labels, {
                      [styles.selected_type]: typeId === item.id,
                    })}
                    onClick={() => setTypeId(item.id)}
                  >
                    <span className={styles.type_label}>{item.label}</span>
                    <span className={styles.type_icon}>{">"}</span>
                  </div>
                ))}
              </div>
              <div className={styles.synchronisation_section}>
                <div className={styles.sub_title}>
                  <strong>
                    {tabs.find((opt) => opt.id === typeId)?.label || ""}
                  </strong>
                  <span>
                    {tabs.find((opt) => opt.id === typeId)?.highlightText || ""}
                  </span>
                </div>
                <div className={styles.description}>
                  {selected == "#admin-is"
                    ? "Veuillez sélectionner les paramètres de synchronisation."
                    : tabs.find((opt) => opt.id === typeId)?.description || ""}
                </div>
                {renderSynchronisationSection()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Synchronisation;
