import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { TTP_UA_URL } from "../../../../config";
import styles from "./RedirectCard.module.scss";

export default function RedirectCard({
  title,
  to,
  children,
  width = "160px",
  height = "244px",
  borderColor = "#b2bcc6",
  ...restProps
}) {
  return (
    <div
      className={styles.card}
      style={{ width: width, height: height, borderColor: borderColor  }}
      {...restProps}
    >
      <div className={styles.card_header}>
        <h2>{title}</h2>
        <Link to={`${TTP_UA_URL}&GOTO=${to}`} target="_blank" rel="noopener noreferrer" className={styles.redirect_link}>
          <FiArrowUpRight color="#29394D" />
        </Link>
      </div>
      {children}
    </div>
  );
}
