import { Modal, Tooltip } from "antd";
import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Cell, Pie, PieChart } from "recharts";
import { CustumedMatrix } from "tamtam-components";
import {
  getCategoriesSuggestions,
  getClientActivities,
  getClientDetails,
  getClientProgressionBudget,
  updateDebtorManager,
} from "../../api";
import { PERIOD_INTERVALS } from "../../config";
import _ from "../../i18n";
import {
  formatAmount,
  formatNumber,
  getUserNameForAvatar,
  renderTeamYears,
  useOnClickOutside,
} from "../../utils";
import CollaboratorCorrectionCard from "../CollaboratorSpace/ProductionTracking/EncodingProgression/CollaboratorCorrectionCard";
import {
  AlertTriangleIcon,
  BookMarkIcon,
  BriefCaseIcon,
  CheckIcon,
  FlagIcon,
  IconArrowRight,
  IconChevronDown,
  MailIcon,
  PhoneCallIcon,
  SettingsIcon,
} from "../common/Icons";
import LigthPageHeader from "../common/PageHeader/LigthPageHeader";
import Synchronizer from "../common/Recovr/Synchronizer";
import "./ClientSpace.local.scss";

const ECART_MAP = {
  NEGATIVE: "tag-warning",
  POSITIVE: "tag-danger",
  EQUAL: "tag-success",
};

const ClientSpace = () => {
  const refYears = useRef();
  const refActivities = useRef();
  useOnClickOutside(refYears, () => setYearDivIsVisible(false));
  useOnClickOutside(refActivities, () => setActivitiesFilterIsVisible(false));

  const { token, navCommunity, loggedAs, spaceAs } = useSelector(
    (state) => state.auth
  );
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  let { id } = useParams();

  //Header
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    format: "category",
  });
  //Body
  const [details, setClientDetails] = useState(null);
  const [associatedListIsVisible, setAssociatedListIsVisible] = useState(false);
  const [activities, setClientActivities] = useState([]);
  const [opentask, setOpenTask] = useState(null);
  const [filtredActivities, setFiltredActivities] = useState([]);
  const [activitiesFilterIsVisible, setActivitiesFilterIsVisible] =
    useState(false);
  const [periodInterval, setPeriodInterval] = useState(PERIOD_INTERVALS[3]);
  const [budgetProgression, setBudgetProgression] = useState({});
  const [global, setGlobal] = useState(null);
  const [volumetry, setVolumetry] = useState([]);
  const [responsables, setResponsables] = useState(null);
  const [borderedcolumns, setBorderedColumns] = useState({});
  const [entityColumns, setEntityColumns] = useState([]);
  const [entities, setEntities] = useState([]);
  const [correctionCardClient, setCorrectionCardClient] = useState(null);
  const [intervalEntityColumns, setIntervalEntityColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  const organizationId = navCommunity && navCommunity.id;
  let clientDetailsQuery = useQuery(
    ["getClientDetails", token, navCommunity, filter.year],
    async () => {
      if (token) {
        try {
          const response = await getClientDetails(
            id,
            organizationId,
            token,
            filter
          );
          setClientDetails(response.data.data);
        } catch (e) {
          setClientDetails(null);
          return null;
        }
      }
    }
  );

  let categoriesQuery = useQuery(
    ["getCategoriesList", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCategoriesSuggestions(
            navCommunity.id,
            token
          );
          let categoryResponse = response.data.data.filter((item) =>
            item.label.fr.includes("04.")
          )[0];
          setFilter({
            ...filter,
            category: {
              id: categoryResponse.id,
              label: categoryResponse.label.fr,
            },
          });
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientProgressionBudgetQuery = useQuery(
    ["getClientsList", token, navCommunity, filter.year, filter.category],
    async () => {
      if (token) {
        let columns = [];
        let entityColumns = [];
        try {
          const response = await getClientProgressionBudget(
            id,
            organizationId,
            token,
            filter
          );
          setBudgetProgression(response.data.data.progression);
          setResponsables(response.data.data.responsables);
          setVolumetry(response.data.data.volumetry);
          setGlobal(response.data.data.global);

          Object.keys(response.data.data.responsables).map((element) => {
            response.data.data.responsables[element].roles
              .filter((role) => role !== "other")
              .map((role) => {
                columns.push({
                  id: role,
                  label: _(role),
                  isEditable: true,
                  widthPercentage: 15,
                });
              });
          });
          setBorderedColumns(columns);
          Object.keys(response.data.data.progression.entities[0]).map(
            (element) => {
              element !== "label" &&
                entityColumns.push({
                  id: element,
                  label:
                    response.data.data.progression.entities[0][element].label,
                  isEditable: true,
                  widthPercentage: 15,
                });
            }
          );
          setEntities(entityColumns);
          setEntityColumns(entityColumns);
        } catch (e) {
          setBorderedColumns({});
          setBudgetProgression({});
          setResponsables(null);
          setVolumetry([]);
          setGlobal(null);
          return null;
        }
      }
    }
  );

  let clientActivitiesQuery = useQuery(
    ["getClientsActivities", token, navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getClientActivities(
            id,
            organizationId,
            token,
            {
              year: filter.year,
              language: language,
            }
          );
          setClientActivities(response.data.data);
          setFiltredActivities(response.data.data);
        } catch (e) {
          setClientActivities([]);
          setFiltredActivities([]);
          return null;
        }
      }
    }
  );

  const assignAccountManager = async () => {
    const { recovrId, manager } = details.infos;

    if (recovrId) {
      localStorage.setItem("recovr-syncing", true);

      try {
        const response = await updateDebtorManager(
          token,
          navCommunity.id,
          recovrId,
          manager.email
        );

        if (response.data.data.status === "OK") {
          toast.success(_("successfully_updated"));
        } else if (response.data.data.error) {
          toast.error(response.data.data.message || _("unknown_error_occurred"));
        }
      } catch (error) {
        console.error("Recovr API Error:", error);
        toast.error(error);
      } finally {
        localStorage.setItem("recovr-syncing", false);
      }
    }
  };

  const renderHeaderAction = () => {
    return (
      <div
        ref={refYears}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    setFilter({ ...filter, year: item });
                    setYearDivIsVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderClientCard = () => {
    return clientDetailsQuery.isFetching ? (
      <div className="card-fetching">
        <div className="card-frame">
          <div className="logo gradiant" />
          <div className="gradiant" />
          <div className="gradiant" />
          <div className="gradiant" />
          <div className="infos-icons">
            <div className="icon gradiant" />
            <div className="icon gradiant" />
            <div className="icon gradiant" />
          </div>
          <div className="list">
            <div className="list_header">
              <div className="gradiant" />
              <div className="icon gradiant" />
              <div className="icon gradiant" />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Fragment>
        <div className="card-frame">
          <div className="logo">
            <img src="https://media.istockphoto.com/id/1162545693/vector/grey-wheel-geometric-technology-background-with-gear-shape-vector-abstract-graphic-design.jpg?s=612x612&w=0&k=20&c=x6BlJkk_bp7ge-gbDKtJQmvHVBQu10LbGvUXUx-5aEA=" />
          </div>
          <h5>
            {details && `${details.infos.name} (${details.infos.legalForm})`}
          </h5>
          <label>BE {details && details.infos.uen}</label>
          <div className="infos-icons">
            <div className="infos-icons_icon">
              <PhoneCallIcon />
            </div>
            <div className="infos-icons_icon">
              <MailIcon />
            </div>
            <div className="infos-icons_icon">
              <Tooltip title="Révision de forfaits">
                <Link to={`/${language}/forfait/${id}`}>
                  <BriefCaseIcon fill="#6D7F92" size={16} />
                </Link>
              </Tooltip>
            </div>
          </div>
          <div className="list">
            <div className="list_header">
              <BookMarkIcon size={10} />
              <div>
                <span>{_("Entité lié")}</span>
                <span>
                  {details && details.infos && details.infos.attached
                    ? details.infos.attached.length
                    : 0}
                </span>
              </div>
              <span
                onClick={() =>
                  setAssociatedListIsVisible(!associatedListIsVisible)
                }
              >
                <IconChevronDown width={9} height={5} />
              </span>
            </div>
            <div className="list_body">
              <ul>
                {associatedListIsVisible &&
                  details.infos.attached.map((item, index) => (
                    <li key={item.id} className={"list_body_option"}>
                      {item.name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {(loggedAs === "SUPERADMIN" ||
            spaceAs === "SUPERADMIN" ||
            loggedAs === "ADMIN" ||
            spaceAs === "ADMIN" ||
            loggedAs === "MANGER" ||
            spaceAs === "MANAGER") &&
            global && (
              <div
                className="tag_pie"
                style={{
                  background:
                    global.budgetState === "BUDGET_COMPLETED"
                      ? "rgba(6, 217, 177, 0.1)"
                      : "rgba(235, 87, 87, 0.1)",
                }}
              >
                {global.budgetState === "BUDGET_COMPLETED" ? (
                  <div className="tag tag-success">
                    <CheckIcon fill="#02AF8E" /> {_("Budgétisation complete")}
                  </div>
                ) : (
                  <div class="pie-frame">
                    <div
                      class="pie"
                      style={{
                        "--p": 100,
                        "--c": "rgba(109, 127, 146, 0.3)",
                        position: "relative",
                        top: 50,
                      }}
                    />
                    <div
                      class="pie"
                      style={{ "--p": global.budgetStatePercentage }}
                    >
                      {" "}
                      {`${global.budgetStatePercentage}%`}
                    </div>
                    <div className="tag tag-danger">
                      <AlertTriangleIcon /> {_(global.budgetState)}
                    </div>
                  </div>
                )}
                <Link to={`/${language}/budget/${id}/${filter.year}`}>
                  <div className="action">
                    <SettingsIcon size={14} fill={"#6D7F92"} />
                    <span>{_("manageClientBudget")}</span>
                  </div>
                </Link>
              </div>
            )}
        </div>
      </Fragment>
    );
  };

  const renderActivitiesFrame = () => {
    return (
      <Fragment>
        <h3>{_("activities")}</h3>
        <div
          ref={refActivities}
          className={
            "customed_select" +
            " " +
            (activitiesFilterIsVisible ? "customed_select_opened" : "")
          }
          style={{
            background: "#F1F2F4",
            color: "#29394D",
          }}
        >
          <div className={"customed_select_header"}>
            <span>{_(periodInterval.label)}</span>
            <div className={"actions"}>
              <div>
                <span
                  className={"action"}
                  onClick={() =>
                    setActivitiesFilterIsVisible(!activitiesFilterIsVisible)
                  }
                >
                  <TiArrowSortedDown />
                </span>
              </div>
            </div>
          </div>
          {activitiesFilterIsVisible && (
            <div className={"customed_select_body"}>
              <div className={"customed_select_body_options"}>
                {PERIOD_INTERVALS.map((item, index) => (
                  <div
                    key={index}
                    className={"customed_select_body_option"}
                    onClick={() => {
                      handleSelectActivitiesFilter(item);
                    }}
                  >
                    {_(item.label)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="cellules">
          {clientActivitiesQuery.isFetching ? (
            [1, 2, 3].map((item) => (
              <div className="cellule_fetching" key={item}>
                <div className="header">
                  <div className="avatar gradiant" />
                  <div className="infos">
                    <span className="gradiant" />
                    <span className="gradiant" />
                  </div>
                </div>
                <div className="title gradiant" />
                <div className="type gradiant" />
                <div className="line">
                  <span className="label gradiant" />
                  <span className="value gradiant" />
                </div>
              </div>
            ))
          ) : filtredActivities && filtredActivities.length === 0 ? (
            <div className="no_data">
              <div className="subTitle">{_("noActivitiesFound")}</div>
            </div>
          ) : (
            filtredActivities &&
            filtredActivities.map((activity, index) => (
              <div className="cellule" key={index}>
                <div className="cellule_header">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: `url(${activity.user.avatarUrl}`,
                    }}
                  />
                  <div className="infos">
                    <span>{activity.user.name}</span>
                    <span>
                      {moment(activity.task.date.date, "YYYY-MM-DD hh:mm:ss")
                        .locale(language)
                        .fromNow()}
                    </span>
                  </div>
                </div>
                <div className="title">{activity.task.type}</div>
                <div className="type">{activity.task.category}</div>
                <div className="line">
                  <span className="label">{_("relizedDuration")} : </span>
                  <span className="value">{activity.task.duration}</span>
                </div>
              </div>
            ))
          )}
        </div>
      </Fragment>
    );
  };

  const handleSelectActivitiesFilter = (item) => {
    const displayedActivities =
      activities && activities.length > 0 && item.daysToSub !== null
        ? activities.filter(
            (activity) =>
              moment(activity.task.date.date, "YYYY-MM-DD hh:mm:ss").format(
                "YYYY-MM-DD"
              ) ===
              moment().subtract(item.daysToSub, "days").format("YYYY-MM-DD")
          )
        : activities;

    setFiltredActivities(displayedActivities);
    setActivitiesFilterIsVisible(false);
    setPeriodInterval(item);
  };

  const renderBudgetTags = () => {
    return clientProgressionBudgetQuery.isFetching ? (
      <div className="tags_fetching">
        <div className={`tag_progress`}>
          <div className="progress">
            <div className="progress_info">
              <div className="gradiant" />
              <div className="gradiant" />
            </div>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="progress_container">
                <div className="progress_icon gradiant" />
                <div className="progress_index gradiant" />
              </div>
            </div>
          </div>
        </div>
        <div className={`tag_progress`}>
          <div className="progress">
            <div className="progress_info">
              <div className="gradiant" />
              <div className="gradiant" />
            </div>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="progress_container">
                <div className="progress_icon gradiant" />
                <div className="progress_index gradiant" />
              </div>
            </div>
          </div>
        </div>
        <div className="tag_pie">
          <div className="tag gradiant" />
        </div>
      </div>
    ) : (
      global && (
        <div style={{ display: "flex", gap: "20px", width: "100%" }}>
          <div
            className={`tag_progress ${
              global.hours.budget > 0 &&
              global.hours.real / global.hours.budget > 0.9
                ? "bg-danger"
                : "bg-success"
            }`}
            style={{ flex: "1 0 0" }}
          >
            <div className="tag_progress_info">
              <div>{_("hourBudget")}</div>
              <div>
                {parseInt(global.hours.budget) > 0
                  ? `${formatAmount(global.hours.budget, false)}h`
                  : "0h"}
              </div>
            </div>
            <div
              style={{
                height: "38px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="item_progress_container">
                <div
                  className="item_progress_bar"
                  style={{
                    width:
                      parseInt(global.hours.real) >
                      parseInt(global.hours.budget)
                        ? "100%"
                        : parseInt(global.hours.budget) > 0 &&
                          parseInt(global.hours.real) > 0
                        ? `${
                            (parseInt(global.hours.real) * 100) /
                            parseInt(global.hours.budget)
                          }%`
                        : 0,
                  }}
                />
                <div
                  className="item_progress_index"
                  style={{
                    width:
                      parseInt(global.hours.real) >
                      parseInt(global.hours.budget)
                        ? "200%"
                        : parseInt(global.hours.budget) > 0 &&
                          parseInt(global.hours.real) > 0
                        ? `${
                            (parseInt(global.hours.real) * 200) /
                            parseInt(global.hours.budget)
                          }%`
                        : 0,
                  }}
                >
                  <TiArrowSortedDown />{" "}
                  <div>
                    {parseInt(global.hours.real) > 0
                      ? `${formatAmount(global.hours.real, false)}h`
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tag_progress ${
              global.economic.budget > global.economic.real
                ? "bg-danger"
                : "bg-success"
            }`}
            style={{ flex: "1 0 0" }}
          >
            <div className="tag_progress_info">
              <div>{_("economicBudget")}</div>
              <div>
                {parseInt(global.economic.budget) > 0
                  ? formatAmount(global.economic.budget)
                  : "0€"}
              </div>
            </div>
            <div
              style={{
                height: "38px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="item_progress_container">
                <div
                  className="item_progress_bar"
                  style={{
                    width:
                      parseInt(global.economic.real) >
                      parseInt(global.economic.budget)
                        ? "100%"
                        : parseInt(global.economic.budget) > 0 &&
                          parseInt(global.economic.real) > 0
                        ? `${
                            (parseInt(global.economic.real) * 100) /
                            parseInt(global.economic.budget)
                          }%`
                        : 0,
                    background:
                      parseFloat(global.hours.real) > 0 &&
                      parseFloat(global.economic.real) /
                        parseFloat(global.hours.real) >
                        80
                        ? "#06D9B1"
                        : "#EA8124",
                  }}
                />
                <div
                  className="item_progress_index"
                  style={{
                    width:
                      parseFloat(global.economic.budget) > 0 &&
                      parseFloat(global.economic.real) > 0
                        ? parseFloat(global.economic.real) >
                          parseFloat(global.economic.budget)
                          ? `inherit`
                          : `${
                              (parseFloat(global.economic.real) * 200) /
                              parseFloat(global.economic.budget)
                            }%`
                        : "fit-content",
                    color:
                      parseFloat(global.hours.real) > 0 &&
                      parseFloat(global.economic.real) /
                        parseFloat(global.hours.real) >
                        80
                        ? "#02AF8E"
                        : "#EA8124",
                    alignItems:
                      parseFloat(global.economic.real) >
                      parseFloat(global.economic.budget)
                        ? "end"
                        : "center",
                  }}
                >
                  <TiArrowSortedDown />{" "}
                  <div>
                    {parseFloat(global.economic.real) > 0
                      ? formatAmount(global.economic.real)
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tag_progress ${
              global.economic.budget > global.economic.real
                ? "bg-danger"
                : "bg-success"
            }`}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              flex: "1 0 0",
            }}
          >
            <div>
              <div className="tag_progress_info">
                <div>{_("realizedProfitability")}</div>
                <div>
                  {parseFloat(global.hours.real) > 0
                    ? formatAmount(global.economic.real / global.hours.real) +
                      "/h"
                    : "0%"}
                </div>
              </div>
              <div className="tag_progress_info">
                <div>{_("budgetedProfitability")}</div>
                <div>
                  {parseFloat(global.hours.budget) > 0
                    ? formatAmount(
                        global.economic.budget / global.hours.budget
                      ) + "/h"
                    : "0%"}
                </div>
              </div>
            </div>
            {/* Payments / Outstandings */}
            <div style={{
              width: "100%",
              height: 3,
              backgroundColor: "#F1F2F4"
            }} />
            <div>
              <div className="tag_progress_info">
                <div>{_("totalPaid")}</div>
                <div>{formatNumber(parseFloat(global.payment.paid)) + "€"}</div>
              </div>
              <div className="tag_progress_info">
                <div>{_("outstanding")}</div>
                <div>{formatNumber(parseFloat(global.payment.remaining)) + "€"}</div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderTaskList = () => {
    return (
      <Fragment>
        <div className="list-frame_header">
          <div className="list-frame_header_title">{_("taskList")}</div>
          <div className="list-frame_header_tabs">
            {["category", "collaborator", "entity"].map((format, i) => {
              return (
                <div
                  key={i}
                  className={`tab_badge ${
                    filter.format === format ? "tab_badge_active" : ""
                  }`}
                  onClick={() => setFilter({ ...filter, format: format })}
                >
                  {_(format)}
                </div>
              );
            })}
          </div>
        </div>
        {clientProgressionBudgetQuery.isFetching ? (
          <div className="list_fetching">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((element) => (
              <div className="list-frame_item" key={element}>
                <div className="list-frame_item_header">
                  <div className="title gradiant" />
                  <div className="progress">
                    <div
                      style={{
                        height: "28px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div className="progress_container">
                        <div className="progress_bar gradiant"></div>
                        <div
                          className="progress_index"
                          style={{
                            width: 0,
                          }}
                        >
                          <div className="progress_bar gradiant" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="avatars">
                    {[0, 1, 2, 3, 4].map((index) => (
                      <div className="avatar" key={index}>
                        {renderAvatar("", "")}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="list-frame_body">
            {filter.format === "category" &&
              Object.keys(budgetProgression).length > 0 &&
              Object.keys(budgetProgression.categories).map((item) => {
                if (
                  parseFloat(budgetProgression.categories[item].total.real) ===
                    0 &&
                  parseFloat(
                    budgetProgression.categories[item].total.budget
                  ) === 0
                ) {
                  return "";
                }
                let ecart =
                  parseFloat(
                    budgetProgression.categories[item].total.real
                  ).toFixed(2) -
                  budgetProgression.categories[item].total.budget;
                return (
                  <div
                    className={`list-frame_item ${
                      opentask === item && "list-frame_item__open"
                    }`}
                  >
                    <div
                      className="list-frame_item_header"
                      onClick={() =>
                        setOpenTask(
                          item !== opentask &&
                            Object.keys(
                              budgetProgression.categories[item].details
                            ).length > 0
                            ? item
                            : null
                        )
                      }
                    >
                      <div className="title">{item}</div>
                      <div className="total">
                        {formatAmount(budgetProgression.categories[item].total.budget, false)}
                        h
                      </div>
                      <div className="progress">
                        <div
                          style={{
                            height: "28px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div className="progress_container">
                            <div
                              className="progress_bar"
                              style={{
                                width:
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .budget
                                  ) > 0 &&
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .real
                                  ) > 0
                                    ? parseFloat(
                                        budgetProgression.categories[item].total
                                          .real
                                      ) >
                                      parseFloat(
                                        budgetProgression.categories[item].total
                                          .budget
                                      )
                                      ? `100%`
                                      : `${
                                          (parseFloat(
                                            budgetProgression.categories[item]
                                              .total.real
                                          ) *
                                            100) /
                                          parseFloat(
                                            budgetProgression.categories[item]
                                              .total.budget
                                          )
                                        }%`
                                    : 0,
                                background:
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .real
                                  ) >
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .budget
                                  )
                                    ? "#EB5757"
                                    : "#06D9B1",
                              }}
                            ></div>
                            <div
                              className="progress_index"
                              style={{
                                width:
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .budget
                                  ) > 0 &&
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .real
                                  ) > 0
                                    ? parseFloat(
                                        budgetProgression.categories[item].total
                                          .real
                                      ) >
                                      parseFloat(
                                        budgetProgression.categories[item].total
                                          .budget
                                      )
                                      ? `100%`
                                      : `${
                                          (parseFloat(
                                            budgetProgression.categories[item]
                                              .total.real
                                          ) *
                                            200) /
                                          parseFloat(
                                            budgetProgression.categories[item]
                                              .total.budget
                                          )
                                        }%`
                                    : 0,
                                color:
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .real
                                  ) >
                                  parseFloat(
                                    budgetProgression.categories[item].total
                                      .budget
                                  )
                                    ? "#DE4848"
                                    : "#02AF8E",
                              }}
                            >
                              <div>
                                {formatAmount(budgetProgression.categories[item].total.real, false)}
                                h
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="avatars">
                        {Object.keys(responsables).length > 0 &&
                        budgetProgression.categories[item].total.responsables
                          .length > 0
                          ? budgetProgression.categories[
                              item
                            ].total.responsables.map((responsable) => (
                              <div className="avatar" key={responsable}>
                                {renderAvatar(
                                  responsables[responsable].avatarUrl,
                                  responsables[responsable].name
                                )}
                              </div>
                            ))
                          : "--------"}
                      </div>

                      <div className="action">
                        {Object.keys(budgetProgression.categories[item].details)
                          .length > 0 && <IconChevronDown />}
                      </div>
                    </div>
                    {opentask === item && (
                      <div className="list-frame_item_body">
                        <div className="header">
                          <label>{_("total")}</label>
                          <div className="tag">
                            <span>{_("Budgétés")}</span>
                            <div className="tag">
                              {formatAmount(budgetProgression.categories[item].total.budget, false)}
                              h
                            </div>
                          </div>

                          <div className="tag tag-success">
                            <span>{_("Réalisés")}</span>{" "}
                            <div className="tag">
                              {formatAmount(budgetProgression.categories[item].total.real, false)}
                              h
                            </div>
                          </div>
                          <div
                            className={`tag ${
                              ECART_MAP[
                                ecart > 0
                                  ? "POSITIVE"
                                  : ecart < 0
                                  ? "NEGATIVE"
                                  : "EQUAL"
                              ]
                            }`}
                          >
                            <span>{_("Écart")}</span>{" "}
                            <div className="tag">
                              {ecart > 0 ? "+" : ""}
                              {formatAmount(ecart, false)}
                            </div>
                          </div>
                        </div>
                        {Object.keys(responsables).length > 0 &&
                          Object.keys(
                            budgetProgression.categories[item].details
                          ).map((index) => {
                            let detail =
                              budgetProgression.categories[item].details[index];
                            return (
                              <div className="line">
                                <div className="infos">
                                  <div
                                    className="avatar"
                                    style={{
                                      backgroundImage: `url(${responsables[index].avatarUrl}`,
                                    }}
                                  ></div>
                                  <div>{responsables[index].name}</div>
                                  <div>
                                    {_(responsables[index].roles.join("-"))}
                                  </div>
                                </div>
                                <div className="tag">

                                      <div>{formatAmount(detail.budget, false)}</div>

                                </div>
                                <div className="tag tag-success">

                                      <div>{formatAmount(detail.real, false)}</div>

                                </div>
                                <div
                                  className={`tag ${ECART_MAP[detail.ecart]}`}
                                >
                                  <div>
                                    {ecart > 0 ? "+" : ""}
                                    {formatAmount(
                                      detail.real - detail.budget, false
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                );
              })}
            {filter.format === "collaborator" && (
              <div>
                <CustumedMatrix
                  columns={borderedcolumns}
                  items={budgetProgression.collaborators}
                  handleCellule={handleColumns}
                  handleTitle={handleTitle}
                  headerTagBG={"#FFFFFF"}
                />
              </div>
            )}
            {filter.format === "entity" && (
              <div className="slide-content">
                <div className="slide-matrix">
                  <CustumedMatrix
                    columns={entityColumns}
                    items={budgetProgression.entities}
                    handleCellule={handleEntityColumns}
                    handleTitle={handleEntityTitle}
                    headerTagBG={"#FFFFFF"}
                  />
                </div>
                {entities.length > 3 && (
                  <div className="slide-column">
                    <div className="actions">
                      <div className="previous">
                        <IconArrowRight size={10} fill="#29394D" />
                      </div>
                      <div className="next">
                        <IconArrowRight size={10} fill="#29394D" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  };

  const handleEntityColumns = (column, item) => {
    return (
      <div className="tag-container">
        <div
          class={`tag ${
            item[column.id] && item[column.id].real > 0 ? "tag-success" : ""
          }`}
        >
          <div>{item[column.id] ? formatAmount(item[column.id].real, false) : 0}</div>
        </div>
      </div>
    );
  };

  const handleEntityTitle = (item) => {
    return (
      <div
        style={{
          height: "28px",
          display: "flex",
          alignItems: "center",
          margin: "5px 5px 17px 15px",
        }}
      >
        {item.label}
      </div>
    );
  };

  const handleColumns = (column, item, index1) => {
    if (item.label === "Total") {
      let responsable = null;
      Object.keys(responsables).length &&
        Object.keys(responsables).map((element) => {
          if (responsables[element].roles.includes(column.label)) {
            responsable = responsables[element];
          }
        });
      return (
        <div
          style={{
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "-4px",
          }}
        >
          <div className="avatar" key={column.label}>
            {renderAvatar(responsable.avatarUrl, responsable.name)}
          </div>
          <div className="progress">
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="progress_container">
                <div
                  className="progress_bar"
                  style={{
                    width:
                      parseFloat(item[column.label].budget) > 0 &&
                      parseFloat(item[column.label].real) > 0
                        ? parseFloat(item[column.label].real) >
                          parseFloat(item[column.label].budget)
                          ? `100%`
                          : `${
                              (parseFloat(item[column.label].real) * 100) /
                              parseFloat(item[column.label].budget)
                            }%`
                        : 0,
                    background:
                      parseFloat(item[column.label].real) >
                      parseFloat(item[column.label].budget)
                        ? "#DE4848"
                        : "#06D9B1",
                  }}
                ></div>
                <div className="progress_total">
                  {formatAmount(item[column.label].budget, false)} h
                </div>
                <div
                  className="progress_index"
                  style={{
                    width:
                      parseFloat(item[column.label].budget) > 0 &&
                      parseFloat(item[column.label].real) > 0
                        ? parseFloat(item[column.label].real) >
                          parseFloat(item[column.label].budget)
                          ? `100%`
                          : `${
                              (parseFloat(item[column.label].real) * 200) /
                              parseFloat(item[column.label].budget)
                            }%`
                        : 0,
                    color:
                      parseFloat(item[column.label].real) >
                      parseFloat(item[column.label].budget)
                        ? "#DE4848"
                        : "#02AF8E",
                  }}
                >
                  <div>{formatAmount(item[column.label].real, false)}h</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        item[column.label] && (
          <div className="progress" style={{ width: "100%" }}>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                margin: "5px",
              }}
            >
              <div className="progress_container">
                <div
                  className="progress_bar"
                  style={{
                    width:
                      parseFloat(item[column.label].budget) > 0 &&
                      parseFloat(item[column.label].real) > 0
                        ? parseFloat(item[column.label].real) >
                          parseFloat(item[column.label].budget)
                          ? `100%`
                          : `${
                              (parseFloat(item[column.label].real) * 100) /
                              parseFloat(item[column.label].budget)
                            }%`
                        : 0,
                    background:
                      parseFloat(item[column.label].real) >
                      parseFloat(item[column.label].budget)
                        ? "#DE4848"
                        : "#06D9B1",
                  }}
                ></div>
                <div className="progress_total">
                  {formatAmount(item[column.label].budget, false)} h
                </div>
                <div
                  className="progress_index"
                  style={{
                    width:
                      parseFloat(item[column.label].budget) > 0 &&
                      parseFloat(item[column.label].real) > 0
                        ? parseFloat(item[column.label].real) >
                          parseFloat(item[column.label].budget)
                          ? `100%`
                          : `${
                              (parseFloat(item[column.label].real) * 200) /
                              parseFloat(item[column.label].budget)
                            }%`
                        : 0,
                    color:
                      parseFloat(item[column.label].real) >
                      parseFloat(item[column.label].budget)
                        ? "#DE4848"
                        : "#02AF8E",
                  }}
                >
                  <div>{formatAmount(item[column.label].real, false)}h</div>
                </div>
              </div>
            </div>
          </div>
        )
      );
    }
  };

  const handleTitle = (title) => {
    if (title.label === "Total") {
      return (
        <div
          style={{
            height: "70px",
            display: "flex",
            alignItems: "center",
            margin: "5px 15px",
          }}
        >
          {title.label}
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: "28px",
            display: "flex",
            alignItems: "center",
            margin: "5px 5px 17px 15px",
          }}
        >
          {title.label}
        </div>
      );
    }
  };

  const renderAvatar = (avatarUrl, name) => {
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(name)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div
          className="avatar_exist"
          style={{
            backgroundImage: `url(${avatarUrl}`,
          }}
        />
      );
    }

    return avatarDiv;
  };

  const renderGaugeData = (form) => {
    let ecart = 0;
    if (form) {
      ecart = volumetry.chart
        ? parseFloat(
            (volumetry.total.budget - 4 * volumetry.chart.real) /
              volumetry.total.budget
          ).toFixed(4)
        : 0;
    } else {
      ecart = volumetry.total
        ? parseFloat(
            (volumetry.total.budget - volumetry.total.real) /
              volumetry.total.budget
          ).toFixed(4)
        : 0;
    }

    const data =
      ecart > 0
        ? [
            { name: "A", value: 135, color: "#E1E4E8" },
            {
              name: "B",
              value:
                ecart == 1
                  ? 135
                  : ecart *
                    (form
                      ? volumetry.total.budget / 4
                      : volumetry.total.budget),
              color: "#06D9B1",
            },
            {
              name: "C",
              value:
                ecart == 1
                  ? 0
                  : 135 -
                    ecart *
                      (form
                        ? volumetry.total.budget / 4
                        : volumetry.total.budget),
              color: "#E1E4E8",
            },
          ]
        : ecart < 0
        ? [
            {
              name: "C",
              value:
                135 -
                Math.abs(
                  ecart *
                    (form ? volumetry.total.budget / 4 : volumetry.total.budget)
                ),
              color: "#E1E4E8",
            },
            {
              name: "B",
              value: Math.abs(
                ecart *
                  (form ? volumetry.total.budget / 4 : volumetry.total.budget)
              ),
              color: "#06D9B1",
            },
            { name: "A", value: 135, color: "#E1E4E8" },
          ]
        : [
            { name: "A", value: 135, color: "#E1E4E8" },
            { name: "B", value: 135, color: "#E1E4E8" },
          ];
    return { data: data, ecart: ecart };
  };

  const rendeVolumetryTable = () => {
    return (
      <Fragment>
        <div className="table-panel">
          <div className="table-panel_title">{_("Volumétrie")}</div>
          {clientProgressionBudgetQuery.isFetching ? (
            <div className="table-panel_fetching">
              <div className="header">
                <label className="gradiant"></label>

                <div className="tag">
                  <div className="tag gradiant" />
                </div>
                <div className="tag">
                  <div className="tag gradiant" />
                </div>
                <div className="tag ">
                  <div className="tag gradiant" />
                </div>
              </div>
              {[1, 2, 3, 4, 5].map((item) => (
                <div className="line">
                  <div className="infos gradiant" />
                  <div className="tag">
                    <div className="gradiant" />
                  </div>
                  <div className="tag">
                    <div className="gradiant" />
                  </div>
                  <div className="tag">
                    <div className="gradiant" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="table-panel_table">
              {volumetry.total && (
                <div className="header">
                  <label>{_("total")}</label>
                  <div className="tag">
                    {preferences &&
                      preferences.allowVisualizationOfBudgetedBenchmark ===
                        "1" && (
                        <>
                          <span>{_("Budgétés")}</span>
                          <div className="tag">{formatAmount(volumetry.total.budget, false)}</div>
                        </>
                      )}
                  </div>
                  <div className="tag tag-success">
                    {preferences &&
                      preferences.allowVisualizationOfRealizedBenchmark ===
                        "1" && (
                        <>
                          <span>{_("Réalisés")}</span>{" "}
                          <div className="tag">
                            {!isNaN(volumetry.total.real)
                              ? formatAmount(volumetry.total.real, false)
                              : "-----"}
                          </div>
                        </>
                      )}
                  </div>
                  <div
                    className={`tag ${
                      parseInt(volumetry.total.budget) -
                        parseInt(volumetry.total.real) >
                      0
                        ? "tag-success"
                        : parseInt(volumetry.total.budget) -
                            parseInt(volumetry.total.real) <
                          0
                        ? "tag-danger"
                        : "tag-warning"
                    }`}
                  >
                    <span>{_("Écart")}</span>{" "}
                    <div className="tag">
                      {formatAmount(volumetry.total.budget - volumetry.total.real, false)}
                    </div>
                  </div>
                </div>
              )}
              {/* {Object.keys(budgetProgression).length > 0 &&
                volumetry.details.map((item) => {
                  return (
                    <div className="line">
                      <div className="infos">{item.label}</div>
                      <div className="tag">
                        {preferences &&
                          preferences.allowVisualizationOfBudgetedBenchmark ===
                            "1" && <div>
                            {
                              !isNaN(item.budget)
                              ? formatAmount(item.budget, false)
                              : '-----'
                            }
                          </div>
                        }
                      </div>
                      <div className="tag tag-success">
                        {preferences &&
                          preferences.allowVisualizationOfRealizedBenchmark ===
                            "1" && (
                            <div>
                              {!isNaN(item.real)
                                ? formatAmount(item.real, false)
                                : "-----"}
                            </div>
                          )}
                      </div>
                      <div
                        className={`tag ${
                          parseInt(item.budget) - parseInt(item.real) > 0
                            ? "tag-success"
                            : parseInt(item.budget) - parseInt(item.real) < 0
                            ? "tag-danger"
                            : "tag-warning"
                        }`}
                      >
                        <div>
                          {parseInt(item.budget) - parseInt(item.real) > 0
                            ? "+"
                            : ""}
                          {!isNaN(parseInt(item.budget) - parseInt(item.real))
                            ? formatAmount(parseInt(item.budget) - parseInt(item.real), false)
                            : "-----"}
                        </div>
                      </div>
                    </div>
                  );
                })} */}
            </div>
          )}
        </div>
        {volumetry.total && (
          <div className="chart-panel">
            <div className="chart-panel_unity">
              <p>Résultat du trimestre courant :</p>
              <div className="frame">
                <div className="label">
                  <span>{_("Total réalisé : ")}</span>
                  <span>{volumetry.chart.real}</span>
                  <span
                    className={
                      renderGaugeData("term").ecart > 0
                        ? "cl-success"
                        : "cl-danger"
                    }
                  >
                    {formatAmount(volumetry.total.budget / 4 - volumetry.chart.real, false)}
                  </span>
                </div>
                <div className="value_tag">
                  <span>
                    {parseFloat(renderGaugeData("term").ecart).toFixed(2) * 100}
                  </span>
                  <span>%</span>
                </div>
                <div className="pie_frame">
                  <PieChart width={140} height={140}>
                    <Pie
                      dataKey="value"
                      startAngle={225}
                      endAngle={-45}
                      data={renderGaugeData("term").data}
                      cx={55}
                      cy={50}
                      innerRadius={30}
                      outerRadius={50}
                      fill="#8884d8"
                      stroke="none"
                    >
                      {renderGaugeData("term").data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              </div>
            </div>
            <div className="chart-panel_unity">
              <p>Résultat annuel :</p>
              <div className="frame">
                <div className="label">
                  <span>{_("Total réalisé: ")}</span>
                  <span>{volumetry.total.real}</span>
                  <span
                    className={
                      renderGaugeData(null).ecart > 0
                        ? "cl-success"
                        : "cl-danger"
                    }
                  >
                    {formatAmount(volumetry.total.budget - volumetry.total.real, false)}
                  </span>
                </div>
                <div className="value_tag">
                  <span>
                    {parseFloat(renderGaugeData(null).ecart).toFixed(2) * 100}
                  </span>
                  <span>%</span>
                </div>
                <div className="pie_frame">
                  <PieChart width={140} height={140}>
                    <Pie
                      dataKey="value"
                      startAngle={225}
                      endAngle={-45}
                      data={renderGaugeData(null).data}
                      cx={55}
                      cy={50}
                      innerRadius={30}
                      outerRadius={50}
                      fill="#8884d8"
                      stroke="none"
                    >
                      {renderGaugeData(null).data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderClientInfoDetails = () => {
    return (
      details &&
      details["map"] &&
      details["map"].map((item) => (
        <div className="unity">
          <label>{_(item.property)} :</label>
          <span>{item.value}</span>
        </div>
      ))
    );
  };
  const renderAddBubble = () => {
    return (
      <div className="setting-bubbles">
        <div
          className={`noFill
            ${correctionCardClient ? "active opened" : ""}`}
          onClick={() => {
            let responsablesLocale = [];
            Object.keys(responsables).forEach((key) => {
              if (
                responsables[key].roles.includes("MANAGER") ||
                responsables[key].roles.includes("GE")
              ) {
                responsablesLocale.push({
                  name: responsables[key].name,
                  uid: "",
                });
              }
            });
            setCorrectionCardClient({
              id,
              title: {
                label: details.infos.name,
                responsables: responsablesLocale,
              },
            });
          }}
        >
          <FlagIcon size="20" fill="#6D7F92" />
          <span style={{ paddingLeft: "2.5rem" }}>{_("correctionCard")}</span>
        </div>
      </div>
    );
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${correctionCardClient && "display"}`}>
          <CollaboratorCorrectionCard
            organization={navCommunity ? navCommunity.id : null}
            token={token}
            year={filter.year}
            loggedAs={loggedAs}
            creator={user}
            onClose={() => {
              setCorrectionCardClient(null);
            }}
            client={correctionCardClient}
            lng={language}
          />
        </div>
      </div>
    );
  };

  const manager =
    details &&
    details["map"] &&
    details["map"].filter((p) => p.property == "GD")[0]["value"];

  return preferences && preferences.allowAccessClientSpace === "1" ? (
    <div className="page-content">
      <LigthPageHeader
        title={{
          label: "ficheClient",
          value:
            details && `${details.infos.name} (${details.infos.legalForm})`,
        }}
        goBack={`/${language}/clients`}
      >
        {renderHeaderAction()}
      </LigthPageHeader>
      <Synchronizer
        offset={20}
        tip="Assigner un getsionnaire de dossier"
        sync={() => setShowModal(true)}
      />
      <Modal
        open={showModal}
        title={<h4>{`Configuration de ${details?.infos.name}`}</h4>}
        onCancel={() => setShowModal(false)}
        footer={null}
        className="ttp-synchronizer-recovr"
      >
        <div className="ttp-synchronizer-recovr_container">
          <span>
            Le gestionnaire de compte doit avoir un compte dans recovr
          </span>
          <p>
            Ajouter <em style={{ opacity: 0.7 }}>{manager}</em> comme
            gestionnaire de compte sur Recovr
          </p>
          <button onClick={assignAccountManager}>{_("assign")}</button>
        </div>
      </Modal>

      <div className="container" style={{ paddingBottom: 60 }}>
        <div className="medium-11 content">
          <div className="aside-colomn">
            {renderAddBubble()}
            <div className="card-frame">{renderClientCard()}</div>
            <div className="history-frame">{renderActivitiesFrame()}</div>
          </div>
          <div className="large-colomn">
            <div className="upper-frame">
              <div className="left-panel">
                <div className="tag-frame">{renderBudgetTags()}</div>
                <div className="list-frame">{renderTaskList()}</div>
                <div className="lower-frame">{rendeVolumetryTable()}</div>
              </div>
              <div className="right-panel">{renderClientInfoDetails()}</div>
            </div>
          </div>
        </div>
        {correctionCardClient && renderSideBarFrame()}
      </div>
    </div>
  ) : (
    <div className="no_data">
      <div className="title">{_("Oops")}</div>
      <div className="subTitle">{_("Accès non autorisé!")}</div>
    </div>
  );
};

export default ClientSpace;
