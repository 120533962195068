const teamEn = {
  save: "Save",
  cancel: "Cancel",
  english: "English",
  french: "French",
  dutch: "Dutch",
  yes: "Yes",
  no: "No",
  encoding: "Encoding",
  closure: "Closure",
  closure_ctrl: "Months Control/Closure",
  year: "Year",
  month: "Month",
  deleted: "Deleted collaborator",
  setYouAsBackup: "set you as backup for his absence",
  accessDenied: "Access Denied",
  accessDeniedMessage: "Sorry. You are not authorized to access this resource",

  downloadInvoice: "Download Invoice",
  download: "Download",
  invoiceNumber: "Invoice Number",
  invoiceDate: "Invoice Date",
  accountingPrestation: "Accounting Prestation",

  filterReporting: "Filter Reporting",
  filterReportingSubtitle: "Navigate and customize reports across tabs"
};
export default teamEn;
