import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { ClockLoader } from "react-spinners";
import styles from "./Synchronizer.module.scss";

export default function Synchronizer({
  sync,
  progress,
  position = "bottom-right",
  offset = 0,
  tip = "Synchroniser avec Recovr",
}) {
  const [syncing, setSyncing] = useState(
    JSON.parse(localStorage.getItem("recovr-syncing")) || false
  );

  useEffect(() => {
    localStorage.setItem("recovr-syncing", syncing);
    return () => localStorage.removeItem("recovr-syncing");
  }, [syncing]);

  const handleClick = async () => {
    setSyncing(true);
    try {
      await sync();
    } catch (error) {
      console.error("Error during sync:", error);
    } finally {
      setSyncing(false);
    }
  };

  const style = {
    ...(position.includes("top") && { top: offset }),
    ...(position.includes("bottom") && { bottom: offset }),
    ...(position.includes("left") && { left: offset }),
    ...(position.includes("right") && { right: offset }),
  };

  return (
    <div className={styles.container} style={style}>
      <Tooltip placement="left" title={tip}>
        <button
          type="submit"
          className={styles.btn}
          onClick={handleClick}
          style={{
            pointerEvents: syncing ? "none" : "auto",
          }}
        >
          {syncing ? (
            <ClockLoader size={20} color="white" />
          ) : (
            <img
              src="https://downloads.intercomcdn.com/i/o/522314/b43088fa68e73304f66103b0/701fd727a086d85ec457f42ce9e22f5c.png"
              alt="recovr_logo"
              width={20}
            />
          )}
        </button>
      </Tooltip>
    </div>
  );
}
