const ADMIN_SUB_TAB = "admin";
const TODO_SUB_TAB = "todo";

export const SUB_TABS = [
  {
    key: ADMIN_SUB_TAB,
    label: "Admin"
  }
]

export const PAGINATION_CURRENT_PAGE = 0;
export const PAGINATION_START = 0;
export const PAGINATION_LIMIT = 5;

// those columns exist in all tabs
const COMMUN_COLUMNS = [
  {
    field: "clientName",
    header: "Nom",
    resizeable: true,
    align: 'left',
    style: {
      width: "240px"
    },
  }
]

export const SUB_TABS_COLUMNS = {
  [ADMIN_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "rdv",
      header: "RDV",
      alignHeader: 'center',
      style: {
        width: "280px"
      },
    },
    {
      field: "observation",
      header: "Remarque",
      alignHeader: 'center',
      align: 'right',
    },
  ]
}
