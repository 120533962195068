import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setManagerNotification } from "../../store";
import { AlertTriangleIcon } from "../common/Icons";
import { getUserNameForAvatar, generateFullName } from "../../utils";
import { Tooltip } from "antd";
import "./NotificationPage.local.scss";
import "./NotificationActivitePage.local.scss";
import { useInterval } from "../../utils";
import { TiArrowSortedDown } from "react-icons/ti";
import _ from "../../i18n";
import LigthPageHeader from "../common/PageHeader/LigthPageHeader";
import { getManagerNotification, getBU } from "../../api";

const NotificationsActivitePage = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { managerNotification, loggedAs } = auth;
  const [interval, setInterval] = useState({ start: 10, limit: 10 });
  const { synchronizationList } = auth;
  const [activeBu, setActiveBu] = useState(null);
  const [activeTab, setActiveTab] = useState({
    label: "Toutes les activités",
  });
  const [listCollaboratorFiltredByBu, setListCollaboratorFiltredByBu] =
    useState([]);

  const initialElement = { id: null, label: "Toutes les activités" };
  const [BU_LIST, setBU_LIST] = useState([initialElement]);

  const preferences = auth.user
    ? auth.user.userOrganizations.find(
        (organization) => organization.id === auth.navCommunity.id,
      ).authorisation.preferences
    : {};

  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  useInterval(() => {
    let allowedNotifications = [];
    if (
      preferences.activateCalendarNotifications ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (preferences.activateCarteNotification || loggedAs === "COLLABORATOR") {
      allowedNotifications.push("CORRECTION_CARD");
      allowedNotifications.push("REPORT_ERROR_CARD");
    }
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        loggedAs,
        allowedNotifications,
      );
      managerNotificationQuery.then((result) => {
        if (
          result.data.data &&
          managerNotification.nbrResult < result.data.data.nbrResult
        ) {
          const data = [...result.data.data.data];
          dispatch(
            setManagerNotification({
              nbrResult: managerNotificationQuery.nbrResult,
              data,
            }),
          );
          setInterval({
            ...interval,
            start: 10,
          });
        }
      });
    } catch (e) {
      return null;
    }
  }, 100000);

  useEffect(() => {
    if (synchronizationList && synchronizationList.listeCollaboarators) {
      const filteredCollaborators = activeBu
        ? synchronizationList.listeCollaboarators.filter(
            (collaborator) => collaborator.BusinessUnit === activeBu,
          )
        : synchronizationList.listeCollaboarators;
      setListCollaboratorFiltredByBu(filteredCollaborators);
    }
  }, [activeBu, synchronizationList]);

  useEffect(() => {
    async function fetchBUData() {
      try {
        const responseBu = await getBU({
          token: auth.token,
          organizationId: auth.navCommunity.id,
        });
        if (responseBu) {
          setBU_LIST((prevBUList) => [initialElement, ...responseBu.data.data]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données BU:", error);
      }
    }
    fetchBUData();
  }, []);


  const getTabCount = (tab) => {
    if (synchronizationList?.listeCollaboarators) {
      if (tab.id === null) {
        return synchronizationList.listeCollaboarators.length;
      }

      let count = 0
      for(let i = 0; i < synchronizationList.listeCollaboarators.length; i++) {
        if (tab.id === synchronizationList.listeCollaboarators[i].BusinessUnit) {
          ++count;
        }
      }

      return count;
    }
    return 0;
  }

  const renderTabContent = () => {
    return BU_LIST.map((tab) => (
      <div
        key={tab.id}
        className={`tab ${activeTab.id === tab.id || activeTab.label === tab.label ? "tab-active" : ""} tab`}
        onClick={() => {
          setActiveBu(tab.id);
          setActiveTab(tab);
        }}
      >
        {_(tab.label)}

        <span className="count">{getTabCount(tab)}</span>
      </div>
    ));
  };

  const renderListNotifications = () => {
    return (
      <div className="content-frame">
        <div className="content-header">
          <div className="selected-tab-title">
            {activeTab.label}
          </div>
        </div>

        {synchronizationList && synchronizationList.synchro_is_runing && (
          <div className={"notifications-list"}>

            <hr className="divider" />

            {listCollaboratorFiltredByBu &&
            listCollaboratorFiltredByBu.length > 0 ? (
              listCollaboratorFiltredByBu.map((notif) => (
                <div className='notification-item' key={notif.id}>
                  <div className="avatar">
                    {renderAvatar(notif.avatarUrl, notif.name)}
                  </div>
                  <div
                    className="notification-content"
                    style={{ flex: "1 0 0" }}
                  >
                    <div className="notification-content-title">
                      {notif.name}
                    </div>
                    <div className="body">
                      {notif.BusinessUnit
                        ? BU_LIST.map((tab) => {
                            return tab.id === notif.BusinessUnit
                              ? tab.label
                              : null;
                          })
                        : "tous"}
                    </div>
                  </div>
                  {notif.state === "NOT_CONFIGURED" ? (
                    <Fragment>
                      <Tooltip
                        key="tool-2"
                        placement="top"
                        title={_("regimeNotConfigurated")}
                        mouseLeaveDelay={0}
                      >
                        <div className="tag tag-alert">
                          <AlertTriangleIcon fill="#EB5757" />
                        </div>
                      </Tooltip>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="tag">
                        <TiArrowSortedDown />
                        <span>{`${parseFloat(
                          parseFloat(notif.ecart).toFixed(2),
                        )}H`}</span>
                      </div>
                    </Fragment>
                  )}
                </div>
              ))
            ) : (
              <div style={{ padding: 10 }}>
                {" "}
                Aucune notification n'est disponible{" "}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="page-content">
      <LigthPageHeader
        title={{
          label: "Activités",
        }}
      />
      <div className="content-notification">
        <div className="filter-tabs">{renderTabContent()}</div>
        {renderListNotifications()}
      </div>
    </div>
  );
};

export default NotificationsActivitePage;
