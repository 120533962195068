import axios from "axios";

import { TTP_API_URL } from "../config";

export const getDevelopementCard = (
  collaborators,
  organizationId,
  token,
  data
) => {
  const requestUrl = `${TTP_API_URL}/team/developement-card`;
  return axios.get(requestUrl, {
    params: {
      filter: JSON.stringify(data),
      collaborators: JSON.stringify(collaborators),
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const saveDevelopementCardPlan = ({
  year,
  card,
  user,
  plan,
  token,
  collaborator,
  organization,
}) => {
  const requestUrl = `${TTP_API_URL}/team/developement-card/plan`;

  let formData = new FormData();
  formData.append("access_token", token);
  card && formData.append("card", card);
  collaborator && formData.append("collaborator", collaborator);
  user && formData.append("user", user);
  formData.append("year", year);
  formData.append("organization", organization);
  formData.append("plan", JSON.stringify(plan));

  return axios.post(requestUrl, formData);
};

export const saveDevelopementComment = ({
  plan,
  card,
  user,
  token,
  comment,
  organization,
}) => {
  const requestUrl = `${TTP_API_URL}/team/developement-card/comment`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("comment", JSON.stringify(comment));
  formData.append("card", card);
  plan && formData.append("plan", plan);
  user && formData.append("user", user);

  return axios.post(requestUrl, formData);
};

export const deleteDevelopementComment = ({ comment, token, organization }) => {
  const requestUrl = `${TTP_API_URL}/team/developement-card/comment/remove`;

  let formData = new FormData();
  comment.code && formData.append("code", comment.code);
  comment.plan && formData.append("plan", comment.plan.id);
  comment.card && formData.append("card", comment.card.id);
  formData.append("organization", organization);
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const deleteDevelopementPlan = ({ organization, token, plan }) => {
  const requestUrl = `${TTP_API_URL}/team/developement-card/plan/remove`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("id", plan);

  return axios.post(requestUrl, formData);
};

export const deleteErrorReport = ({ organization, token, id }) => {
  const requestUrl = `${TTP_API_URL}/team/error_report/remove`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("id", id);

  return axios.post(requestUrl, formData);
};
