export const TABS = [
  {
      id: "CLIENTS_IN",
      label: "Clients Entrants",
      subTitle: "clientsEntrants"
  },
  {
    id: "CLIENTS_IN_RISK",
    label: "Clients à risque",
    subTitle: "clientsInRisk"
  },
  {
    id: "CLIENTS_OUT",
    label: "Clients Sortants",
    subTitle: "clientsSortants"
  },
  {
    id: "REVIEW",
    label: "Review",
    subTitle: "review"
  }
];
